/*-------------------------------- RESET CSS ---------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*============================ END RESET CSS =======================================*/

/*------------------------------------*\
	TYPOGRAPHY
/*--- MVB ---*/
@font-face {
	font-family: "SolanoGothicMVB-Bd";
	font-style: normal;
	font-weight: normal;
	src: local("SolanoGothicMVB-Bd"), url("../fonts/SolanoGothicMVB-Bd/SolanoGothicMVB-Bd.eot"); /* IE9*/
	src: local("SolanoGothicMVB-Bd"), url("../fonts/SolanoGothicMVB-Bd/SolanoGothicMVB-Bd.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/SolanoGothicMVB-Bd/SolanoGothicMVB-Bd.woff2") format("woff2"), /* chrome、firefox */ url("../fonts/SolanoGothicMVB-Bd/SolanoGothicMVB-Bd.woff") format("woff"), /* chrome、firefox */ url("../fonts/SolanoGothicMVB-Bd/SolanoGothicMVB-Bd.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url("../fonts/SolanoGothicMVB-Bd/SolanoGothicMVB-Bd.svg#SolanoGothicMVB-Bd") format("svg"); /* iOS 4.1- */
}
/*--- MONTSERRAT ---*/
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/Montserrat/montserrat-v14-latin-regular.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/Montserrat/montserrat-v14-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v14-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v14-latin-regular.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v14-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v14-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/Montserrat/montserrat-v14-latin-700.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/Montserrat/montserrat-v14-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v14-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v14-latin-700.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v14-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v14-latin-700.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-100 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 100;
	src: url("../fonts/Montserrat/montserrat-v15-latin-100.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Thin"), local("Montserrat-Thin"), url("../fonts/Montserrat/montserrat-v15-latin-100.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-100.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-100.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-100.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-100.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-100italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 100;
	src: url("../fonts/Montserrat/montserrat-v15-latin-100italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("../fonts/Montserrat/montserrat-v15-latin-100italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-100italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-100italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-100italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-100italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-200italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 200;
	src: url("../fonts/Montserrat/montserrat-v15-latin-200italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("../fonts/Montserrat/montserrat-v15-latin-200italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-200italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-200italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-200italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-200italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-200 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 200;
	src: url("../fonts/Montserrat/montserrat-v15-latin-200.eot"); /* IE9 Compat Modes */
	src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("../fonts/Montserrat/montserrat-v15-latin-200.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-200.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-200.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-200.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-200.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	src: url("../fonts/Montserrat/montserrat-v15-latin-300.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Light"), local("Montserrat-Light"), url("../fonts/Montserrat/montserrat-v15-latin-300.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-300.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-300.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-300.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-300.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-300italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 300;
	src: url("../fonts/Montserrat/montserrat-v15-latin-300italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("../fonts/Montserrat/montserrat-v15-latin-300italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-300italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-300italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-300italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-300italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 400;
	src: url("../fonts/Montserrat/montserrat-v15-latin-italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Italic"), local("Montserrat-Italic"), url("../fonts/Montserrat/montserrat-v15-latin-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/Montserrat/montserrat-v15-latin-500.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/Montserrat/montserrat-v15-latin-500.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-500.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-500.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-500.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-500.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-500italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 500;
	src: url("../fonts/Montserrat/montserrat-v15-latin-500italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("../fonts/Montserrat/montserrat-v15-latin-500italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-500italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-500italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-500italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-500italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	src: url("../fonts/Montserrat/montserrat-v15-latin-600.eot"); /* IE9 Compat Modes */
	src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/Montserrat/montserrat-v15-latin-600.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-600.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-600.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-600.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-600.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-600italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 600;
	src: url("../fonts/Montserrat/montserrat-v15-latin-600italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("../fonts/Montserrat/montserrat-v15-latin-600italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-600italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-600italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-600italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-600italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-700italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/Montserrat/montserrat-v15-latin-700italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("../fonts/Montserrat/montserrat-v15-latin-700italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-700italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-700italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-700italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-700italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	src: url("../fonts/Montserrat/montserrat-v15-latin-800.eot"); /* IE9 Compat Modes */
	src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("../fonts/Montserrat/montserrat-v15-latin-800.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-800.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-800.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-800.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-800.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-800italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 800;
	src: url("../fonts/Montserrat/montserrat-v15-latin-800italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("../fonts/Montserrat/montserrat-v15-latin-800italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-800italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-800italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-800italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-800italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-900 - latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 900;
	src: url("../fonts/Montserrat/montserrat-v15-latin-900.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Black"), local("Montserrat-Black"), url("../fonts/Montserrat/montserrat-v15-latin-900.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-900.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-900.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-900.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-900.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-900italic - latin */
@font-face {
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 900;
	src: url("../fonts/Montserrat/montserrat-v15-latin-900italic.eot"); /* IE9 Compat Modes */
	src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("../fonts/Montserrat/montserrat-v15-latin-900italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/Montserrat/montserrat-v15-latin-900italic.woff2") format("woff2"), /* Super Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-900italic.woff") format("woff"), /* Modern Browsers */ url("../fonts/Montserrat/montserrat-v15-latin-900italic.ttf") format("truetype"), /* Safari, Android, iOS */ url("../fonts/Montserrat/montserrat-v15-latin-900italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-italic - latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 400;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500italic - latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 500;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-500italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-600.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600italic - latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 600;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-600italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700italic - latin */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 700;
	src: local(""), url("../fonts/Poppins/poppins-v15-latin-700italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* akzidenz grotesk-regular - latin */
@font-face {
	font-family: "Akzidenz Grotesk";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/AkzidenzGrotesk/Berthold Akzidenz Grotesk BE Regular.ttf") format("truetype"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/Poppins/poppins-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* akzidenz grotesk-bold  */
@font-face {
	font-family: "Akzidenz Grotesk";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/AkzidenzGrotesk/Akzidenz-grotesk-bold.ttf") format("truetype");
}
html,
body {
	margin: 0;
	padding: 0;
	color: #555;
	/* font-family: "Avenir", sans-serif; */
	font-family: Montserrat, sans-serif;
	width: 100%;
	overflow-x: hidden;
}

a {
	text-decoration: none;
}

/* ::-moz-selection {
	background: #0A1529;
	color: #ffffff;
}
::selection {
	background: #0A1529;
	color: #ffffff;
} */

strong {
	font-weight: bold;
}

.spacer_10 {
	display: table;
	width: 100%;
	height: 10px;
}
.spacer_25 {
	display: table;
	width: 100%;
	height: 25px;
}
.spacer_50 {
	display: table;
	width: 100%;
	height: 50px;
}

/* ----------------- LOADER PURE CSS ------------------ */
#loaderBIGOuter {
	position: fixed;
	z-index: 200;
	background-color: rgba(10, 21, 41, 0.97); /*#000000;*/
	height: 100%;
	width: 100%;
	text-align: center;
	top: 0px;
}
#loaderBIGInner {
	position: relative;
	margin: 0px auto;
	display: block;
	top: 35%;
}
#loaderBIGInner img {
	display: block;
	margin: 10px auto 20px auto;
	height: 60px;
	width: auto;
}

.loaderCSS {
	margin: 0px auto;
	font-size: 5px;
	position: relative;
	text-indent: -9999em;
	border-top: 0.7em solid rgba(255, 255, 255, 0.1);
	border-right: 0.7em solid rgba(255, 255, 255, 0.1);
	border-bottom: 0.7em solid rgba(255, 255, 255, 0.1);
	border-left: 0.7em solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 0.3s infinite linear;
	animation: load8 0.3s infinite linear;
}
.loaderCSS,
.loaderCSS:after {
	border-radius: 50%;
	width: 5em;
	height: 5em;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/*============================== END LOADER PURE CSS =============================*/

/*---------------------- SITIX REDESIGN -------------------*/
input[type="checkbox"],
input[type="radio"] {
	display: inline-block;
	vertical-align: top;
	width: auto;
	border: 1px solid #ddd;
	box-shadow: none;
	transform: scale(1.5);
}
input[type="checkbox"]:hover,
input[type="radio"]:hover,
label:hover {
	cursor: pointer;
}
.checkbox_radio_label {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 20px);
	padding-left: 10px;
	margin: 5px auto;
}
.checkbox_radio_label .section_text {
	margin: 0;
}
.section_row {
	display: table;
	width: 100%;
}
.section_row_half {
	display: inline-block;
	vertical-align: middle;
	width: 49%;
}
.section_row_half:first-child {
	margin-right: 1%;
}
.section_row_half:last-child {
	margin-left: 1%;
}
.section_row_half .section_row_half {
	width: 48%;
}

.button {
	background: #0a1529;
	display: table;
	margin: 10px auto;
	width: 200px;
	background: #0a1529;
	color: #fff;
	text-align: center;
	font-size: 15px;
	font-weight: 700;
	padding: 15px 0;
	border-radius: 10px;
	text-decoration: none;
	border: 0 !important;
	outline: 0 !important;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}
.button:hover,
.button:focus,
.button:active,
.buttonGray:hover {
	cursor: pointer;
	outline: 0 !important;
	border: 0 !important;
}
.button:active,
.buttonGray:active,
.button:focus,
.buttonGray:focus,
img:active {
	outline: 0;
}
.buttonGray {
	background: #888;
	color: #ffffff;
	font-size: 15px;
	font-weight: bold;
	padding: 20px 50px;
	border-radius: 11px;
	display: table;
	margin: 0 auto;
	text-decoration: none;
	border: 0;
	outline: 0;
}
.button_blue {
	display: table;
	margin: 10px auto;
	width: 200px;
	background: linear-gradient(#6a788d, 50%, #0a1529);
	color: #fff;
	text-align: center;
	font-size: 15px;
	font-weight: 700;
	padding: 15px 0;
	border-radius: 10px;
	outline: 0;
	border: 0;
}
.button_blue:hover,
.button_blue:active,
.button_blue:focus {
	outline: 0;
	border: 0;
	cursor: pointer;
	background: linear-gradient(#0a1529, 50%, #6a788d);
}
.button_white_gray {
	display: table;
	margin: 10px auto;
	width: 200px;
	background: linear-gradient(#fff, 50%, #ddd);
	color: #333;
	text-align: center;
	font-size: 15px;
	font-weight: 700;
	padding: 15px 0;
	border-radius: 10px;
}
.button_white_gray_active,
.button_white_gray:hover {
	background: linear-gradient(#ed7064, 50%, #e92224);
	color: #fff;
}
.button_red {
	display: table;
	margin: 10px auto;
	width: 200px;
	background: linear-gradient(#ed7064, 50%, #e92224);
	color: #fff;
	text-align: center;
	font-size: 15px;
	font-weight: 700;
	padding: 15px 0;
	border-radius: 10px;
}
.button_red:hover {
	color: #fff;
}
.button_full {
	width: 100%;
}
.button_facebook {
	color: #fff;
	background: linear-gradient(#5971ad, 50%, #224292);
}
.button_facebook:hover {
	color: #fff;
	background: linear-gradient(#224292, 50%, #5971ad);
}
.button_google {
	background: linear-gradient(#fff, 50%, #ddd);
	color: #333;
}
.button_google:hover {
	background: linear-gradient(#ddd, 50%, #fff);
	color: #333;
}
.button_facebook img,
.button_google img {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
	width: 20px;
	height: 20px;
}
.button_google img {
	margin-right: 10px;
}
.button_inline {
	display: inline-block;
	margin: 10px;
}
.buttonNewsletter {
	border-radius: 0;
	border-top-right-radius: 11px;
	border-bottom-right-radius: 11px;
	float: left;
	width: 120px;
	height: 50px;
	font-size: 17px;
	font-weight: normal;
	text-transform: none;
	text-align: center;
	margin-top: 5px;
	padding: 15px 0;
	max-width: none;
	display: table;
	background: #e92224;
	box-shadow: inset -5px 5px 15px rgba(0, 0, 0, 0.6);
}
.buttonNewsletter:hover {
	color: #fff !important;
}

.sectionFullHeight {
	min-height: calc(100vh - 115px);
}
.checkout_left {
	float: left;
	vertical-align: top;
	width: 60%;
	max-width: 700px;
	min-height: calc(100vh - 175px);
	padding: 10px 20px 50px 20px;
	background: linear-gradient(#f7f7f7, #fff 50%, #f7f7f7);
	box-shadow: 8px -8px 8px -5px #ccc, -8px -8px 8px -5px #ccc;
	-webkit-boz-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.checkout_step {
	padding: 10px 0;
	margin: 0;
	border-bottom: 2px solid #d3d2d1;
}
.checkout_step:first-child {
	border-top: 2px solid #d3d2d1;
}
#checkout_step_container_1 {
	max-width: 500px;
	margin: 10px auto;
}
.checkout_step_title {
	font-size: 17px;
	line-height: 21px;
	margin: 10px 0;
	text-align: left;
	position: relative;
	color: #d3d2d1;
	font-weight: bold;
	font-family: Montserrat, sans-serif;
}
.checkout_step_title_number {
	display: inline-block;
	vertical-align: middle;
	background: transparent;
	color: #e3e2e1;
	width: 40px;
	height: 40px;
	font-weight: bold;
	border: 4px solid #d3d2d1;
	border-radius: 100%;
	position: relative;
}
.checkout_step_title_number span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.checkout_step_title_text {
	display: inline-block;
	vertical-align: middle;
	padding-left: 10px;
}
.checkout_step_arrow {
	position: absolute;
	top: 20px;
	right: 20px;
}

.checkout_step_active .checkout_step_title {
	color: #000;
}
.checkout_step_active .checkout_step_title_number {
	color: #fff;
	background: #e3e2e1;
	border: 4px solid #fff;
	box-shadow: 0px 0px 5px 3px #ccc;
}
.checkout_step_active .checkout_step_container {
	display: table;
	width: calc(100% - 65px);
	margin: 10px 10px 10px 55px;
}
.checkout_step_complete:hover {
	background: #f3f2f1;
	cursor: pointer;
}
.checkout_step_active:hover {
	background: initial;
	cursor: unset;
}
.checkout_venmo_logo {
	display: inline-block;
	vertical-align: middle;
	height: 15px;
	width: auto;
}
.checkout_paypal_logo {
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: auto;
}

.checkout_right {
	float: right;
	vertical-align: top;
	width: 40%;
	max-width: 500px;
	padding: 20px 20px;
}
.checkout_right_box {
	display: table;
	width: 100%;
	max-width: 400px;
	margin: 20px auto;
	background: #fff;
	padding: 20px;
	border-radius: 20px;
	box-shadow: 3px 3px 7px 3px #ccc;
	-webkit-boz-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.checkout_box_title {
	font-weight: 700;
	margin: 10px 0;
	font-size: 17px;
}
.checkout_box_text {
	font-weight: 400;
	font-size: 15px;
	margin: 5px 0;
}
hr {
	all: initial;
	display: block;
	height: 1px;
	width: 100%;
}
.checkout_box_hr {
	display: table;
	width: 100%;
	height: 1px;
	background: #ddd;
	margin: 5px auto;
}
.checkout_box_row {
	display: table;
	width: 100%;
	margin: auto;
	text-align: left;
}
.checkout_box_row_left {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 150px);
	text-align: left;
}
.checkout_box_row_right {
	display: inline-block;
	vertical-align: top;
	width: 150px;
	text-align: right;
}

.inline_middle {
	display: inline-block;
	vertical-align: middle;
}

.section_text {
	font-size: 15px;
	line-height: 17px;
	margin: 5px 0;
	text-align: left;
}
.section_text_small {
	font-size: 13px;
	line-height: 15px;
	margin: 5px 0;
	text-align: left;
}

.text_left {
	text-align: left;
}
.text_center {
	text-align: center;
}
.text_right {
	text-align: right;
}
.text_gray {
	color: #767977;
}
.text_darkGray {
	color: #333;
}
.text_black {
	color: #000;
}
.text_blue,
.text_blue:hover {
	color: #0075ff;
}
.text_bold {
	font-weight: 700;
	font-weight: bold;
}
.text_line_through {
	text-decoration: line-through;
}
.text_underline:hover {
	text-decoration: underline;
}
/*==================== END SITIX REDESIGN =================*/

/*------------------------------ MOBILE MENU -------------------------------------*/
.mobileSubMenuTab {
	display: none;
	background: #e92224;
	color: #ffffff;
	font-size: 25px;
	padding: 10px 20px 10px 50px;
}
.mobileSubMenuTabActive {
	display: block;
}
.mobileSubMenuTab:hover {
	background: #e92224;
	cursor: pointer;
	opacity: 0.9;
}

.mobileMenuSocial {
	display: flex;
	flex-direction: row;
	margin-left: 25px;
	margin-right: 25px;
	margin-top: 20px;
}

.mobileMenuSocialLink {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	border: 2px solid #fff;
	border-radius: 100%;
	height: 40px;
	width: 40px;
	text-align: center;
}

.followUsSocialLink {
	display: inline-block;
	margin: auto 10px;
}

.mobileMenuSocialIcon {
	width: 20px;
	height: 20px;
}

.followUsSocialIcon {
	width: 35px;
	height: 35px;
}
/*============================ END MOBILE MENU ==================================*/

.section {
	display: table;
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.menuTop {
	position: relative;
	display: table;
	width: 100%;
	margin: auto;
	height: 60px;
	padding: 10px 0;
	text-align: left;
}

.topHeaderSaveText {
	color: #fff;
	display: table;
	margin: auto;
	padding-top: 30px;
	padding-right: 60px;
}

.topHeaderSaveText a {
	margin: auto;
	color: #fff;
}

.topHeaderSaveText a:hover {
	text-decoration: underline;
	cursor: pointer;
}

.topHeaderSaveTextMobile {
	display: none;
}

.topHeaderSaveTextDesktop {
	display: block;
}

.topHeaderSaveTextDesktop:hover,
.topHeaderSaveTextMobile:hover {
	text-decoration: none;
}

/* ---- add 50px to menu and menuUnder -----*/
.sectionStickyBand {
	position: fixed;
	z-index: 90;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background: #e92224;
	color: #fff;
	font-size: 17px;
	text-align: center;
	display: table;
}

.sectionStickyBand a {
	color: #fff;
	font-weight: bold;
	text-decoration: underline;
}

.sectionStickyBand a:hover {
	text-decoration: underline;
}

.sectionStickyBandPercent {
	font-size: 20px;
	font-weight: bold;
	font-style: italic;
	padding: 0px 1px;
}

.sectionHeader {
	background-size: cover;
	/*min-height: 400px;*/
	width: 100%;
}

.sectionHeaderHomepage {
	display: table;
	width: 100%;
	height: 300px;
	background-image: url("../img/header/sitix/sitix_concerts_header.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

/*-------------------------- HOME SLIDER ----------------------------------*/
.sectionInnerSlider {
	position: relative;
	width: 100%;
	display: table;
}

.sectionHeaderSlider {
	width: 100vw;
	max-width: 100%;
	margin: 0;
}

.headerSliderArrowImg {
	width: 35px;
	height: 35px;
	/*box-shadow: 0 0 13px 2px #000;
	border-radius: 100%;*/
}

.headerSliderArrowImg:hover {
	cursor: pointer;
}

.slick-prev,
.slick-next {
	position: absolute;
	top: 0;
	bottom: 0;
	color: #fff;
	outline: 0 !important;
	background: transparent;
	border: none;
	z-index: 100;
	font-size: 40px;
	opacity: 0.5;
}

.slick-prev {
	left: 0;
	display: none !important;
}

.slick-next {
	right: 20px;
}

.slick-arrow:hover {
	cursor: pointer;
	opacity: 1;
}

.slick-slide {
	border-right: 1px solid #fff;
	border-left: 1px solid #fff;
}

.slick-slide[aria-hidden="true"] {
	filter: blur(2px);
}
/*====================== END HOME SLIDER =================================*/

/*------------------- HOME SUBSLIDER --------------------------------*/
.homeSubSlider_container {
	display: block;
	width: 100vw;
	max-width: 1100px;
	margin: auto;
	position: relative;
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 10px;
}

.homeSubSlider {
	display: table;
	width: 100%;
	margin: auto;
	text-align: center;
}

.homeSubSlider_tab {
	display: block;
	float: left;
	width: 25%;
	text-align: center;
	padding: 0 10px;
	box-sizing: border-box;
}

.homeSubSlider_tab_img {
	width: 100%;
	height: auto;
}
.homeSubSlider_category {
	width: 1050px;
}
.homeSubSlider_category .homeSubSlider_tab {
	width: 150px;
}

.homeSubSlider_category .homeSubSlider_tab_img {
	max-width: 150px;
}

.homeSubSlider_tab_title {
	text-align: center;
	font-weight: bold;
	font-size: 20px;
	color: #767977;
	font-family: "Montserrat", sans-serif;
}
.homeSubSlider_tab_text {
	text-align: left;
	font-size: 18px;
	color: #767977;
	margin-top: 20px;
	font-family: "Avenir", sans-serif;
}
#event_slider {
	margin-bottom: 20px;
}
/*=================== END HOME SUBSLIDER ============================*/

.sectionHeaderLoginRegister {
	background-image: url("../img/header/loginRegister/header_20promo.jpg");
	background-position: center bottom;
	/*height: 450px;
	min-height: 450px;*/
	height: auto;
	min-height: initial;
	padding: 50px 0;
}

.sectionHeaderMaintenance {
	background-image: url("../img/header/maintenance/maintenance_bkg.jpg");
	background-position: center bottom;
}

.sectionHeader404 {
	background-image: url("../img/header/404/404_bkg.jpg");
	background-position: center bottom;
}

.sectionHeaderFootball {
	background-image: url("../img/header_football.jpg");
}

.sectionHeaderMLB {
	background-image: url("../img/header_baseball.jpg");
}
.sectionSportsHeader_MLB {
	background-image: url("../img/header/big/header_mlb.jpg");
	background-position: center bottom;
}

.sectionHeaderNFL {
	background-image: url("../img/header_nfl.jpg");
	background-position: center top;
}
.sectionSportsHeader_NFL {
	background-image: url("../img/header/big/header_nfl.jpg");
	background-position: center bottom;
}

.sectionHeaderNBA {
	background-image: url("../img/header_nba.jpg");
	background-position: center bottom;
}
.sectionSportsHeader_NBA {
	background-image: url("../img/header/big/header_nba.jpg");
	background-position: center bottom;
}

.sectionHeaderNCAAB {
	background-image: url("../img/header_nba.jpg");
	background-position: center bottom;
}
.sectionSportsHeader_NCAAB {
	background-image: url("../img/header/big/header_ncaab.jpg");
	background-position: center bottom;
}

.sectionHeaderNHL {
	background-image: url("../img/header_nhl.jpg");
	background-position: center top;
}
.sectionSportsHeader_NHL {
	background-image: url("../img/header/big/header_nhl.jpg");
	background-position: center bottom;
}

.sectionHeaderCFP {
	background-image: url("../img/header_cfp.jpg");
}
.sectionSportsHeader_CFP {
	background-image: url("../img/header/big/header_cfp.jpg");
	background-position: center bottom;
}

.sectionHeaderBig10 {
	background-image: url("../img/conference/header_big10.jpg");
	background-position: center top;
}

.sectionHeaderACC {
	background-image: url("../img/conference/header_acc.jpg");
	background-position: center top;
}

.sectionHeaderBig12 {
	background-image: url("../img/conference/header_big12.jpg");
	background-position: center top;
}

.sectionHeaderSEC {
	background-image: url("../img/conference/header_sec.jpg");
	background-position: center top;
}

.sectionHeaderPAC-12 {
	background-image: url("../img/conference/header_pac-12.jpg");
	background-position: center top;
}

.sectionHeaderSugar {
	background-image: url("../img/conference/header_sugar.jpg");
	background-position: center top;
}

.sectionHeaderRose {
	background-image: url("../img/conference/header_rose.jpg");
	background-position: center top;
}

.sectionHeaderCotton {
	background-image: url("../img/conference/header_cotton.jpg");
	background-position: center top;
}

.sectionHeaderOrange {
	background-image: url("../img/conference/header_orange.jpg");
	background-position: center top;
}

.sectionHeaderPeach {
	background-image: url("../img/conference/header_peach.jpg");
	background-position: center top;
}

.sectionHeaderFiesta {
	background-image: url("../img/conference/header_fiesta.jpg");
	background-position: center top;
}

.sectionHeaderRealFans {
	background-image: url("../img/header/big/header_nfl.jpg");
	background-position: center bottom;
}

.sectionHeaderTicketHolder {
	background-image: url("../img/header/big/header_nfl.jpg");
	background-position: center bottom;
}

.sectionSavePageHeader {
	background: #eee;
	padding: 50px 0px;
}
.sectionSavePageHeader .sectionInner {
	max-width: 900px;
	display: block;
}

.sectionHowItWorksHeader {
	background-image: url(../img/header_homepage.jpg);
	background-position: center top;
	padding: 0 0 20px 0;
	min-height: 73vh;
	background-size: cover;
}

.headerOfficialPartner {
	width: 70%;
	max-width: 200px;
	margin: 0px auto 0px auto;
}

.headerOfficialPartnerBig10 {
	width: auto;
	height: auto;
	max-width: 200px;
	max-height: 100px;
}

.sectionNewsletter {
	padding: 10px 0px;
	margin: -1px 0;
}
.sectionNewsletter .sectionInner {
	display: table;
	margin: auto;
	width: 95%;
	max-width: 600px;
}

.sectionMobileApp {
	background-image: url(../img/sitix/mobile_app/sitix_blue_bckground.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.sectionMobileApp .sectionInner {
	display: table;
	max-width: 800px;
	margin: 20px auto;
}
.sectionMobileApp_left {
	display: inline-block;
	vertical-align: middle;
	width: 30%;
	padding: 0 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.sectionMobileApp_left img {
	display: table;
	width: 100%;
	max-width: 250px;
	height: auto;
	margin: auto;
}
.sectionMobileApp_right {
	display: inline-block;
	vertical-align: middle;
	width: 70%;
	text-align: center;
	padding: 0 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.sectionMobileApp_logo,
.sectionMobileApp_venmo {
	display: inline-block;
	margin: 0 20px;
	width: auto;
	height: 130px;
}
.sectionMobileApp_title {
	font-size: 34px;
	line-height: 37px;
	letter-spacing: 1px;
	color: #fff;
	margin: 20px auto 5px auto;
	font-family: "SolanoGothicMVB-Bd" !important;
}
.sectionMobileApp_text {
	font-size: 15px;
	line-height: 20px;
	color: #fff;
	margin: 0 auto 30px auto;
}
.sectionMobileApp_button {
	display: inline-block;
	margin: 5px 10px;
}
.sectionMobileApp_button img {
	width: auto;
	height: 50px;
}

.sectionBorders {
	/*border-top: 1px solid #eee;*/
	border-bottom: 1px solid #ccc;
}

.sectionFooter {
	color: #ffffff;
	padding: 0 0 50px 0;
}

.sectionInnerFooter {
	margin: auto;
}

.sectionLogos {
	background-color: #eeeeee;
	border-top: 1px solid #e3e3e3;
}

.sectionGray {
	background-color: #f7f7f7;
}

.sectionBlue {
	background-color: #11284b;
}

.sectionDarkBlue {
	background-color: #0a1529;
}

.sectionPadding {
	padding: 30px 0px;
}

.sectionPaddingBottom {
	padding: 0px 0px 30px 0px;
}

.sectionInner {
	display: table;
	width: 95%;
	margin: 0 auto;
	text-align: center;
}
.sectionInner_1000 {
	max-width: 1000px;
}
.sectionInner_1200 {
	max-width: 1200px;
}

.sectionSteps234Header {
	padding: 50px 0px 30px 0px;
}

.sectionSteps234Header_MLB {
	background-image: url("../img/header/small/header_mlb.jpg");
	background-position: center top;
}

.sectionSteps234Header_NHL {
	background-image: url("../img/header/small/header_nhl.jpg");
	background-position: center top;
}

.sectionSteps234Header_NBA {
	background-image: url("../img/header/small/header_nba.jpg");
	background-position: center top;
}

.sectionSteps234Header_NCAAB {
	background-image: url("../img/header/small/header_ncaab.jpg");
	background-position: center top;
}

.sectionSteps234Header_NFL {
	background-image: url("../img/header/small/header_nfl.jpg");
	background-position: center top;
}

.sectionSteps234Header_CFP {
	background-image: url("../img/header/small/header_cfp.jpg");
	background-position: center top;
}

.footer_logo {
	display: table;
	margin: 0 auto 30px auto;
}

.footer_logo img {
	height: 70px;
	width: auto;
}

.headerSportIconsContainer {
	display: table;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	position: absolute;
	right: 0;
	left: 0;
}

.headerSportIcons {
	display: table;
	max-width: 95%;
	margin: 10px auto;
	/*padding-top: 5px;
	padding-bottom: 5px;
	border-top: 3px solid #e92224;
	border-bottom: 3px solid #e92224;*/
	/*padding: 5px;
	border: 3px solid #e92224;
	background: rgba(0,0,0,0.3);*/
}

.headerSportIcon {
	display: table-cell;
	border: 1px solid transparent;
	padding: 1% 1% 0 1%;
}

.headerSportIcon:hover {
	border: 1px solid #e92224;
	background: rgba(0, 30, 57, 0.5);
}

.headerSportIcon img {
	width: 100%;
	max-width: 70px;
	height: auto;
}

.headerH1 {
	font-size: 50px;
	line-height: 60px;
	color: #ffffff;
	text-shadow: 2px 3px #000000;
	font-weight: bold;
	width: 90%;
	max-width: 1000px;
	margin: 0 auto 40px auto;
}

.headerH1Br {
	display: block;
}

.headerH2 {
	margin: 30px auto 30px auto;
	font-size: 25px;
	line-height: 30px;
	width: 90%;
	max-width: 700px;
	color: #ffffff;
	text-shadow: 1px 1px #000000;
}

.tahoma {
	font-family: Tahoma, sans-serif;
}

.ticketholder_next,
.ticketholder_back {
	display: inline-block;
}

.userSelectNone {
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

#headerButton {
	font-size: 21px;
	margin-bottom: 20px;
}

#howItWorksButton {
	margin: 30px auto 10px auto;
}

input,
select,
textarea {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	outline: 0;
	border: 1px solid #ddd;
	border-radius: 11px;
	font-size: 16px;
	width: 100%;
	padding: 10px;
	margin: 5px auto;
	color: #555;
	font-family: "Avenir", sans-serif;
}
.input_shadow {
	border: 0;
	box-shadow: -5px -5px 3px 1px #ccc;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff url(../img/selectArrow.svg) no-repeat right;
	background-position: right 11px center;
}

select:hover {
	cursor: pointer;
}

.inputError {
	border: 1px solid #ff004e !important;
}

.headerVideoLink {
	color: #ffffff;
	margin: 50px auto 0 auto;
	display: table;
	font-size: 20px;
}

.headerVideoLink img {
	margin-right: 5px;
	height: 15px;
	width: auto;
}

.headerVideoLink span:hover {
	text-decoration: underline;
	cursor: pointer;
}

.logosImage {
	width: 90%;
	max-width: 800px;
	margin: 15px auto;
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: 0.5;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}

.sectionTitle {
	font-size: 25px;
	line-height: 31px;
	font-weight: bold;
	color: #000000;
	display: table;
	width: 95%;
	max-width: 700px;
	margin: 20px auto 0px auto;
}

.sectionSubtitle {
	margin: 15px auto;
	font-size: 17px;
	line-height: 23px;
}

.googleTextOnHomepage {
	text-align: left;
	margin: 10px auto 20px auto;
	width: 95%;
}

.sectionInner_Left {
	display: block;
	width: 100%;
	margin: auto;
}

.sectionLeftTitle {
	display: table;
	width: 95%;
	max-width: 1100px;
	font-size: 25px;
	line-height: 31px;
	font-weight: bold;
	color: #0a1529;
	text-align: left;
	margin: 10px auto;
	padding: 0 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
}
.sectionLeftTitle_viewAll {
	float: right;
	padding: 5px 15px;
	margin-top: -10px;
	font-size: 13px;
	border-radius: 20px;
	color: #767977;
	box-shadow: 6px 5px 7px 0 #ccc;
}
.sectionLeftTitle_viewAll:hover {
	cursor: pointer;
}

.sectionLeftSubtitle {
	display: table;
	margin: 5px 0 0 0;
	font-size: 17px;
	line-height: 23px;
	text-align: left;
}

.textWhite {
	color: #fff;
}

.generalPagesContainer {
	display: table;
	width: 95%;
	max-width: 800px;
	margin: 20px auto 20px auto;
}

.generalPagesText {
	font-size: 15px;
	line-height: 21px;
	text-align: left;
	margin: 10px 0;
	word-break: break-word;
}
.generalPagesTextItalic {
	font-style: italic;
	font-size: 13px;
}
.generalPagesTextBold {
	font-size: 17px;
	font-weight: bold;
	margin: 30px 0 5px 0;
}

.generalPagesTextCenter {
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	margin: 10px 0;
	word-break: break-word;
}

.generalPagesTitle {
	font-size: 23px;
	line-height: 30px;
	text-align: left;
	font-weight: bold;
	margin-top: 30px;
}

.generalPagesSubtitle {
	font-size: 17px;
	line-height: 25px;
	text-align: left;
	font-weight: bold;
	margin-top: 30px;
}

.generalPagesList li {
	font-size: 15px;
	line-height: 21px;
	text-align: left;
	margin: 10px 0px 10px 20px;
}

.generalPagesLink {
	color: #e92224;
	font-weight: bold;
}

.generalPagesLink:hover {
	text-decoration: underline;
}

.generalPagesButton {
	width: 250px;
	text-align: center;
	font-size: 17px;
	padding: 15px 0px;
	vertical-align: top;
	margin: 20px auto;
}

.sportsContainer {
	display: table;
	max-width: 700px;
	width: 90%;
	margin: 40px auto 40px auto;
}

.sportsTab {
	text-align: center;
	margin: 5px;
	display: inline-block;
	background: #f3f2f1;
	padding: 20px 0px;
	text-decoration: none;
	border: 1px solid #ddd;
	width: 100%;
	max-width: 200px;
}

.sportsTab:hover {
	cursor: pointer;
	background: #fff;
}

.sportsTabIcon {
	height: auto;
	width: 90px;
}

.sportsTabText {
	font-weight: bold;
	font-size: 20px;
	margin-top: 15px;
	color: #000000;
}

.header_hero_container {
	display: table;
	width: 95%;
	max-width: 800px;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, 0%);
}
.header_hero_text {
	font-size: 40px;
	letter-spacing: 1px;
	font-family: "SolanoGothicMVB-Bd" !important;
	color: #fff;
	text-align: left;
}

#predictiveSearchOverlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background: rgb(10 21 41 / 0.7);
	width: 100vw;
	height: 100vh;
}

/*--- TEAM Search ---*/

.predictiveSearchResultsTab_bkg {
	background: #f3f2f1;
}
.predictiveSearchResultsTab_left {
	width: 109px;
	margin-right: 20px;
	display: inline-block;
	vertical-align: middle;
	border-right: 1px solid #fff;
	text-align: center;
}
.predictiveSearchResultsTab_right {
	width: calc(100% - 130px);
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}
.predictiveSearchResultsTab_text {
	font-size: 15px;
	color: #aaa;
	margin: 5px 0;
}
.predictiveSearchResultsTab_title {
	font-size: 17px;
	font-weight: bold;
	color: #132c51;
	margin: 5px 0;
}

.ac-subscribe-form {
	display: table;
	width: 100%;
	margin: 10px auto;
}

.newsletterText {
	display: table;
	width: auto;
	margin: 10px auto;
	color: #fff;
	font-size: 15px;
	line-height: 20px;
	padding: 10px;
	border: 1px solid #fff;
	border-radius: 11px;
	box-sizing: border-box;
}

.inputNewsletter {
	border-radius: 0;
	border-top-left-radius: 11px;
	border-bottom-left-radius: 11px;
	width: calc(100% - 120px);
	height: 50px;
	float: left;
	border: 0;
	box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.7);
}
.inputNewsletter:hover {
	box-shadow: none;
}

.ac-responses {
	margin-top: 10px;
}

.noTeamsNewsletterText {
	color: #555;
	margin: 20px auto 30px auto;
	font-size: 20px;
	line-height: 23px;
	width: 90%;
}

.inputNoTeamsNewsletter {
	border-radius: 0;
	border-top-left-radius: 11px;
	border-bottom-left-radius: 11px;
	width: 300px;
	float: left;
	border: 1px solid #ddd;
	background: #eee;
}

.buttonNoTeamsNewsletter {
	border-radius: 0;
	border-top-right-radius: 11px;
	border-bottom-right-radius: 11px;
	float: left;
	font-size: 17px;
	line-height: 20px;
	margin-top: 5px;
	padding: 15px 25px;
	max-width: none;
	display: table;
	width: inherit;
	border: 1px solid #e92224;
}

.guaranteeContainer {
	display: table;
	width: 100%;
	margin: 0px auto;
	background: #fff;
}

.guaranteeContainer_img {
	display: table;
	width: 95%;
	max-width: 1000px;
	margin: 50px auto;
}

.goldenTicketContainer {
	display: table;
	width: 100%;
	margin: 0px auto;
	background: #000;
}

.goldenTicketContainer_img {
	display: table;
	width: 95%;
	max-width: 1000px;
	margin: 0px auto;
}

.giftCardContainer {
	display: table;
	width: 100%;
	margin: 0 auto;
	background: #e92224;
	background-image: linear-gradient(to left, #1975bb, #020b3e);
}

.giftCardContainer_img {
	display: table;
	width: 95%;
	max-width: 1000px;
	margin: 0px auto;
}

.testimonialContainer {
	display: table;
	width: 100%;
	margin: 0px auto;
	background: #e92224;
	background-image: linear-gradient(to right, #000000 35%, #e92224);
}

.testimonialContainer_img {
	display: table;
	width: 100%;
	max-width: 1300px;
	margin: 50px auto;
}

.testimonialTab {
	display: inline-block;
	padding: 0px 20px;
	margin: 20px auto;
	width: 100%;
	max-width: 300px;
	vertical-align: top;
}

.testimonialText {
	font-size: 15px;
	line-height: 20px;
	text-align: justify;
}

.testimonialStars {
	width: 100px;
	height: auto;
	margin: 10px auto;
}

.testimonialName {
	font-weight: bold;
	font-size: 15px;
	font-style: italic;
}

.generalPagesHeader {
	display: table;
	width: 100%;
	margin: 0px auto;
}

.generalPagesHeader_blue {
	background: #0d3e7a;
	background-image: linear-gradient(#020b3e, #1975bb);
}

.generalPagesHeader_black {
	background-color: #000;
}

.generalPagesHeader_img {
	display: table;
	width: 100%;
}

.generalPagesHeader_img_affiliates {
	max-width: 700px;
	margin: 50px auto;
}

.generalPagesHeader_img_goldenticket {
	max-width: 900px;
	margin: 20px auto;
}

.howItWorksContainer {
	display: table;
	width: 100%;
	/*margin: 70px auto 30px auto;*/
	text-align: center;
}

.howItWorksHalf {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	margin: 30px auto 0px auto;
	max-width: 550px;
}

.howItWorksHalf_1 {
	/*width: 40%;*/
}

.howItWorksHalf_2 {
	/*width: 40%;
	max-width: 400px;*/
}

.howItWorksHalf_2 img {
	width: 100%;
	display: table;
}

.howItWorksH1 {
	margin: 20px auto 20px auto;
	font-size: 30px;
	font-weight: bold;
	color: #e92224;
}

.howItWorksH2 {
	font-size: 20px;
}

.howItWorksNumber {
	display: table;
	margin: 0px auto 7px auto;
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
	background-color: #e92224;
	padding: 19px 23px;
	border-radius: 100%;
}

.howItWorksText {
	max-width: 230px;
	margin: 0 auto;
	font-size: 17px;
	line-height: 22px;
}

.howItWorksLine {
	height: 40px;
	width: 2px;
	border-left: 3px dotted #e92224;
	margin: 10px auto;
}

.howItWorksHalf iframe {
	display: table;
	margin: 0 auto;
	max-width: 90%;
}

.howItWorksTextGreen {
	font-weight: bold;
	color: #e92224;
}

.footerText {
	margin: 10px auto;
	font-size: 13px;
	color: #ffffff;
}

.footerTextInline {
	margin: 10px 5px;
}

.footerBBBlogo {
	display: table;
	margin: 13px auto 10px auto;
}

.footerBBBlogo img {
	width: 130px;
	height: auto;
}

.faqContainer {
	margin: 50px auto 20px 0px;
}

.faqTab {
	display: table;
	width: 90%;
	max-width: 700px;
	margin: 15px auto;
	border: 1px solid #ddd;
}

.faqQuestion {
	text-align: left;
	font-weight: bold;
	background-color: #eee;
}

.faqQuestion:hover {
	cursor: pointer;
	opacity: 0.9;
}

.faqQuestion div {
	padding: 13px 15px;
	font-size: 20px;
	line-height: 23px;
}

.faqAnswer {
	display: none;
	text-align: justify;
	font-size: 15px;
	line-height: 19px;
}

.faqAnswer div {
	padding: 10px 15px;
	background: #fff;
}

.faqTabsHidden {
	display: none;
}

#showMoreFAQ {
	text-decoration: underline;
	color: #000000;
	font-weight: bold;
	margin-top: 35px;
}

#showMoreFAQ:hover {
	cursor: pointer;
}

#teamSearchInput {
	margin: 40px auto 0px auto;
}

.teamsContainer {
	display: table;
	margin: 30px auto 30px auto;
	width: 100%;
	max-width: 800px;
}

.teamTab {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	max-width: 31%;
	margin: 5px auto;
	font-size: 17px;
	line-height: 21px;
	color: #000000;
	padding: 15px 0;
	border: 1px solid transparent;
	text-decoration: none;
}

.teamTab:hover {
	cursor: pointer;
	background: #fff;
	border: 1px solid #ddd;
}

.teamTab_top25 {
	display: table;
	width: 95%;
	max-width: 400px;
}

.teamConference {
	display: table;
	width: 95%;
	margin: 10px auto 20px auto;
}

.teamConferenceTitle {
	text-align: center !important;
	text-transform: uppercase;
}

.teamConferenceTeams {
}

/*---------------------------------------- ACCORDION ----------------------------------------------*/
.accordion {
	background-color: #eeeeee;
	color: #000000;
	cursor: pointer;
	padding: 25px 2.5%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 17px;
	font-weight: bold;
	transition: 0.1s;
}

.accordionActive,
.accordion:hover {
	background-color: #ddd;
}

.accordion:after {
	content: "\002B";
	color: #000000;
	font-weight: bold;
	float: right;
	margin-left: 5px;
}

.accordionActive:after {
	content: "\2212";
}

.panel {
	padding: 0px 1%;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
	border: 1px solid #ddd;
	border-top: none;
}

.panelInner {
	margin: 20px auto;
}

.accordionAffiliate {
	margin-top: 20px;
}

.panelAffiliate {
	background: #fff;
}

.accordionAffiliate_link {
	display: table;
	text-align: left;
	text-decoration: underline;
	font-size: 17px;
	margin: 10px 0;
	color: #e92224;
}
/*====================================== END ACCORDION ============================================*/

.form-group {
	margin: 0;
}

.pricesContainerButtons {
	margin: auto;
	display: table;
	width: 95%;
	max-width: 500px;
}

.pricesTabTextContainer {
	margin: 20px auto;
}

.pricesTabText {
	font-size: 18px;
	margin: 10px auto;
	color: #000000;
}

#pricesTabBuyTicketsButton {
	margin: 20px auto 0px auto;
	font-size: 18px;
}

.pricesContainer {
	display: table;
	float: left;
	margin: 10px auto;
	width: calc(100% - 270px);
	max-width: 1000px;
}
.rightSidebar_banner {
	display: table;
	float: right;
	width: 250px;
	margin: 20px auto;
}
.rightSidebar_banner a img {
	width: 100%;
	max-width: 400px;
	height: auto;
}

.pricesTab {
	display: table;
	width: 95%;
	background: #e2e4e7;
	border: 3px solid #fff;
	border-radius: 20px;
	margin: 15px auto;
	box-shadow: 3px 3px 7px 3px #ccc;
}

.pricesTab_banner {
	width: 95%;
	display: table;
	margin: 15px auto;
}
.pricesTab_banner img {
	display: table;
	width: 100%;
}

.pricesTabTeamName {
	display: table-cell;
	vertical-align: middle;
	/*width: 23%;*/
	width: 50%;
	text-align: left;
	font-size: 20px;
	font-weight: bold;
	padding: 10px 0px 10px 2%;
}
.pricesTabTeamName_title {
	font-weight: bold;
	margin-top: 7px;
	font-size: 17px;
	color: #11284b;
}
.pricesTabTeamName_text {
	font-weight: normal;
	font-size: 15px;
	margin-top: 5px;
	color: #767977;
}
.pricesTabTeamName_text strong {
	font-weight: bold;
}

.pricesTabButtonContainer {
	display: table-cell;
	vertical-align: middle;
	width: 20%;
}

.pricesTabOfferContainer {
	display: table-cell;
	vertical-align: middle;
	width: 30%;
	text-align: center;
	position: relative;
}
.pricesTabOfferContainer_border {
	float: left;
	width: 1px;
	min-height: 60px;
	background: #fff;
	border-radius: 1px;
}
.price_corner_info {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50px;
	height: auto;
	border-bottom-right-radius: 20px;
}
.price_corner_info:hover {
	cursor: pointer;
}
.pricesTabOffer_title {
	display: table;
	width: 90%;
	margin: auto;
	text-align: left;
}
.pricesTabOffer_title_img {
	display: inline-block;
	vertical-align: middle;
	width: auto;
	height: 25px;
}
.pricesTabOffer_title_1 {
	display: inline-block;
	vertical-align: middle;
	font-weight: 300;
	font-size: 15px;
	color: #11284b;
	padding: 0 5px;
}
.pricesTabOffer_title_2 {
	display: inline-block;
	vertical-align: middle;
	font-weight: 700;
	font-size: 17px;
	color: #11284b;
}
.pricesTabOffer_subtitle {
	display: table;
	width: 90%;
	margin: 5px auto;
	font-weight: 700;
	font-size: 15px;
	color: #767977;
	text-align: left;
}
.pricesTabOffer_text {
	display: table;
	width: 90%;
	margin: auto;
	font-weight: 300;
	font-size: 13px;
	color: #767977;
	text-align: left;
}

.buttonPricesSelectSeats {
	font-size: 15px;
	line-height: 21px;
	text-transform: none;
	padding: 15px 0;
	width: 100%;
	max-width: 90%;
	background: #eeeff1;
	color: #3c3c3b;
	border-width: 0px !important;
	border-radius: 20px !important;
	box-shadow: -3px -3px 7px 3px #fff, 3px 3px 7px 3px #ccc;
}
.buttonPricesSelectSeats:hover {
	background: #11284b;
	color: #fff;
}

.buttonPricesSelectSeatsPrice {
	font-size: 20px;
	font-weight: bold;
	padding-left: 5px;
}

.buttonPricesSelectSeatsRed {
	background: #ff004e;
}

.buttonPricesSelectSeatsBlue {
	background: #e92224;
}

#buyBidModal_Title {
	font-size: 20px;
	font-weight: bold;
	line-height: 30px;
}

.pricesTabContainerHalf {
	display: table;
	width: 45%;
	float: left;
	font-size: 17px;
	line-height: 20px;
	padding: 7px 0px 3px 0px;
}

.pricesTabContainerVS {
	display: table;
	width: 10%;
	float: left;
	font-size: 15px;
	padding: 25px 0px 3px 0px;
	height: 35px;
	max-width: 35px;
}

.pricesTabContainerVScircle {
	display: table-cell;
	border-radius: 100%;
	vertical-align: middle;
	font-weight: bold;
	border: 1px solid #ddd;
}

.pricesTabContainerFull {
	display: table;
	width: 100%;
	max-width: 95%;
	font-size: 17px;
	line-height: 20px;
}

.priceTabOur {
	color: #e92224;
	font-size: 20px;
	font-weight: bold;
	margin-right: 7px;
}

.priceTabOurSmall {
	font-style: italic;
	font-size: 13px;
}

.priceTabTitle {
	margin-bottom: 5px;
}

.priceTabTheir {
	font-weight: bold;
}

.pricesTab:hover {
	/* cursor: pointer; */
	/* background: #000000;
	border: 1px solid transparent;
	color: #ffffff; */
}

.pricesTab:hover > .pricesTabTeamName {
	/* color: #ffffff; */
}

/*===================== Events with bet list======================*/
.event-row {
	margin: 10px;
	border-radius: 10px;
	padding: 15px 25px;
	background: #eee;
	width: calc(100vw - 25px);
	max-width: 1000px;
}
.event-row .event-title {
	text-align: left;
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
}
.event-row-container {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	grid-gap: 2vw;
	grid-auto-rows: 1fr;
}
.col-event-desc {
	text-align: left;
}
.col-event-reserve {
	display: inline-flex;
}
.col-event-reserve:hover {
	cursor: pointer;
}
.col-event-bet {
	display: inline-flex;
}
.col-event-bet:hover {
	cursor: pointer;
}
.event-row .reserve-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	width: 100%;
	height: auto;
	background: #0c4291;
	border-radius: 8px;
	color: #fff;
}
.event-row .bet-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	width: 100%;
	height: auto;
	background: #04752c;
	border-radius: 8px;
	color: #fff;
}
.event-row .btn-content span {
	display: block;
}
.event-row .btn-content .btn-title {
	font-size: 16px;
	font-weight: bold;
}
/*===================== End Events with bet list==================*/

.seatsContainer {
	display: table;
	margin: 60px auto 30px auto;
	width: 90%;
	max-width: 1000px;
}

.seatsStadiumContainer {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	max-width: 49.5%;
}

.seatsStadiumContainer img {
	width: 100%;
	height: auto;
}

.seatsInfoContainer {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	vertical-align: top;
}

.seatsTitleDate {
	font-size: 15px;
	line-height: 25px;
}

.seatsSelect,
.zoneSelect {
	margin: 7px auto;
}

#showIfMore5Seats {
	display: none;
	font-size: 15px;
	line-height: 18px;
	margin: 10px auto;
	max-width: 280px;
	padding: 13px 5px;
}

.seatsText {
	font-size: 20px;
	margin: 10px auto;
	display: table;
	width: 95%;
	max-width: 280px;
}

.seatsText_faceValueContainer {
	border-top: 1px solid #ddd;
	padding-top: 20px;
	margin-top: 20px;
	position: relative;
}

.seatsText_left {
	float: left;
}

.seatsText_right {
	float: right;
}

.text_TBD {
	text-decoration: underline;
	color: #e92224;
}

.seatsTextSmall {
	font-size: 15px;
	font-style: italic;
	display: block;
	text-align: left;
	line-height: 20px;
}

.seatsPrice {
	font-weight: bold;
}

#seats_ticketCost:hover,
#checkout_costTBD:hover {
	cursor: pointer;
}

#seats_ticketCost:hover ~ .TBD_tooltip,
#checkout_costTBD:hover ~ .TBD_tooltip {
	display: block;
}

.TBD_tooltip {
	top: 37px;
	left: 0;
	right: 0;
	margin: 10px auto 20px auto;
	width: 100%;
	max-width: 300px;
	font-size: 15px;
	line-height: 20px;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 11px;
	z-index: 10;
	padding: 10px;
	position: absolute;
	display: none;
}
/*
.TBD_tooltip:after {
	content: "";
	display: block;
	position: absolute;
	left: 250px;
	bottom: 100%;
	width: 0;
	height: 0;
	border-bottom: 10px solid #ddd;
	border-top: 10px solid transparent;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
}
*/
/*
#seats_ticketCost:hover + .TBD_tooltip {
	display: table;
}
*/
#buttonAddToCart,
#buttonSeatsPlaceBid {
	font-size: 17px;
	padding: 15px 30px;
	margin: 10px auto 10px auto;
}
#buttonAddToCart[disabled] {
	opacity: 0.3;
	cursor: not-allowed;
}
#buttonSeatsPlaceBid[disabled] {
	opacity: 0.3;
	cursor: not-allowed;
}
.addToCartNotify {
	border: 1px dashed #000000;
	margin: 20px auto 0 auto;
	padding: 20px 0px;
	font-size: 15px;
	color: #000000;
	display: table;
	width: 95%;
	max-width: 300px;
	background: #fff;
}

.addToCartNotify a {
	color: #000000;
	font-weight: bold;
	text-decoration: underline;
}

.checkoutContainer {
	display: table;
	margin: 30px auto 0px auto;
	width: 90%;
	max-width: 900px;
}

.checkoutInfo {
	width: 100%;
	max-width: 410px;
	display: inline-block;
	vertical-align: top;
}

.checkoutInputHalf {
	width: 49.1%;
	max-width: 100%;
	display: inline-block;
}

.checkoutInputThird {
	width: 32.4%;
	max-width: 100%;
}

.checkoutInputFull {
	width: 100%;
	max-width: 100%;
}

.checkoutInput {
	margin: 5px auto;
}

.creditCardsIcons {
	width: 100%;
	max-width: 250px;
	height: auto;
	display: table;
	margin: 20px auto;
}

.powered_by_venmo_footer {
	width: 100%;
	max-width: 100px;
	height: auto;
	display: table;
	margin: 0 auto 20px auto;
}

.buttonPay {
	width: 300px;
	text-align: center;
	font-size: 17px;
	padding: 17px 0px;
	margin: 10px auto;
}

.checkoutSummary {
	width: 100%;
	max-width: 300px;
	display: inline-block;
	vertical-align: top;
	padding-top: 10px;
}

.checkoutSummaryMobile {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}

.checkoutSummaryInner {
	height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 10px auto;
}

.checkoutSummaryInnerMobile {
	background: #fff;
}

.checkoutSummaryTitle {
	font-size: 20px;
	font-weight: bold;
}

.checkoutSummaryText {
	margin: 10px auto;
}

.checkoutSummaryTeamTitle {
	margin: 10px auto;
	font-weight: bold;
	border-top: 1px dashed #aaa;
	padding-top: 20px;
}

.checkoutSummaryTotal {
	margin: 15px auto 0px auto;
	padding-top: 15px;
	border-top: 1px solid #ddd;
	display: table;
	width: 250px;
	font-size: 20px;
	position: relative;
}

.checkoutSummaryTotalText {
	float: left;
	font-weight: bold;
}

.checkoutSummaryTotalTextSmall {
	font-size: 13px;
	font-weight: normal;
	font-style: italic;
}

.checkoutSummaryTotalPrice {
	float: right;
	font-weight: bold;
}

.checkoutSummaryRow {
	display: table;
	width: 100%;
}

.checkoutSummaryMiddle {
	float: left;
	font-size: 15px;
	margin: 4px 0px;
}

.checkoutSummaryMiddlePrice {
	float: right;
	font-weight: bold;
	font-size: 15px;
	margin: 4px 0px;
}

.checkoutPaidOnly {
	font-size: 15px;
	font-style: italic;
	display: block;
	text-align: left;
	line-height: 20px;
}

.checkoutSecureImage {
	width: 80%;
	display: table;
	margin: 40px auto 0px auto;
}

#checkoutError {
	border: 1px solid #ff004e;
	margin: 20px auto 0 auto;
	padding: 20px 0px;
	font-size: 15px;
	color: #ff004e;
	width: 95%;
	background: rgba(255, 0, 78, 0.07);
}

#checkoutError-mobile {
	border: 1px solid #ff004e;
	margin: 20px auto 0 auto;
	padding: 20px 0px;
	font-size: 15px;
	color: #ff004e;
	width: 95%;
	background: rgba(255, 0, 78, 0.07);
	display: none;
}

.checkoutTermsLink {
	color: #555;
	text-decoration: underline;
}

.loginRegisterContainer {
	display: table;
	width: 95%;
	max-width: 410px;
	margin: 0 auto;
}

.loginRegisterTitle {
	font-size: 20px;
	margin: 0px auto 20px auto;
	font-weight: bold;
	color: #000000;
}

#loginError,
#registerError {
	display: none;
}

.registerMandatory {
	font-size: 13px;
	color: #888;
	font-style: italic;
	margin: 5px 0px;
}

.tabButton {
	display: inline-block;
	padding: 20px 0px;
	margin: 20px auto;
	font-size: 20px;
	font-weight: bold;
	border: 1px solid #0a1529;
	color: #000000;
	/* border-bottom: 1px solid #ddd; */
	background: #eee;
}

.tabButton2 {
	width: 50%;
}

.tabButton4 {
	width: 25%;
}

.tabActive {
	background: #0a1529 !important;
	color: #ff7500 !important;
}

.tabButton:hover {
	cursor: pointer;
	background: #0a1529;
	color: #ff7500;
}

.my-tab-button:hover {
	cursor: pointer;
	background: #0a1529;
	color: #ff7500;
}

.tabActive:hover {
	cursor: pointer;
	background: #0a1529;
	color: #ff7500;
}

.contentTab {
	display: none;
}

.contentTabActive {
	display: block;
}

.contentTabInnerHalf {
	display: inline-block;
	width: 50%;
	max-width: 410px;
	margin: 10px auto;
	padding: 0px 1%;
}

.buttonSaveProfile {
	/*display: inline-block;*/
	width: 203px;
	text-align: center;
	font-size: 17px;
	padding: 15px 0px;
	vertical-align: top;
	margin: 6px auto 20px auto;
}

.buttonLoginRegister,
.buttonForgotPassword {
	width: 200px;
	text-align: center;
	font-size: 17px;
	padding: 17px 0px;
	display: inline-block;
	vertical-align: top;
	margin: 10px auto 20px auto;
}

.buttonSocialConnect {
	text-align: center;
	font-size: 17px;
	padding: 17px 0px;
	display: block;
	vertical-align: top;
	margin: 5px auto 5px auto;
	width: 250px;
}

.buttonSocialConnect img {
	height: 20px;
	width: 20px;
	margin: 0 10px 0 20px;
	float: left;
}

.buttonSocialConnect span {
	text-transform: none;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	padding-right: 20px;
}

.buttonFacebook {
	background: #3b5998;
}

.buttonGoogle {
	background: #dd4b39;
}

.buttonTwitter {
	background: #5baaf4;
}

.forgotPasswordLink {
	font-size: 15px;
	text-decoration: underline;
	margin: 10px auto 5px auto;
	display: table;
	color: #888;
}

.forgotTabTitle {
	padding: 20px 0px;
	margin: 20px auto;
	font-size: 20px;
	font-weight: bold;
	color: #000000;
}

.checkoutTerms {
	font-size: 15px;
	margin-top: 5px;
	padding: 10px 0 10px 0px;
	border: 1px solid transparent;
}

.checkoutTerms-mobile {
	font-size: 15px;
	margin-top: 5px;
	padding: 10px 0 10px 0px;
	border: 1px solid transparent;
}

.checkoutTermsError {
	border: 1px dashed #ff004e;
}

.checkoutTerms span {
	text-decoration: underline;
}

.checkoutTerms-mobile span {
	text-decoration: underline;
}

.checkoutTerms:hover {
	cursor: pointer;
}

.checkoutTermsContainer {
	display: none;
	font-size: 15px;
	margin-top: 10px;
	line-height: 19px;
	text-align: left;
	padding-left: 20px;
}

.checkoutTermsContainer ul li {
	margin-bottom: 5px;
}

#checkoutCountdown {
	margin: 30px auto 0px auto;
	max-width: 700px;
	font-size: 18px;
}

#countdownNumber {
	font-weight: bold;
}

#checkoutCardDetails {
	margin: 10px auto;
}

ol,
ul {
	list-style: disc;
}

.generalPagesUL {
	list-style: none;
	text-align: left;
	font-size: 15px;
	line-height: 21px;
	margin: 5px 0;
}

.generalPagesUL li {
	padding-left: 25px;
}

.newsPostContent li {
	margin-left: 25px;
}

.thankyouContainer {
	width: 90%;
	margin: 0 auto;
	max-width: 500px;
}

.buttonThankyou {
	margin-top: 10px;
	font-size: 17px;
	padding: 15px 40px;
}

#checkBoxTerms {
	display: inline-block;
	width: initial;
	margin-right: 10px;

	zoom: 1.3;
	transform: scale(1.3);
	-ms-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	-o-transform: scale(1.3);
	-moz-transform: scale(1.3);
	transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	-o-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}

#checkBoxTerms-mobile {
	display: inline-block;
	width: initial;
	margin-right: 10px;

	zoom: 1.3;
	transform: scale(1.3);
	-ms-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	-o-transform: scale(1.3);
	-moz-transform: scale(1.3);
	transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	-o-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}

.thankyouClickA {
	text-decoration: underline;
	color: #e92224;
	font-weight: bold;
}

.breadcrumbsContainer {
	padding: 15px 20px;
	background: #eee;
	line-height: 21px;
}

.breadcrumbLink {
	color: #555;
	margin: 0px 5px;
	font-weight: bold;
}

.breadcrumbLink:hover {
	text-decoration: underline;
}

.progressBarContainer {
	margin: 30px auto 30px auto;
	border-radius: 11px;
	background: #dedede;
	display: table;
	height: 17px;
	width: 95%;
	max-width: 500px;
	position: relative;
	box-shadow: 0px 0px 5px #000;
}

.progressBar {
	display: table;
	position: absolute;
	height: 100%;
	background: #e92224;
	border-radius: 11px;
}

.progressBar_teams {
	width: 30%;
}

.progressBar_prices {
	width: 60%;
}

.progressBar_seats {
	width: 80%;
}

.progressBar_checkout {
	width: 95%;
}

.progressBar_percent {
	position: absolute;
	display: table;
	width: 100%;
	top: 2px;
	right: 0;
	left: 0;
	font-size: 13px;
	font-weight: bold;
	color: #000000;
}

.modalOverlay {
	background: rgb(10 21 41 / 0.9);
	top: 115px;
	width: 100%;
	height: 200vh;
	position: absolute;
	z-index: 100;
	display: none;
}

.modal {
	display: block;
	width: 95%;
	max-width: 900px;
	background: #ffffff;
	border: 1px solid #ddd;
	/*box-shadow: 0px 0px 20px #888;*/
	position: absolute;
	z-index: 20;
	top: 77px;
	right: 0;
	left: 0;
	margin: 0 auto;
}

.modalInner {
	display: table;
	margin: 50px auto 40px auto;
	width: 95%;
	max-width: 500px;
	text-align: center;
}

.modalClose {
	background: #ff004e;
	color: #ffffff;
	padding: 7px 11px;
	font-size: 17px;
	font-weight: bold;
	position: absolute;
	right: 10px;
	top: 10px;
}

.modalClose:hover {
	cursor: pointer;
	opacity: 0.9;
}

#cookieBar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: table;
	padding: 20px 0px;
	background: rgba(0, 0, 0, 0.9);
	color: #aaa;
	z-index: 999999999999;
	display: none;
}

.cookieBarInner {
	display: table;
	width: 95%;
	max-width: 1000px;
	margin: 0 auto;
}

#cookieBarClose {
	background: #e92224;
	color: #ffffff;
	padding: 10px 20px;
	font-size: 17px;
	font-weight: bold;
	margin: 20px auto 10px auto;
	display: table;
}

#cookieBarClose:hover {
	cursor: pointer;
	opacity: 0.95;
}

#privacyScroll,
#useragreementScroll {
	text-decoration: underline;
	color: #000000;
}

#privacyScroll:hover,
#useragreementScroll:hover {
	cursor: pointer;
}

.notificationError {
	border: 1px solid #ff004e;
	border-radius: 11px;
	margin: 10px auto 10px auto;
	padding: 20px 2%;
	font-size: 15px;
	color: #ff004e;
	width: 95%;
	max-width: 700px;
	background: rgba(255, 0, 78, 0.07);
	text-align: center;
}

.notificationError_cart {
	display: table;
	width: 100%;
	margin: 5px auto;
	padding: 10px 0;
}

.notificationSuccess {
	margin: 10px auto 10px auto;
	border: 1px solid #288e44;
	border-radius: 11px;
	background: rgba(0, 231, 119, 0.07);
	color: #000000;
	padding: 20px 2%;
	display: table;
	width: 95%;
	max-width: 700px;
	font-size: 21px;
	line-height: 19px;
}

.notificationInform {
	margin: 10px auto 10px auto;
	border: 1px solid #0a1529;
	border-radius: 11px;
	background: rgba(10, 21, 41, 0.07);
	color: #000000;
	padding: 20px 2%;
	display: table;
	width: 95%;
	max-width: 700px;
	font-size: 15px;
	line-height: 19px;
}

.notificationGeneral {
	border: 1px solid #ddd;
	border-radius: 11px;
	margin: 10px auto 10px auto;
	padding: 20px 2%;
	font-size: 15px;
	color: #555;
	display: table;
	width: 95%;
	max-width: 700px;
	background: #f3f2f1;
	text-align: center;
}

.notificationGoldenTicket {
	border: 1px solid #bc8d36;
	border-radius: 11px;
	margin: 10px auto 10px auto;
	padding: 20px 2%;
	font-size: 15px;
	color: #bc8d36;
	display: table;
	width: 95%;
	max-width: 700px;
	background: rgba(188, 141, 54, 0.07);
	text-align: center;
}

#profileError_info,
#profileSuccess_info,
#profileError_money,
#profileSuccess_money {
	display: none;
}

#forgotSuccess,
#forgotError {
	display: none;
}

#forgotSuccess,
#forgotError {
	display: none;
}

#thankyouShareError {
	display: none;
}

#thankyouShareSuccess {
	display: none;
}

.contactContainer {
	width: 95%;
	max-width: 800px;
	margin: 0 auto;
}

.affiliatesContainer {
	width: 95%;
	max-width: 800px;
	margin: 0 auto 50px auto;
}

.affiliateAdContainer {
	text-align: left;
	width: 95%;
	margin: 10px auto;
}

.affiliateAdContainer div {
	margin-bottom: 20px;
}

.affiliateAdContainer div p {
	margin-bottom: 10px;
}

.affiliateAdImage {
	max-width: 300px;
	width: auto;
	max-height: 150px;
	height: auto;
	display: inline-block;
	margin: 5px;
}
.influnecer-interested-wrap {
	line-height: 1.5;
}
.influnecer-interested-wrap .form-check-input {
	width: auto;
}

#contactUs_success {
	display: none;
}

#contactError,
#contactSuccess {
	display: none;
}

.reservationRow {
	display: table;
	width: 95%;
	background: #eee;
	border: 1px solid #ddd;
	margin: 20px auto;
}

.reservationRow:hover {
	cursor: pointer;
	background: #000000;
	border: 1px solid transparent;
	color: #f7f7f7;
}

.reservationRow:hover > .reservationRowThird_1 {
	color: #f7f7f7;
}

.reservationRowThird_1 {
	display: table-cell;
	vertical-align: middle;
	width: 34%;
	/*width: 50%;*/

	text-align: left;
	padding: 30px 0px 30px 2%;
	color: #000000;
}

.reservationRowThird_2 {
	display: table-cell;
	vertical-align: middle;
	width: 34%;
	/*width: 50%;*/

	padding: 15px 0px;
}

.reservationRowThird_3 {
	display: table-cell;
	/*display: none;*/

	vertical-align: middle;
	width: 30%;
	padding: 15px 0px;
}

.buttonReservationBidSellRow {
	font-size: 15px;
	line-height: 21px;
	padding: 15px 0;
	width: 100%;
	max-width: 90%;
}

.buttonRed {
	background: #ff004e;
}

.buttonOrange {
	background: #ff7700;
}

.buttonGreen {
	background: #288e44;
}

.buttonBlue {
	background: #0a1529;
}

.buttonGray {
	background: #888;
}

.buttonPurple {
	background: #ca00ca;
}

.reservationRowThird_title {
	font-size: 18px;
	line-height: 23px;
	font-weight: bold;
}

.reservationRowThird_subtitle {
	font-size: 16px;
	line-height: 21px;
}

.profileWalletLeft {
	display: inline-block;
	width: 40%;
	text-align: left;
}

.priceWalletLeftMoney {
	font-weight: bold;
	/*color: #000000;*/
}

.profileWalletCenter {
	display: inline-block;
	width: 10%;
	text-align: center;
}

.profileWalletRight {
	display: inline-block;
	width: 40%;
	text-align: right;
	font-weight: bold;
	color: #000000;
}

#profileWithdraw {
}

#profileWithdraw:hover {
	cursor: pointer;
}

.profileWalletContainer {
	border: 1px solid #ddd;
	margin: 5px auto 5px auto;
	padding: 16px 0px;
	font-size: 17px;
	color: #555;
	width: 99%;
	max-width: 700px;
	background: #f3f2f1;
	text-align: center;
}

.accountModalContainer {
	margin: 10px auto 30px auto;
}

.modalRow {
	display: table;
	width: 100%;
	padding: 10px 0px;
	margin: 20px auto;
}

.modalRowBorder {
	border: 1px solid #ddd;
	border-radius: 11px;
	background: #f3f2f1;
}

.modalFull {
	display: table;
	margin: 0 auto;
	width: 90%;
	text-align: center;
}

.modalFull_blue {
	background: rgb(104, 203, 244, 0.3);
	padding-bottom: 20px;
	margin: 10px auto;
	border-radius: 11px;
}

.modalHalf {
	float: left;
	width: 50%;
	text-align: center;
}

.modalHalfSell_1 {
	width: 40%;
}

.modalHalfSell_2 {
	width: 60%;
}

.modalHalf input,
.modalHalf select {
	width: 95%;
}

.currentZoneBids,
.currentZoneOffers {
	max-height: 250px;
	overflow: auto;
	/*width: inherit;*/
	margin: auto;
}

.modalTitle {
	font-size: 18px;
	line-height: 23px;
	font-weight: bold;
	margin: 15px auto;
}

.modalTitle {
	font-size: 23px;
	line-height: 30px;
	font-weight: bold;
	margin: 40px auto 10px auto;
	text-align: center;
	width: 80%;
}

.modalText {
	font-size: 16px;
	line-height: 21px;
}

.modalText.bid {
	padding: 5px 10px;
	border: 1px solid #ddd;
}

.notificationInform .modalText {
	font-size: 16px;
	line-height: 21px;
	width: 90%;
	margin: 5px auto;
	text-align: left;
}

.notificationError .modalText {
	width: 90%;
	margin: 0 auto;
}

#accountReservationSellSuccess,
#accountSellingSuccess,
#accountBidSuccess {
	display: none;
}

#accountReservationSellGeneral,
#accountSellingGeneral,
#accountSellingEditSuccess,
#accountBidGeneral {
	display: none;
}

#accountWalletPaypalInput {
	display: none;
}

.modalTransparent {
	background: transparent;
	border: none;
	max-width: 500px;
}

.modalFullImage {
	width: 100%;
	border: 2px solid #eee;
}
.modalFullImage_height {
	height: auto;
	width: 95%;
	margin: auto;
	display: table;
}
.modalFullImage:hover,
.modalFullImage_height:hover {
	cursor: pointer;
}

.v-align-baseline {
	align-items: baseline;
}

.sectionForm {
	display: block;
	width: 100%;
	max-width: 420px;
	margin: 50px auto;
}

.sectionFormText {
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 10px auto;
	text-align: left;
	font-size: 17px;
	line-height: 21px;
}

.sectionFormTitle {
	font-size: 18px;
	line-height: 23px;
	margin: 25px 0px 10px 0px;
	text-align: left;
	font-weight: bold;
}

#sectionForm_realfans {
	max-width: 350px;
}

#realfans_submit {
	margin-top: 30px;
}

.formInputHalf {
	width: 49.1%;
	max-width: 100%;
}

.formInputThird {
	width: 32.4%;
	max-width: 100%;
}

.formInputFull {
	width: 100%;
	max-width: 100%;
}

.formInput {
	margin: 5px auto;
}

/*--------------------------------------- BLOG NEWS -----------------------------------*/
.newsTabsContainer {
	display: table;
	width: 95%;
	max-width: 1200px;
	margin: 20px auto 0px auto;
	text-align: center;
}

.newsTab {
	display: inline-block;
	width: 96%;
	max-width: 350px;
	margin: 10px 1%;
	background: #fff;
	border-radius: 3px;
	border: 1px solid #eee;
	border-bottom: 2px solid #ddd;
	border-right: 2px solid #ddd;
}

.newsTab:hover {
	border-bottom: 2px solid #ccc;
	border-right: 2px solid #ccc;
	box-shadow: 0px 0px 10px 0px #ddd;
}

.newsTabImage {
	width: 100%;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	display: none;
}

.newsTabTitle {
	margin: 13px auto;
	display: table;
	color: #000000;
	font-size: 17px;
	line-height: 21px;
	font-weight: bold;
	text-align: left;
	width: 90%;
	height: 70px;
}

.newsTabTitleInner {
	display: table-cell;
	vertical-align: middle;
}

.newsTabDate {
	margin-bottom: 10px;
	color: #888;
	font-size: 13px;
	font-style: italic;
	text-align: right;
	width: 95%;
}

.newsPostContainer {
	display: table;
	width: 95%;
	max-width: 700px;
	margin: 20px auto 0px auto;
}

.newsPostContainer p,
.newsPostContainer ul,
.newsPostContainer span,
.newsPostContainer h1,
.newsPostContainer h2,
.newsPostContainer h3 {
	margin: 10px auto;
	text-align: left;
	font-size: 15px;
	line-height: 21px;
}

.newsPostFeaturedImage {
	width: 100%;
}

.newsPostContent {
	margin-top: 30px;
}

.newsTabsMore {
	display: table;
	margin: 20px auto;
}

.newsTabsMoreButton {
	font-size: 17px;
}

.savePageTable {
	display: table;
	width: 100%;
	text-align: right;
	margin: 50px auto 20px auto;
}

.savePageTable th {
	border-bottom: 2px solid #ddd;
	font-weight: bold;
	padding: 10px 20px;
	font-size: 20px;
}

.savePageTable th:first-child {
	text-align: left;
}

.savePageTable td {
	border-bottom: 1px solid #ddd;
	padding: 10px 20px;
}

.savePageTable td:first-child {
	text-align: left;
}

.savePageTable td:last-child,
.savePageTable th:last-child {
	font-weight: bold;
	background: #288e44;
}

.savePageTable tr:hover {
	background: #e7e7e7;
}

.savePageDisclaimer {
	margin: 0px auto 20px auto;
	display: table;
	max-width: 95%;
	width: 100%;
}

.savePageDisclaimer p {
	font-size: 15px;
	line-height: 18px;
	color: #555;
	font-style: italic;
	text-align: left;
	margin: 10px 0px;
}

body.fixed-pac-container div.pac-container {
	position: fixed !important;
}

/*==================================== END BLGO NEWS ==================================*/

/*------------------------ HIDE MARKETPLACE BID SELL ---------------*/
#tabButton_bid,
#tab_bid,
#tabButton_selling,
#tab_selling {
	/*display: none;*/
}

/*--------------------------------- SEATSMART TICKETS -----------------------------------*/
#seatsmartIframe {
	margin-top: 49px;
	height: calc(100vh - 49px);
	width: 100%;
	display: block;
}

.socialPlugin {
	width: 95%;
	max-width: 500px;
	display: inline-block;
	margin: 0px 10px;
	padding-top: 20px;
}

.juicerContainer {
	margin: 20px auto;
	width: 95%;
	max-width: 1300px;
}

/* ====================== dev2 ========================== */

.invalid-feedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
	display: block;
}

.checkoutInput.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid,
input.is-invalid {
	border-color: #dc3545;
}

.fleft {
	float: left;
}

.valid-feedback {
	color: #5cb85c;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger {
	color: #d9534f;
}
.form-control-feedback {
	margin-top: 0.25rem;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
	padding-right: 2.25rem;
	background-repeat: no-repeat;
	background-position: center right 0.5625rem;
	-webkit-background-size: 1.125rem 1.125rem;
	background-size: 1.125rem 1.125rem;
}

.has-danger .form-control,
.form-control-danger {
	border-color: #d9534f;
}

.has-danger .form-control-danger {
	background-image: url(data:image/svg + xml;charset=utf8, %3Csvgxmlns="http://www.w3.org/2000/svg"f…%3E%3Ccirclecy="3"r=".5"/%3E%3Ccirclecx="3"cy="3"r=".5"/%3E%3C/svg%3E);
}

.valid-feedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #28a745;
	display: block;
}

.col {
	padding-left: 5px;
	padding-right: 5px;
}

a {
	text-decoration: none;
	color: inherit;
}

.sectionSteps234Header {
	padding: 50px 0px 30px 0px;
}

.textWhite {
	color: #fff;
}

.zoneSelectNew,
.seatsSelectNew,
.zoneSelectNewBid,
.seatsSelectNewBid {
	max-width: 300px;
}

.my-account-row {
	background: #eee;
	border: 1px solid #ddd;
	margin: 20px 20px;
}

.my-account-row-column {
	padding: 30px 0px 30px 2%;
	text-align: center;
}

.selling-history-row {
	margin-bottom: 10px;
}
.selling-history-title {
	display: inline-block;
	font-size: 18px;
	font-weight: bold;
	padding-bottom: 10px;
}
.selling-history-title:hover {
	cursor: pointer;
}
.selling-history-title-icon {
	font-size: 15px;
	margin-left: 5px;
}

.sale-history-table thead {
	font-weight: bold;
}

.my-account-button {
	font-size: 15px;
	line-height: 21px;
	margin: 10px auto;
	/*padding: 15px 0;*/
	/*width: 100%;*/
	/*max-width: 90%;*/
}

.account-ticket-wrap {
	padding: 0 20px;
}

.shareFacebookAffiliate,
.shareTwitterAffiliate,
.shareInstagramAffiliate {
	padding: 7px 10px;
	display: inline-block;
	margin: 10px 5px;
	border-radius: 5px;
}

.shareFacebookAffiliate span,
.shareTwitterAffiliate span,
.shareInstagramAffiliate span {
	color: #fff;
	font-size: 15px;
	padding-top: 2px;
	display: block;
	float: right;
}

.shareFacebookAffiliate img,
.shareTwitterAffiliate img,
.shareInstagramAffiliate img {
	height: 15px;
	width: auto;
	margin-right: 7px;
}

.shareFacebookAffiliate {
	background: #3b5998;
}

.shareTwitterAffiliate {
	background: #00acee;
}

.shareInstagramAffiliate {
	background: #e1306c;
}

#everflow_data {
	display: table;
	margin: 20px auto;
}

.referralStatsTab {
	display: inline-block;
	width: 200px;
	margin: 10px;
	padding: 10px 0;
	border: 1px solid #ddd;
	background: #fff;
	border-radius: 11px;
}

.referralStatsTabTitle {
	font-weight: bold;
	margin-bottom: 10px;
}

.check-referral {
	font-size: 15px;
}

.my-account-tabs-row {
	width: 95%;
	max-width: 800px;
	margin: 20px auto 0px auto;
}

.account-tabs {
	margin: 20px 0;
}

.my-tab-button {
	padding: 20px 10px;
	margin: 0 auto;
	font-size: 20px;
	font-weight: bold;
	border: 1px solid #0a1529;
	margin-right: -2px;
	background: #eee;
	color: #000000;
	white-space: nowrap;
}

.my-tab-container {
	max-width: 1000px;
	margin: 20px auto 0px auto;
}

.my-login-register-container {
	width: 95%;
	max-width: 410px;
	margin: 0 auto;
}

.newHeaderTextImage_maintenance {
	max-width: 500px;
	width: 70%;
}

.newHeaderTextImage_404 {
	max-width: 350px;
	width: 90%;
	margin: auto;
}

.my-profile-affiliate {
	margin: 5px auto 20px auto;
	padding: 15px 0;
	font-size: 17px;
	color: #555;
	width: 99%;
	max-width: 700px;
	text-align: center;
	border: 1px solid #ddd;
	background: #f3f2f1;
}

.my-profile-affiliate-link {
	margin: 5px auto;
}

.my-profile-affiliate-link a {
	color: #e92224;
	-ms-word-break: break-all;
	word-break: break-all;
}

.my-profile-affiliate-link a:hover {
	color: #e92224;
	text-decoration: underline;
	cursor: pointer;
}

.my-profile-affiliate-title {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: bold;
}

.my-profile-affiliate-text {
	font-size: 17px;
	margin: 5px auto;
	line-height: 21px;
}

.my-profile-affiliate-text div {
	margin: 5px;
}

.my-profile-affiliate-text a {
	/*color: #e92224;*/
	text-decoration: underline;
}

.my-profile-affiliate-text a:hover {
	color: #e92224;
}

.my-profile-wallet {
	margin: 5px auto 20px auto;
	padding: 15px 0 0 0;
	font-size: 17px;
	color: #555;
	width: 99%;
	max-width: 700px;
	text-align: center;
	border: 1px solid #ddd;
	background: #f3f2f1;
}

.my-profile-wallet-title {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: bold;
}

.my-profile-wallet-row {
	margin: 10px auto;
}

.my-profile-wallet-purchase {
	font-weight: 700;
	font-size: 25px;
	margin: 7px auto 0 auto;
}

.my-profile-wallet-withdraw {
	font-weight: 700;
	margin: 5px auto 0 auto;
}

.my-profile-wallets-container {
	position: relative;
}

.my-profile-wallets-tooltips {
	position: absolute;
	top: 35px;
	left: 0;
	right: 0;
	margin: 10px auto 20px auto;
	width: 100%;
	max-width: 300px;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 11px;
	z-index: 10;
	padding: 10px 15px;
	text-align: center;
	line-height: 20px;
	box-shadow: 0px 0px 10px 0px #ccc;
	display: none;
	/*margin: 10px auto 0px auto;
	width: 100%;
	max-width: 300px;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 11px;
	padding: 10px;
	text-align: center;
	font-size: 15px;
	font-style: italic;
	line-height: 18px;
	box-shadow: 0 0 10px 0 #ccc;
	display: table;*/
}

#my-profile-forPurchase-tooltip {
	top: 45px;
}

#my-profile-forPurchase-hover:hover,
#my-profile-forWithdraw-hover:hover {
	cursor: pointer;
	text-decoration: underline;
	color: #e92224;
}

#my-profile-forPurchase-hover:hover ~ #my-profile-forPurchase-tooltip {
	display: block;
}

#my-profile-forWithdraw-hover:hover ~ #my-profile-forWithdraw-tooltip {
	display: block;
}

.newButtonContainer {
	display: table;
	margin: 5px auto;
	width: 100%;
}

.buttonPayPal {
	width: 300px;
	background: #ffc439;
	margin: auto;
	outline: 0;
	padding: 8px !important;
}

.buttonPayPal img {
	width: auto;
	height: 30px;
}

.apple-pay-button {
	width: 300px !important;
	margin: 5px auto;
	border-radius: 11px;
}

.paypal-button-container {
	width: 300px !important;
	margin: 5px auto;
	border-radius: 11px;
}

/*-------------------------------- TICKER PAGE CSS -------------------------------------*/
#salesTickerWidget {
	position: fixed;
	bottom: 0;
	background: #eee;
	left: 0;
	right: 0;
	z-index: 20;
	box-shadow: 0px 0px 7px -2px #888;
}

.marquee-container {
	color: #000;
	border-top: 1px solid #e3e3e3;
	/*border-bottom: 1px solid #e3e3e3;*/
	/* padding: 10px 5px 0px 5px; */
}
.marquee-desc-wrap {
	display: inline-block;
	line-height: 18px;
	width: 60px;
	float: left;
	color: #000;
	font-weight: bold;
	position: relative;
}
.marquee-desc-wrap img {
	margin-left: -5px;
	height: 40px;
	float: left;
	margin-top: 0;
}
.marquee-desc-wrap:after {
	content: "\f054";
	font-family: "Font Awesome 5 Free";
	color: #000;
	position: absolute;
	right: 3px;
	top: 12px;
	display: none;
}
.ticker-item {
	display: inline-block;
	margin: 10px 10px 0 10px;
	text-align: center;
	color: #000;
}
.ticker-item:hover {
	text-decoration: none;
}
.ticker-item img {
	height: 20px;
	width: auto;
}
.ticker-event {
	display: block;
	line-height: 15px;
	font-size: 12px;
	padding-top: 3px;
}
.ticker-team {
	display: block;
	line-height: 18px;
	font-size: 14px;
}

.bb-alternate-modal {
	background: none !important;
	border: none !important;
	max-width: none !important;
	top: 150px !important;
	z-index: 2000 !important;
	box-shadow: none !important;
	margin-top: 15%;
}
/*============================== END TICKER PAGE CSS ===========================*/

/*---------------------------- CART -------------------------------*/
.sectionHeaderCart {
	background-image: url("../img/header/loginRegister/header_20promo.jpg");
	background-position: center bottom;
	/*height: 450px;
	min-height: 450px;*/
	height: auto;
	min-height: initial;
	padding: 50px 0;
}

.cartElementQty {
	display: table-cell;
	vertical-align: middle;
	padding-left: 30px;
	padding-right: 50px;
	padding-top: 15px;
	font-size: 30px;
	font-weight: bold;
	color: #000000;
	display: none;
}
.cartElementQty_text {
	font-size: 15px;
	font-weight: normal;
}
.cartElementQty_number {
	margin-top: 3px;
}
.cartElementFee {
	font-size: 17px;
	color: #000000;
	margin: 7px auto;
}
.cartElementPriceEach {
	font-size: 13px;
	color: #000000;
	font-weight: normal;
}

.cartButtons {
	display: inline-block;
	margin: 5px auto;
	padding: 0px 10px;
}

#cartToCheckoutButton {
	background-color: #0a1529;
	font-size: 17px;
	width: 300px;
}

.cartToCheckout_disabled {
	background: #ccc !important;
}
.cartToCheckout_disabled:hover {
	cursor: no-drop !important;
}

#cartContinueShoppingButton {
	background-color: #0a1529;
	font-size: 17px;
	width: 300px;
}

.modalButton_green {
	background: #288e44;
	color: #f7f7f7;
	font-size: 13px;
	padding: 5px 20px;
	margin: auto;
}

.modalButton_red {
	background: #ff004e;
	color: #f7f7f7;
	font-size: 15px;
	padding: 5px 20px;
}
.cartQtyArrow {
	display: table;
	color: #000;
	padding: 3px 7px;
	border: 2px solid #000;
	border-radius: 100%;
	font-size: 20px;
	margin: 5px auto;
}
.cartQtyArrow:hover {
	cursor: pointer;
	background: #e92224;
}
/*========================== END CART =============================*/

/*---------------------- DISCOUNT CODES ----------------------------*/
.checkout_discountCodeContainer {
	width: 100%;
	max-width: 410px;
	margin-top: 10px;
}

#checkoutDiscountError {
	border: 1px solid #ff004e;
	margin: 20px auto 0 auto;
	padding: 20px 0px;
	font-size: 15px;
	color: #ff004e;
	width: 95%;
	background: rgba(255, 0, 78, 0.07);
	display: none;
}

#checkout_addDiscountCode {
	text-align: center;
	font-size: 15px;
	padding: 17px 0;
	margin: 5px auto;
	background: #0a1529;
}

#checkout_discount {
}

.discountCodeAdded {
	padding: 15px 0;
	/*width: 49.1%;*/
	display: inline-block;
	margin: 5px auto;
	position: relative;
}

.checkout_removeDiscountCode {
	border: 1px solid #ff004e;
	background: none;
	color: #ff004e;
	font-size: 17px;
	font-weight: bold;
	position: absolute;
	right: 10px;
	top: 7px;
	padding: 5px 10px 7px 10px;
	border-radius: 100%;
}

.checkout_removeDiscountCode:hover {
	cursor: pointer;
	background: #ff004e;
	color: #fff;
}
/*=================== END DISCOUNT CODES ===========================*/

/*----------------------- SECONDARY NAV ----------------------------*/
.menuNavButton img {
	height: 50px;
	width: auto;
}

#thirdNavMobileDiv {
	display: none;
}
.thirdNavMobile {
	width: 100vw;
	margin: 0 auto;
	overflow-x: auto;
}
.thirdNavMobileInner {
	width: 535px;
	margin: 0 auto;
	padding: 0;
}
.thirdNavMobileTab {
	display: inline-block;
	padding: 10px 15px;
	color: #fff;
	font-size: 18px;
	font-style: italic;
	font-weight: 700;
	border: 1px solid #ddd;
}
.thirdNavMobileTab a img {
	width: 50px;
	height: auto;
}
.thirdNavMobileTab:hover {
	background: #eee;
}
.thirdNavMobileScroll {
	display: table;
	height: 10px;
	width: auto;
	margin: 10px auto 20px auto;
	opacity: 0.7;
}

.menuNavButton {
	border-radius: 20px;
	padding: 5px 0;
	width: 100%;
	display: table;
	margin: 2px auto;
}
.menuNavButtonBlue {
	background-color: #e92224;
	border: 3px solid #000;
}
.menuNavButtonBlack {
	background-color: #000;
	border: 3px solid #fff;
	box-shadow: 0px 0px 2px #000;
}

.thirdNav {
	display: block;
	width: 100vw;
	margin: 0 auto;
	overflow-x: auto;
	background: #ffffff;
	/*box-shadow: 0 3px 7px -2px #333;*/
	border-top: 1px solid #ddd;
}

.thirdNavInner {
	display: none;
	margin: 0 auto;
	padding: 0;
	text-align: left;
	margin-left: 250px;
}

.thirdNavTab {
	display: inline-block;
	padding: 10px 15px;
	color: #fff;
	font-size: 18px;
	font-style: italic;
	font-weight: 700;
}

.thirdNavTab:hover {
	background: #eee;
}

.thirdNavTab a img {
	width: 50px;
	height: auto;
}

/*==================== END SECONDARY NAV ===========================*/

/*--------------------------- NEW SEATS PAGE ----------------------*/
.newSeatsPage_details {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	max-width: calc(100% - 300px);
	margin: auto;
	text-align: left;
	position: relative;
}
.newSeatsPage_details_text {
	width: 100%;
	max-width: calc(100% - 250px);
	display: inline-block;
	vertical-align: middle;
}
.seatsPageDetails_title {
	font-size: 15px;
	line-height: 21px;
	font-weight: bold;
	color: #000;
	width: 95%;
	display: table;
	margin: auto;
}
.seatsPageDetails_subtitle {
	font-size: 12px;
	line-height: 17px;
	color: #000;
	width: 95%;
	display: table;
	margin: 2px auto 0 auto;
}
.seatsPageDetails_banner {
	position: absolute;
	top: 50%;
	bottom: 50%;
	transform: translate(0%, -47%);
	right: 0;
	display: inline-block;
	vertical-align: middle;
	width: auto;
	height: 65px;
}

.newSeatsPage_details_mobile {
	display: none;
	width: 100%;
	padding: 10px 0;
	margin: auto;
	background: #000;
	text-align: center;
}
.seatsPageDetailsMobile_title {
	font-size: 15px;
	line-height: 21px;
	font-weight: bold;
	color: #fff;
	width: 95%;
	display: table;
	margin: 5px auto 0 auto;
}
.seatsPageDetailsMobile_subtitle {
	font-size: 12px;
	line-height: 17px;
	color: #fff;
	width: 95%;
	display: table;
	margin: 2px auto 0 auto;
}

.seatsPageFilters {
	display: table;
	width: 100%;
	margin: 0 auto;
	padding: 10px 0px;
	text-align: left;
	background: #eee;
}

.seatsPageFiltersDesktop {
	display: table;
}

.seatsPageFiltersInline span {
	margin-left: 15px;
	font-weight: bold;
	color: #fff;
}
.seatsPageFiltersInline {
	display: inline-block;
	margin: auto;
}
.seatsPageFiltersInline:first-child {
	margin: 0;
}
.seatsPageSelect {
	font-weight: bold;
	min-width: 70px;
	font-size: 12px;
	padding: 10px 15px 10px 7px;
}
.seatsPageFilterSelect {
	width: 125px;
	background: url(../img/selectArrowWhite.svg) right 7px top 13px no-repeat, #707372 linear-gradient(#9b9d9c, #707372, #9b9d9c);
	color: #fff;
	border: 0;
	box-shadow: none;
}
.seatsPageFilterWrap {
	max-width: 95%;
	margin: auto;
}
.seatsPageFilterWrap .seatsPageFiltersInline {
	width: 24%;
}

.topbar_dark {
	display: table;
	width: 100%;
	min-height: 60px;
	padding: 0;
	background: #172624;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
}
.topbar_dark_guarantee {
	position: absolute;
	right: 2.5%;
	top: 50%;
	transform: translate(0%, -50%);
	width: auto;
	height: 60px;
}
.seatsPage_sibets {
	display: table;
	width: 90%;
	max-width: 1000px;
	margin: 5px 2.5%;
	padding: 10px 5px;
	text-align: center;
	border-radius: 20px;
	background: linear-gradient(to right, #172624, #172624, #6e7170);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
}
.seatsPage_sibets_fifth {
	display: inline-block;
	vertical-align: middle;
	width: calc((100% - 150px) / 4);
	padding: 0 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-left: 1px solid #fff;
	color: #fff;
}
.seatsPage_sibets_fifth_1 {
	text-align: left;
	border: 0;
	width: 150px;
}
.seatsPage_sibets_fifth_2 {
	font-family: SolanoGothicMVB-Bd !important;
}
.seatsPage_sibets_fifth_2 .bet_row_1 {
	font-size: 25px;
}
.seatsPage_sibets_fifth_2 .bet_row_2 {
	font-size: 13px;
}
.seatsPage_sibets_fifth_3 .bet_row_1,
.seatsPage_sibets_fifth_4 .bet_row_1,
.seatsPage_sibets_fifth_5 .bet_row_1 {
	font-size: 11px;
}
.seatsPage_sibets_fifth_3 .bet_row_2,
.seatsPage_sibets_fifth_4 .bet_row_2,
.seatsPage_sibets_fifth_5 .bet_row_2 {
	font-size: 13px;
	font-weight: bold;
	margin-top: 5px;
}

.seatsPage_sibets_logo {
	display: inline-block;
	vertical-align: middle;
	width: 95%;
	max-width: 100px;
	height: auto;
}

.seatsPageRight {
	display: table;
	float: right;
	width: 58%;
	padding: 10px 1%;
}
.seatsPageRightImg {
	display: table;
	width: 95%;
	padding: 30px 0;
	margin: auto;
}

.seatsPageLeft {
	display: table;
	float: left;
	width: 38%;
	min-height: calc(100vh - 190px);
	padding: 0;
	box-shadow: 8px 0 5px -7px #888;
}

.seatsPageLeft_res {
	display: block;
	background: #eee;
	max-height: calc(100vh - 250px);
	overflow-x: auto;
	box-shadow: 0 5px 7px -2px #ddd;
}

.seatsPageTable_reservations,
.seatsPageTable_bids {
	width: 100%;
	margin: 0;
	line-height: 20px;
}

.seatsPageTable_reservations tr:hover {
	background: #eee;
	color: #fff;
}

.seatsPageTable_reservations tr th {
	font-size: 13px;
	font-weight: bold;
	color: #000;
	text-align: center;
	padding: 10px 0;
	vertical-align: middle;
	background: #eee;
	border-bottom: 1px solid #ddd;
}

.seats_tab {
	display: table;
	width: 95%;
	margin: 15px auto;
	background: #e2e4e7;
	border: 3px solid #fff;
	border-radius: 20px;
	box-shadow: 3px 3px 7px 3px #ccc;
	padding: 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.seats_tab:hover {
	cursor: pointer;
	background: #fff;
}
.seats_tab_row {
	display: table;
	width: 100%;
	margin: 5px auto;
	text-align: left;
}
.seats_tab_hidden {
	display: none;
	position: relative;
}
.seats_tab_active {
	background: #fff;
}
.seats_tab_active .seats_tab_title {
	color: #767977;
}
.seats_tab_active .seats_tab_hidden {
	display: table;
}
.seats_tab_row_left {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 150px);
	text-align: left;
}
.seats_tab_row_right {
	display: inline-block;
	vertical-align: top;
	width: 150px;
	text-align: right;
}
.seats_tab_title {
	font-weight: 700;
	margin-top: 7px;
	font-size: 17px;
	color: #11284b;
}
.seats_tab_text {
	font-weight: 400;
	font-size: 15px;
	margin-top: 5px;
	color: #767977;
}
.seats_tab_text_small {
	font-size: 13px;
}
.seats_tab_text_strike {
	text-decoration: line-through;
}
.seats_tab_hr {
	display: table;
	width: 100%;
	height: 1px;
	background: #ddd;
	margin: 5px auto;
}
.seats_tab_arrow_up {
	position: absolute;
	bottom: 20px;
	right: 10px;
	width: 20px;
	height: auto;
	-moz-transform: scaleY(-1);
	-o-transform: scaleY(-1);
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
	filter: FlipV;
	-ms-filter: "FlipV";
}

.seatsPageTable_reservations tr th:first-child {
	max-width: 200px;
}
.seatsPageTable_reservations tr th:nth-child(2),
.seatsPageTable_reservations tr td:nth-child(2) {
	/*min-width: 110px;
	max-width: 150px;*/
	width: 190px;
	white-space: pre-line;
}
.seatsPageTable_reservations tr th:nth-child(3) {
	/*max-width: 140px;*/
}
.seatsPageTable_reservations tr td {
	color: #000;
	text-align: center;
	border-bottom: 1px solid #ddd;
	padding: 15px 5px;
	vertical-align: middle;
	font-weight: bold;
}

.row-reservation-item {
	position: relative;
}

.buttonResBidSeats {
	background: #0a1529;
	color: #fff;
	font-size: 17px;
	display: block;
	padding: 13px 0px;
	max-width: 150px;
	margin: auto;
}

.seatsPageBid {
	padding: 20px 0;
	margin: 20px 0;
	border-top: 10px solid #0a1529;
}

#seatsPage_placeBid {
	min-width: 90px;
}

.seatsPageBid_title {
	font-size: 20px;
	line-height: 23px;
	text-align: left;
	color: #000;
	font-weight: bold;
	display: table;
	margin: 0 auto 5px auto;
	width: 95%;
}

.seatsPageTable_bids {
	border-top: 1px solid #ddd;
}

.seatsPageTable_bids tr th {
	font-size: 19px;
	font-weight: 700;
	color: #000;
	text-align: center;
	padding: 15px 0 5px 0;
	vertical-align: middle;
}

.seatsPageTable_bids tr td {
	color: #aaa;
	text-align: center;
	padding: 0 5px;
	vertical-align: middle;
}

#event-map {
	width: 100%;
	height: calc(100vh - 250px);
}

/*======================== END NEW SEATS PAGE =====================*/

/*=================== GIFT CARD FORM PAGE ======================== */
.gift-card-text-wrap {
	margin: 0 auto;
	max-width: 800px;
	line-height: 1.4;
}
.gift-card-text-separator {
	margin: 20px auto 5px auto;
	width: 200px;
	border-bottom: 5px solid #1871b7;
}
.gift-card-form-wrap {
	width: 95%;
	max-width: 410px;
	margin: 0 auto;
}
.gift-card-form-title {
	margin: 20px auto 10px auto;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
}
.gift-card-form-btn {
	border: 3px solid #000;
	padding: 10px 40px;
	border-radius: 20px;
	margin-top: 20px;
	font-size: 25px;
}

/*=================== END GIFT CARD FORM PAGE ==================== */

/*======================== START GOLDEN TICKET PAGE ===============*/
.gt-alert {
	color: #000000;
	background-color: #edc554;
	border-color: #fffacc;
}

.golden-ticket-form-wrap {
	max-width: 410px;
	margin: 0 auto;
	width: 95%;
}
.golden-ticket-form-wrap .gt-city-wrap {
	margin: 5px 0;
}
.gt-city-wrap.select2-container--default .select2-selection--single {
	border: 1px solid #ddd;
	border-radius: 11px;
	height: 52px;
}
.gt-city-wrap.select2-container .select2-selection--single .select2-selection__rendered {
	padding-top: 15px;
	line-height: 20px;
}
.gt-city-wrap.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 13px;
}
.select2-container--open .select2-dropdown--below {
	border: 1px solid #ddd;
}
.select2-results .select2-results__option {
	padding: 0;
}

.gt-city-name {
	border-right: 1px solid #ccc;
	padding: 8px;
}
.gt-team-names {
	padding: 2px 8px;
}
.gt-team-names p {
	margin: 6px 0;
}
#goldenTicketVideo {
	display: table;
	width: 100%;
	max-width: 700px;
	margin: 20px auto;
}
/*======================== END GOLDEN TICKET PAGE =================*/

/*======================== START DRAFTKINGS ===============*/
.sectionInner_draftkings {
	padding: 20px 0;
}
.draftkings_tab {
	display: inline-block;
	width: 250px;
	max-width: calc(50% - 20px);
	margin: 5px 10px;
}

.draftkings_tab_img {
	width: 100%;
	height: auto;
}

.section_draftkings_bookfeed {
	padding: 50px 0;
}

.sectionInner_draftkings_bookfeed {
	max-width: 700px !important;
	height: 500px;
	overflow: auto;
	margin: 0 auto;
	text-align: center;
}

.draftkings_sb_tab_container {
	display: table;
	width: 100%;
	margin: 20px auto 0 auto;
}
.draftkings_sb_tab {
	display: table-cell;
	vertical-align: middle;
	width: calc(100% / 7);
	padding: 10px;
	margin: auto;
	text-align: center;
	border-right: 1px solid #e3e2e1;
	border-bottom: 1px solid #e3e2e1;
	background: #f3f2f1;
	color: #000;
}
.draftkings_sb_tab_blank {
	display: none;
	color: #fff;
	background: #fff;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
}
.draftkings_sb_tab span {
	font-weight: bold;
	font-size: 20px;
}
.draftkings_sb_tab_active,
.draftkings_sb_tab:hover {
	cursor: pointer;
	background: #000;
	color: #fff;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
}
.draftkings_sb_tab_blank:hover {
	background: #fff;
	color: #fff;
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
}
/*.draftkings_sb_tab:last-child {
	border-right: 0;
}*/
.draftkings_sb_tab img {
	display: table;
	height: 50px;
	width: auto;
	margin: auto;
}
.draftkings_sb_tab_content_container {
	display: block;
	width: 100%;
	padding: 0 0 50px 0;
}
.draftkings_sb_tab_content_container_inner {
	display: block;
	width: 90%;
	max-width: 1000px;
	margin: 10px auto;
	text-align: left;
}
.draftkings_sb_tab_content {
	display: none;
}
.draftkings_sb_tab_content_active {
	display: block;
	background: #fff;
}

.draftkings_sb_tab_title {
	display: table;
	width: 100%;
	padding: 20px 0 10px 0;
	border-bottom: 1px solid #e3e2e1;
}
.draftkings_sb_tab_title_left {
	display: table;
	height: 150px;
	width: auto;
	float: left;
}
.draftkings_sb_tab_title_right {
	display: table;
	width: calc(100% - 250px);
	margin-left: 20px;
	text-align: left;
	float: left;
	padding-top: 10px;
	line-height: 30px;
}
.draftkings_sb_tab_title_right_1 {
	font-size: 25px;
	line-height: 30px;
	font-weight: bold;
	color: #000;
}
.draftkings_sb_tab_title_right_2 {
	font-size: 45px;
	line-height: 45px;
	font-weight: bold;
	color: #000;
}
.draftkings_sb_tab_title_right_3 {
	font-size: 17px;
	margin-top: 10px;
}
.draftkings_sb_tab_content_container_inner h1 {
	font-size: 25px;
	font-weight: 700;
	line-height: 45px;
	color: #000;
	border-bottom: 1px solid #e3e2e1;
	margin: 20px 0;
}
.draftkings_sb_tab_content_container_inner h2 {
	font-size: 20px;
	font-weight: bold;
	line-height: 35px;
	color: #000;
	margin-top: 30px;
}
.draftkings_sb_tab_content_container_inner p {
	font-size: 17px;
	line-height: 21px;
	margin: 10px 0;
}
/*====================== END DRAFTKINGS ==================== */
.section_top_bar {
	text-align: center;
	height: 35px;
}

/*------------------------------------- MEDIA QUERIES -----------------------------*/
@media only screen and (max-width: 1000px) {
	.socialPlugin {
		margin: auto;
	}
	.marquee-container {
		/*left: 180px;
		right: 120px;*/
	}

	.ticker-item {
		font-size: 12px;
	}
}

@media only screen and (max-width: 960px) {
	.slick-slide {
		width: 100vw;
		border: none;
	}
}

@media only screen and (max-width: 900px) {
	.headerH1 {
		font-size: 40px;
		line-height: 50px;
	}

	.headerH2 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 23px;
	}

	.generalPagesButton {
		padding: 15px 0px;
	}

	.howItWorksHalf_1 {
		width: 100%;
	}

	.howItWorksHalf_2 {
		width: 100%;
		margin-top: 30px;
	}

	.buttonPricesSelectSeats,
	.buttonReservationBidSellRow {
		font-size: 17px;
		padding: 15px 0px;
	}
}

@media only screen and (max-width: 800px) {
	.pricesContainer {
		width: 100%;
	}
	.rightSidebar_banner {
		width: 100%;
	}

	.sportsTabText {
		font-size: 17px;
	}
	.topHeaderSaveTextDesktop {
		display: none;
	}
	.topHeaderSaveTextMobile {
		display: block;
		font-size: 13px;
	}

	.seatsPageFilters {
		padding: 3px 0;
	}
	.newSeatsPage_details {
		display: none !important;
	}
	.newSeatsPage_details_mobile {
		display: table;
		padding: 0 0 5px 0;
	}
	.seatsPageRight {
		width: 100%;
		float: none;
	}
	.seatsPageLeft {
		width: 100%;
		float: none;
		min-height: initial;
	}
	.seatsPageLeft_res {
		box-shadow: 0px 5px 5px #ddd, 0px -5px 5px #ddd;
	}
	.seatsPageRightImg {
		width: auto;
		max-height: 50vh;
		max-width: 95%;
		padding: 0;
	}
	.seatsPageTable_bids tr td {
		padding: 0 2px;
	}
	.seatsPageFilterSelect {
		max-width: 85px;
	}
	.seatsPageTable_bids {
		width: 99vw;
	}
	.seatsPageTable_reservations {
		width: calc(100vw - 16px);
	}
	.seatsPageTable_bids tr th {
		font-size: 15px;
	}
	.seatsPageTable_reservations tr td {
		font-size: 15px;
	}
	.seatsPageTable_reservations tr th:first-child {
		min-width: initial;
		/*max-width: 30px*/
	}
	.seatsPageTable_reservations tr th:nth-child(2),
	.seatsPageTable_reservations tr td:nth-child(2) {
		min-width: initial;
		width: initial;
		max-width: 130px;
	}
	.seatsPageTable_reservations tr th:nth-child(3) {
		min-width: initial;
		/*max-width: 70px;*/
	}
	.seatsPageTable_reservations tr th:nth-child(4) {
		min-width: initial;
		/*max-width: 60px;*/
	}
	.buttonResBidSeats {
		max-width: 100px;
		font-size: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.my-tab-button.first-row {
		border-bottom: none;
	}
}

@media only screen and (max-width: 750px) {
	.cartElementQty {
		padding-left: 10px;
		padding-right: 25px;
		padding-top: 0px;
	}
	.cartTotalPrice {
		float: none;
		margin: auto;
	}
}

@media only screen and (max-width: 700px) {
	.pricesTab {
		display: inline-block;
		vertical-align: top;
		width: 45%;
		margin: 7px 10px;
	}

	.pricesTabTeamName {
		display: table;
		width: 100%;
		float: none;
		text-align: center;
		padding: 10px 0px 10px 0px;
		text-indent: 0;
	}

	.pricesTabButtonContainer {
		display: table;
		width: 100%;
		float: none;
		text-align: center;
		margin: 20px auto 20px auto;
	}

	.pricesTabOfferContainer {
		text-align: center;
		width: 100%;
		display: table;
		margin: 20px auto;
	}
	.pricesTabOfferContainer_border {
		display: none;
	}
	.pricesTabOffer_title,
	.pricesTabOffer_subtitle,
	.pricesTabOffer_text {
		text-align: center;
	}

	.col-event-desc {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-row-end: 1;
		grid-column-end: 4;
	}

	.col-event-reserve {
		grid-row-start: 2;
		grid-column-start: 1;
		grid-row-end: 2;
		grid-column-end: 2;
	}
	.col-event-bet {
		grid-row-start: 2;
		grid-column-start: 2;
		grid-row-end: 2;
		grid-column-end: 4;
	}
	.event-row .reserve-btn {
		padding: 10px;
	}
	.event-row .bet-btn {
		padding: 10px;
	}

	.reservationRow {
		display: inline-block;
		vertical-align: top;
		width: 45%;
		margin: 20px 10px;
	}

	.reservationRowThird_1 {
		display: table;
		width: 100%;
		float: none;
		text-align: center;
		padding: 20px 0px 10px 0px;
		text-indent: 0;
	}

	.reservationRowThird_2 {
		display: table;
		width: 100%;
		float: none;
		text-align: center;
		margin: 10px auto;
	}

	.reservationRowThird_3 {
		display: table;
		/*display: none;*/

		width: 100%;
		float: none;
		text-align: center;
		margin: 20px auto 20px auto;
	}

	.priceTabOurSmall {
		display: block;
	}

	.checkout_left,
	.checkout_right {
		float: none;
		width: 100%;
	}
}

@media only screen and (max-width: 670px) {
	.sectionHeaderHomepage {
		height: 150px;
		background-position: center right;
	}
	.homeSubSlider_category .homeSubSlider_tab {
		width: 100px;
	}
	.homeSubSlider_tab_title {
		font-size: 15px;
	}
	.homeSubSlider {
		width: 100%;
	}
	.homeSubSlider_category {
		width: 1050px;
	}
	.homeSubSlider_category .homeSubSlider_tab_img {
		max-width: 130px;
	}
	.homeSubSlider_3 .homeSubSlider_tab:last-child {
		display: none;
	}
	.homeSubSlider_3 .homeSubSlider_tab {
		width: 33%;
		padding: 0 5px;
	}
	.homeSubSlider_tab_text {
		font-size: 15px;
		margin-top: 10px;
	}
	.newsletterText span:first-child,
	.newsletterText span:last-child {
		display: table;
		margin: auto;
	}
	.newsletterText span:nth-child(2) {
		display: none;
	}
	.sectionMobileApp_left,
	.sectionMobileApp_right {
		display: table;
		width: 95%;
		float: none;
		margin: auto;
	}
	.sectionMobileApp_left img {
		width: auto;
		height: 300px;
		margin-bottom: 20px;
	}
	.sectionMobileApp_logo,
	.sectionMobileApp_venmo {
		margin: 0 5px;
		height: 100px;
	}
	.sectionMobileApp_button {
		display: inline-block;
		width: calc(50% - 10px);
		margin: 5px;
	}
	.sectionMobileApp_button img {
		width: 100%;
		height: auto;
	}
	.inputNewsletter {
		font-size: 12px;
	}

	.sportsTab {
		border: 1px solid #ddd;
		display: table;
		width: 400px;
		max-width: 95%;
		margin: 10px auto;
	}

	.sportsContainer {
		border: none;
		width: 90%;
		margin: 20px auto 30px auto;
	}

	.sportsTabIcon {
		display: inline-block;
		vertical-align: top;
	}

	.sportsTabText {
		display: block;
		vertical-align: top;
		margin-left: 10px;
	}

	.headerH1 {
		font-size: 30px;
		line-height: 40px;
		text-shadow: 1px 2px #000000;
		margin: 0 auto 20px auto;
	}

	.headerH1Br {
		display: initial;
	}

	.teamTab {
		max-width: 30%;
	}

	.seatsContainer {
		margin: 30px auto 20px auto;
	}

	.seatsStadiumContainer {
		display: block;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
	}

	.seatsInfoContainer {
		display: block;
		width: 90%;
		max-width: 90%;
		margin: 20px auto 0px auto;
	}

	.testimonialTab {
		padding: 0;
	}

	.header_hero_text {
		font-size: 19px;
	}

	.seatsPageFilterWrap {
		max-width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.sectionInner_draftkings_bookfeed {
		width: 100%;
	}
	.section_draftking_bookfeed {
		padding: 10px 0;
	}
	.draftkings_sb_tab_blank {
		display: block;
	}
	.draftkings_sb_tab {
		display: block;
		float: left;
		width: 25%;
		min-height: 70px;
	}
	.draftkings_sb_tab span {
		display: block;
		margin-top: 15px;
		font-size: 15px;
	}
	.draftkings_sb_tab_title_left {
		height: 100px;
	}
	.draftkings_sb_tab_title_right_1 {
		font-size: 20px;
	}
}

@media only screen and (max-width: 500px) {
	.menuTop {
		width: 100%;
	}
	.mobileMenuAccount {
		right: 40px;
	}
	.mobileMenuCart {
		right: 0;
	}
	.mobileMenuLocation {
		display: table;
		margin-left: 5px;
	}
	.sliderBkg_1 {
		background-position: -270px center;
	}
	.sliderBkg_3,
	.sliderBkg_4 {
		background-position: center right;
	}

	.sectionHeaderLoginRegister {
		height: auto;
		min-height: initial;
	}

	#headerButton {
		padding: 20px 30px;
	}

	.newsletterText {
		margin-bottom: 15px;
	}

	.sportsTab {
		width: 100%;
		max-width: 95%;
	}

	.sectionPadding {
		padding: 30px 0px;
	}

	.teamTab {
		max-width: 40%;
	}

	.pricesTab {
		display: inline-block;
		vertical-align: top;
		width: 90%;
		margin: 10px auto;
	}

	.reservationRow {
		display: inline-block;
		vertical-align: top;
		width: 90%;
		margin: 20px auto;
	}

	.checkoutInputFull {
		width: 100%;
		max-width: 100%;
	}

	.formInputFull {
		width: 100%;
		max-width: 100%;
	}

	.buttonPay {
		margin-top: 20px;
	}

	.my-tab-button {
		font-size: 13px !important;
	}

	.contentTabInnerHalf {
		width: 95%;
	}

	.headerOfficialPartner {
		margin: 0px auto 0px auto;
		max-width: 150px;
	}

	.headerH1 {
		font-size: 25px;
		line-height: 35px;
		text-shadow: 1px 2px #000000;
		margin: 0 auto 25px auto;
	}

	.headerVideoLink {
		margin: 30px auto 0 auto;
		display: table;
		font-size: 15px;
	}

	.my-account-row-column {
		padding: 10px 0 !important;
		text-align: center;
		width: 100%;
		max-width: 90%;
		flex: initial;
	}
	.reservationRowThird_3 {
		margin: 0px auto 10px auto;
	}

	.thirdNav {
		display: none;
	}

	.thirdNavTab {
		padding: 10px 5px;
	}
	.thirdNavInner {
		width: 390px;
	}
	.sectionSportsMobile {
		display: table !important;
	}
	.testimonialContainer_img,
	.guaranteeContainer_img {
		margin: 50px auto;
	}

	.sectionLeftTitle {
		font-size: 17px;
		margin: 10px 0;
	}
	.sectionLeftSubtitle {
		font-size: 15px;
		margin: 0;
	}
	.slick-prev {
		left: 5px;
		padding: 0;
	}
	.slick-next {
		right: 5px;
		padding: 0;
	}

	.seatsPageTable_reservations tr th:nth-child(2),
	.seatsPageTable_reservations tr td:nth-child(2) {
		white-space: initial;
	}
	.sectionInner_draftkings {
		padding: 10px 0;
	}
	.draftkings_tab {
		margin: 5px 5px;
	}
}

@media only screen and (max-width: 480px) {
	.marquee-container {
		/*top: 75px;
		left: 10px;
		right: 10px;*/
	}
	.ticker-event {
		font-size: 12px;
	}
	.col-event-reserve {
		grid-row-start: 2;
		grid-column-start: 1;
		grid-row-end: 2;
		grid-column-end: 4;
	}
	.col-event-bet {
		grid-row-start: 3;
		grid-column-start: 1;
		grid-row-end: 3;
		grid-column-end: 4;
	}
}

a[disabled="disabled"] {
	pointer-events: none;
}

/*==================== END MEDIA QUERIES =====================*/
