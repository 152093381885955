/*
 * Limited version of Bootstrap Stylesheet for use with Seatics Maps.
 * Compiled from version 3.3.4 (node_modules/bootstrap)
 */
.bootstrap-scope html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
.bootstrap-scope body {
	margin: 0;
}
.bootstrap-scope article,
.bootstrap-scope aside,
.bootstrap-scope details,
.bootstrap-scope figcaption,
.bootstrap-scope figure,
.bootstrap-scope footer,
.bootstrap-scope header,
.bootstrap-scope hgroup,
.bootstrap-scope main,
.bootstrap-scope menu,
.bootstrap-scope nav,
.bootstrap-scope section,
.bootstrap-scope summary {
	display: block;
}
.bootstrap-scope audio,
.bootstrap-scope canvas,
.bootstrap-scope progress,
.bootstrap-scope video {
	display: inline-block;
	vertical-align: baseline;
}
.bootstrap-scope audio:not([controls]) {
	display: none;
	height: 0;
}
.bootstrap-scope [hidden],
.bootstrap-scope template {
	display: none;
}
.bootstrap-scope a {
	background-color: transparent;
}
.bootstrap-scope a:active,
.bootstrap-scope a:hover {
	outline: 0;
}
.bootstrap-scope abbr[title] {
	border-bottom: 1px dotted;
}
.bootstrap-scope b,
.bootstrap-scope strong {
	font-weight: bold;
}
.bootstrap-scope dfn {
	font-style: italic;
}
.bootstrap-scope h1 {
	font-size: 2em;
	margin: 0.67em 0;
}
.bootstrap-scope mark {
	background: #ff0;
	color: #000;
}
.bootstrap-scope small {
	font-size: 80%;
}
.bootstrap-scope sub,
.bootstrap-scope sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
.bootstrap-scope sup {
	top: -0.5em;
}
.bootstrap-scope sub {
	bottom: -0.25em;
}
.bootstrap-scope img {
	border: 0;
}
.bootstrap-scope svg:not(:root) {
	overflow: hidden;
}
.bootstrap-scope figure {
	margin: 1em 40px;
}
.bootstrap-scope hr {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
}
.bootstrap-scope pre {
	overflow: auto;
}
.bootstrap-scope code,
.bootstrap-scope kbd,
.bootstrap-scope pre,
.bootstrap-scope samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
.bootstrap-scope button,
.bootstrap-scope input,
.bootstrap-scope optgroup,
.bootstrap-scope select,
.bootstrap-scope textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}
.bootstrap-scope button {
	overflow: visible;
}
.bootstrap-scope button,
.bootstrap-scope select {
	text-transform: none;
}
.bootstrap-scope button,
.bootstrap-scope html input[type="button"],
.bootstrap-scope input[type="reset"],
.bootstrap-scope input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
.bootstrap-scope button[disabled],
.bootstrap-scope html input[disabled] {
	cursor: default;
}
.bootstrap-scope button::-moz-focus-inner,
.bootstrap-scope input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
.bootstrap-scope input {
	line-height: normal;
}
.bootstrap-scope input[type="checkbox"],
.bootstrap-scope input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
.bootstrap-scope input[type="number"]::-webkit-inner-spin-button,
.bootstrap-scope input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
.bootstrap-scope input[type="search"] {
	-webkit-appearance: textfield;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
.bootstrap-scope input[type="search"]::-webkit-search-cancel-button,
.bootstrap-scope input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
.bootstrap-scope fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}
.bootstrap-scope legend {
	border: 0;
	padding: 0;
}
.bootstrap-scope textarea {
	overflow: auto;
}
.bootstrap-scope optgroup {
	font-weight: bold;
}
.bootstrap-scope table {
	border-collapse: collapse;
	border-spacing: 0;
}
.bootstrap-scope td,
.bootstrap-scope th {
	padding: 0;
}
@media print {
	.bootstrap-scope *,
	.bootstrap-scope *:before,
	.bootstrap-scope *:after {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}
	.bootstrap-scope a,
	.bootstrap-scope a:visited {
		text-decoration: underline;
	}
	.bootstrap-scope a[href]:after {
		content: " (" attr(href) ")";
	}
	.bootstrap-scope abbr[title]:after {
		content: " (" attr(title) ")";
	}
	.bootstrap-scope a[href^="#"]:after,
	.bootstrap-scope a[href^="javascript:"]:after {
		content: "";
	}
	.bootstrap-scope pre,
	.bootstrap-scope blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	.bootstrap-scope thead {
		display: table-header-group;
	}
	.bootstrap-scope tr,
	.bootstrap-scope img {
		page-break-inside: avoid;
	}
	.bootstrap-scope img {
		max-width: 100% !important;
	}
	.bootstrap-scope p,
	.bootstrap-scope h2,
	.bootstrap-scope h3 {
		orphans: 3;
		widows: 3;
	}
	.bootstrap-scope h2,
	.bootstrap-scope h3 {
		page-break-after: avoid;
	}
	.bootstrap-scope select {
		background: #fff !important;
	}
	.bootstrap-scope .navbar {
		display: none;
	}
	.bootstrap-scope .btn > .caret,
	.bootstrap-scope .dropup > .btn > .caret {
		border-top-color: #000 !important;
	}
	.bootstrap-scope .label {
		border: 1px solid #000;
	}
	.bootstrap-scope .table {
		border-collapse: collapse !important;
	}
	.bootstrap-scope .table td,
	.bootstrap-scope .table th {
		background-color: #fff !important;
	}
	.bootstrap-scope .table-bordered th,
	.bootstrap-scope .table-bordered td {
		border: 1px solid #ddd !important;
	}
}
@font-face {
	font-family: "Glyphicons Halflings";
	src: url("../../../../node_modules/bootstrap/dist/fonts/glyphicons-halflings-regular.eot");
	src: url("../../../../node_modules/bootstrap/dist/fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../../../../node_modules/bootstrap/dist/fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../../../../node_modules/bootstrap/dist/fonts/glyphicons-halflings-regular.woff") format("woff"), url("../../../../node_modules/bootstrap/dist/fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../../../../node_modules/bootstrap/dist/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}
.bootstrap-scope .glyphicon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: "Glyphicons Halflings";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.bootstrap-scope .glyphicon-asterisk:before {
	content: "\2a";
}
.bootstrap-scope .glyphicon-plus:before {
	content: "\2b";
}
.bootstrap-scope .glyphicon-euro:before,
.bootstrap-scope .glyphicon-eur:before {
	content: "\20ac";
}
.bootstrap-scope .glyphicon-minus:before {
	content: "\2212";
}
.bootstrap-scope .glyphicon-cloud:before {
	content: "\2601";
}
.bootstrap-scope .glyphicon-envelope:before {
	content: "\2709";
}
.bootstrap-scope .glyphicon-pencil:before {
	content: "\270f";
}
.bootstrap-scope .glyphicon-glass:before {
	content: "\e001";
}
.bootstrap-scope .glyphicon-music:before {
	content: "\e002";
}
.bootstrap-scope .glyphicon-search:before {
	content: "\e003";
}
.bootstrap-scope .glyphicon-heart:before {
	content: "\e005";
}
.bootstrap-scope .glyphicon-star:before {
	content: "\e006";
}
.bootstrap-scope .glyphicon-star-empty:before {
	content: "\e007";
}
.bootstrap-scope .glyphicon-user:before {
	content: "\e008";
}
.bootstrap-scope .glyphicon-film:before {
	content: "\e009";
}
.bootstrap-scope .glyphicon-th-large:before {
	content: "\e010";
}
.bootstrap-scope .glyphicon-th:before {
	content: "\e011";
}
.bootstrap-scope .glyphicon-th-list:before {
	content: "\e012";
}
.bootstrap-scope .glyphicon-ok:before {
	content: "\e013";
}
.bootstrap-scope .glyphicon-remove:before {
	content: "\e014";
}
.bootstrap-scope .glyphicon-zoom-in:before {
	content: "\e015";
}
.bootstrap-scope .glyphicon-zoom-out:before {
	content: "\e016";
}
.bootstrap-scope .glyphicon-off:before {
	content: "\e017";
}
.bootstrap-scope .glyphicon-signal:before {
	content: "\e018";
}
.bootstrap-scope .glyphicon-cog:before {
	content: "\e019";
}
.bootstrap-scope .glyphicon-trash:before {
	content: "\e020";
}
.bootstrap-scope .glyphicon-home:before {
	content: "\e021";
}
.bootstrap-scope .glyphicon-file:before {
	content: "\e022";
}
.bootstrap-scope .glyphicon-time:before {
	content: "\e023";
}
.bootstrap-scope .glyphicon-road:before {
	content: "\e024";
}
.bootstrap-scope .glyphicon-download-alt:before {
	content: "\e025";
}
.bootstrap-scope .glyphicon-download:before {
	content: "\e026";
}
.bootstrap-scope .glyphicon-upload:before {
	content: "\e027";
}
.bootstrap-scope .glyphicon-inbox:before {
	content: "\e028";
}
.bootstrap-scope .glyphicon-play-circle:before {
	content: "\e029";
}
.bootstrap-scope .glyphicon-repeat:before {
	content: "\e030";
}
.bootstrap-scope .glyphicon-refresh:before {
	content: "\e031";
}
.bootstrap-scope .glyphicon-list-alt:before {
	content: "\e032";
}
.bootstrap-scope .glyphicon-lock:before {
	content: "\e033";
}
.bootstrap-scope .glyphicon-flag:before {
	content: "\e034";
}
.bootstrap-scope .glyphicon-headphones:before {
	content: "\e035";
}
.bootstrap-scope .glyphicon-volume-off:before {
	content: "\e036";
}
.bootstrap-scope .glyphicon-volume-down:before {
	content: "\e037";
}
.bootstrap-scope .glyphicon-volume-up:before {
	content: "\e038";
}
.bootstrap-scope .glyphicon-qrcode:before {
	content: "\e039";
}
.bootstrap-scope .glyphicon-barcode:before {
	content: "\e040";
}
.bootstrap-scope .glyphicon-tag:before {
	content: "\e041";
}
.bootstrap-scope .glyphicon-tags:before {
	content: "\e042";
}
.bootstrap-scope .glyphicon-book:before {
	content: "\e043";
}
.bootstrap-scope .glyphicon-bookmark:before {
	content: "\e044";
}
.bootstrap-scope .glyphicon-print:before {
	content: "\e045";
}
.bootstrap-scope .glyphicon-camera:before {
	content: "\e046";
}
.bootstrap-scope .glyphicon-font:before {
	content: "\e047";
}
.bootstrap-scope .glyphicon-bold:before {
	content: "\e048";
}
.bootstrap-scope .glyphicon-italic:before {
	content: "\e049";
}
.bootstrap-scope .glyphicon-text-height:before {
	content: "\e050";
}
.bootstrap-scope .glyphicon-text-width:before {
	content: "\e051";
}
.bootstrap-scope .glyphicon-align-left:before {
	content: "\e052";
}
.bootstrap-scope .glyphicon-align-center:before {
	content: "\e053";
}
.bootstrap-scope .glyphicon-align-right:before {
	content: "\e054";
}
.bootstrap-scope .glyphicon-align-justify:before {
	content: "\e055";
}
.bootstrap-scope .glyphicon-list:before {
	content: "\e056";
}
.bootstrap-scope .glyphicon-indent-left:before {
	content: "\e057";
}
.bootstrap-scope .glyphicon-indent-right:before {
	content: "\e058";
}
.bootstrap-scope .glyphicon-facetime-video:before {
	content: "\e059";
}
.bootstrap-scope .glyphicon-picture:before {
	content: "\e060";
}
.bootstrap-scope .glyphicon-map-marker:before {
	content: "\e062";
}
.bootstrap-scope .glyphicon-adjust:before {
	content: "\e063";
}
.bootstrap-scope .glyphicon-tint:before {
	content: "\e064";
}
.bootstrap-scope .glyphicon-edit:before {
	content: "\e065";
}
.bootstrap-scope .glyphicon-share:before {
	content: "\e066";
}
.bootstrap-scope .glyphicon-check:before {
	content: "\e067";
}
.bootstrap-scope .glyphicon-move:before {
	content: "\e068";
}
.bootstrap-scope .glyphicon-step-backward:before {
	content: "\e069";
}
.bootstrap-scope .glyphicon-fast-backward:before {
	content: "\e070";
}
.bootstrap-scope .glyphicon-backward:before {
	content: "\e071";
}
.bootstrap-scope .glyphicon-play:before {
	content: "\e072";
}
.bootstrap-scope .glyphicon-pause:before {
	content: "\e073";
}
.bootstrap-scope .glyphicon-stop:before {
	content: "\e074";
}
.bootstrap-scope .glyphicon-forward:before {
	content: "\e075";
}
.bootstrap-scope .glyphicon-fast-forward:before {
	content: "\e076";
}
.bootstrap-scope .glyphicon-step-forward:before {
	content: "\e077";
}
.bootstrap-scope .glyphicon-eject:before {
	content: "\e078";
}
.bootstrap-scope .glyphicon-chevron-left:before {
	content: "\e079";
}
.bootstrap-scope .glyphicon-chevron-right:before {
	content: "\e080";
}
.bootstrap-scope .glyphicon-plus-sign:before {
	content: "\e081";
}
.bootstrap-scope .glyphicon-minus-sign:before {
	content: "\e082";
}
.bootstrap-scope .glyphicon-remove-sign:before {
	content: "\e083";
}
.bootstrap-scope .glyphicon-ok-sign:before {
	content: "\e084";
}
.bootstrap-scope .glyphicon-question-sign:before {
	content: "\e085";
}
.bootstrap-scope .glyphicon-info-sign:before {
	content: "\e086";
}
.bootstrap-scope .glyphicon-screenshot:before {
	content: "\e087";
}
.bootstrap-scope .glyphicon-remove-circle:before {
	content: "\e088";
}
.bootstrap-scope .glyphicon-ok-circle:before {
	content: "\e089";
}
.bootstrap-scope .glyphicon-ban-circle:before {
	content: "\e090";
}
.bootstrap-scope .glyphicon-arrow-left:before {
	content: "\e091";
}
.bootstrap-scope .glyphicon-arrow-right:before {
	content: "\e092";
}
.bootstrap-scope .glyphicon-arrow-up:before {
	content: "\e093";
}
.bootstrap-scope .glyphicon-arrow-down:before {
	content: "\e094";
}
.bootstrap-scope .glyphicon-share-alt:before {
	content: "\e095";
}
.bootstrap-scope .glyphicon-resize-full:before {
	content: "\e096";
}
.bootstrap-scope .glyphicon-resize-small:before {
	content: "\e097";
}
.bootstrap-scope .glyphicon-exclamation-sign:before {
	content: "\e101";
}
.bootstrap-scope .glyphicon-gift:before {
	content: "\e102";
}
.bootstrap-scope .glyphicon-leaf:before {
	content: "\e103";
}
.bootstrap-scope .glyphicon-fire:before {
	content: "\e104";
}
.bootstrap-scope .glyphicon-eye-open:before {
	content: "\e105";
}
.bootstrap-scope .glyphicon-eye-close:before {
	content: "\e106";
}
.bootstrap-scope .glyphicon-warning-sign:before {
	content: "\e107";
}
.bootstrap-scope .glyphicon-plane:before {
	content: "\e108";
}
.bootstrap-scope .glyphicon-calendar:before {
	content: "\e109";
}
.bootstrap-scope .glyphicon-random:before {
	content: "\e110";
}
.bootstrap-scope .glyphicon-comment:before {
	content: "\e111";
}
.bootstrap-scope .glyphicon-magnet:before {
	content: "\e112";
}
.bootstrap-scope .glyphicon-chevron-up:before {
	content: "\e113";
}
.bootstrap-scope .glyphicon-chevron-down:before {
	content: "\e114";
}
.bootstrap-scope .glyphicon-retweet:before {
	content: "\e115";
}
.bootstrap-scope .glyphicon-shopping-cart:before {
	content: "\e116";
}
.bootstrap-scope .glyphicon-folder-close:before {
	content: "\e117";
}
.bootstrap-scope .glyphicon-folder-open:before {
	content: "\e118";
}
.bootstrap-scope .glyphicon-resize-vertical:before {
	content: "\e119";
}
.bootstrap-scope .glyphicon-resize-horizontal:before {
	content: "\e120";
}
.bootstrap-scope .glyphicon-hdd:before {
	content: "\e121";
}
.bootstrap-scope .glyphicon-bullhorn:before {
	content: "\e122";
}
.bootstrap-scope .glyphicon-bell:before {
	content: "\e123";
}
.bootstrap-scope .glyphicon-certificate:before {
	content: "\e124";
}
.bootstrap-scope .glyphicon-thumbs-up:before {
	content: "\e125";
}
.bootstrap-scope .glyphicon-thumbs-down:before {
	content: "\e126";
}
.bootstrap-scope .glyphicon-hand-right:before {
	content: "\e127";
}
.bootstrap-scope .glyphicon-hand-left:before {
	content: "\e128";
}
.bootstrap-scope .glyphicon-hand-up:before {
	content: "\e129";
}
.bootstrap-scope .glyphicon-hand-down:before {
	content: "\e130";
}
.bootstrap-scope .glyphicon-circle-arrow-right:before {
	content: "\e131";
}
.bootstrap-scope .glyphicon-circle-arrow-left:before {
	content: "\e132";
}
.bootstrap-scope .glyphicon-circle-arrow-up:before {
	content: "\e133";
}
.bootstrap-scope .glyphicon-circle-arrow-down:before {
	content: "\e134";
}
.bootstrap-scope .glyphicon-globe:before {
	content: "\e135";
}
.bootstrap-scope .glyphicon-wrench:before {
	content: "\e136";
}
.bootstrap-scope .glyphicon-tasks:before {
	content: "\e137";
}
.bootstrap-scope .glyphicon-filter:before {
	content: "\e138";
}
.bootstrap-scope .glyphicon-briefcase:before {
	content: "\e139";
}
.bootstrap-scope .glyphicon-fullscreen:before {
	content: "\e140";
}
.bootstrap-scope .glyphicon-dashboard:before {
	content: "\e141";
}
.bootstrap-scope .glyphicon-paperclip:before {
	content: "\e142";
}
.bootstrap-scope .glyphicon-heart-empty:before {
	content: "\e143";
}
.bootstrap-scope .glyphicon-link:before {
	content: "\e144";
}
.bootstrap-scope .glyphicon-phone:before {
	content: "\e145";
}
.bootstrap-scope .glyphicon-pushpin:before {
	content: "\e146";
}
.bootstrap-scope .glyphicon-usd:before {
	content: "\e148";
}
.bootstrap-scope .glyphicon-gbp:before {
	content: "\e149";
}
.bootstrap-scope .glyphicon-sort:before {
	content: "\e150";
}
.bootstrap-scope .glyphicon-sort-by-alphabet:before {
	content: "\e151";
}
.bootstrap-scope .glyphicon-sort-by-alphabet-alt:before {
	content: "\e152";
}
.bootstrap-scope .glyphicon-sort-by-order:before {
	content: "\e153";
}
.bootstrap-scope .glyphicon-sort-by-order-alt:before {
	content: "\e154";
}
.bootstrap-scope .glyphicon-sort-by-attributes:before {
	content: "\e155";
}
.bootstrap-scope .glyphicon-sort-by-attributes-alt:before {
	content: "\e156";
}
.bootstrap-scope .glyphicon-unchecked:before {
	content: "\e157";
}
.bootstrap-scope .glyphicon-expand:before {
	content: "\e158";
}
.bootstrap-scope .glyphicon-collapse-down:before {
	content: "\e159";
}
.bootstrap-scope .glyphicon-collapse-up:before {
	content: "\e160";
}
.bootstrap-scope .glyphicon-log-in:before {
	content: "\e161";
}
.bootstrap-scope .glyphicon-flash:before {
	content: "\e162";
}
.bootstrap-scope .glyphicon-log-out:before {
	content: "\e163";
}
.bootstrap-scope .glyphicon-new-window:before {
	content: "\e164";
}
.bootstrap-scope .glyphicon-record:before {
	content: "\e165";
}
.bootstrap-scope .glyphicon-save:before {
	content: "\e166";
}
.bootstrap-scope .glyphicon-open:before {
	content: "\e167";
}
.bootstrap-scope .glyphicon-saved:before {
	content: "\e168";
}
.bootstrap-scope .glyphicon-import:before {
	content: "\e169";
}
.bootstrap-scope .glyphicon-export:before {
	content: "\e170";
}
.bootstrap-scope .glyphicon-send:before {
	content: "\e171";
}
.bootstrap-scope .glyphicon-floppy-disk:before {
	content: "\e172";
}
.bootstrap-scope .glyphicon-floppy-saved:before {
	content: "\e173";
}
.bootstrap-scope .glyphicon-floppy-remove:before {
	content: "\e174";
}
.bootstrap-scope .glyphicon-floppy-save:before {
	content: "\e175";
}
.bootstrap-scope .glyphicon-floppy-open:before {
	content: "\e176";
}
.bootstrap-scope .glyphicon-credit-card:before {
	content: "\e177";
}
.bootstrap-scope .glyphicon-transfer:before {
	content: "\e178";
}
.bootstrap-scope .glyphicon-cutlery:before {
	content: "\e179";
}
.bootstrap-scope .glyphicon-header:before {
	content: "\e180";
}
.bootstrap-scope .glyphicon-compressed:before {
	content: "\e181";
}
.bootstrap-scope .glyphicon-earphone:before {
	content: "\e182";
}
.bootstrap-scope .glyphicon-phone-alt:before {
	content: "\e183";
}
.bootstrap-scope .glyphicon-tower:before {
	content: "\e184";
}
.bootstrap-scope .glyphicon-stats:before {
	content: "\e185";
}
.bootstrap-scope .glyphicon-sd-video:before {
	content: "\e186";
}
.bootstrap-scope .glyphicon-hd-video:before {
	content: "\e187";
}
.bootstrap-scope .glyphicon-subtitles:before {
	content: "\e188";
}
.bootstrap-scope .glyphicon-sound-stereo:before {
	content: "\e189";
}
.bootstrap-scope .glyphicon-sound-dolby:before {
	content: "\e190";
}
.bootstrap-scope .glyphicon-sound-5-1:before {
	content: "\e191";
}
.bootstrap-scope .glyphicon-sound-6-1:before {
	content: "\e192";
}
.bootstrap-scope .glyphicon-sound-7-1:before {
	content: "\e193";
}
.bootstrap-scope .glyphicon-copyright-mark:before {
	content: "\e194";
}
.bootstrap-scope .glyphicon-registration-mark:before {
	content: "\e195";
}
.bootstrap-scope .glyphicon-cloud-download:before {
	content: "\e197";
}
.bootstrap-scope .glyphicon-cloud-upload:before {
	content: "\e198";
}
.bootstrap-scope .glyphicon-tree-conifer:before {
	content: "\e199";
}
.bootstrap-scope .glyphicon-tree-deciduous:before {
	content: "\e200";
}
.bootstrap-scope .glyphicon-cd:before {
	content: "\e201";
}
.bootstrap-scope .glyphicon-save-file:before {
	content: "\e202";
}
.bootstrap-scope .glyphicon-open-file:before {
	content: "\e203";
}
.bootstrap-scope .glyphicon-level-up:before {
	content: "\e204";
}
.bootstrap-scope .glyphicon-copy:before {
	content: "\e205";
}
.bootstrap-scope .glyphicon-paste:before {
	content: "\e206";
}
.bootstrap-scope .glyphicon-alert:before {
	content: "\e209";
}
.bootstrap-scope .glyphicon-equalizer:before {
	content: "\e210";
}
.bootstrap-scope .glyphicon-king:before {
	content: "\e211";
}
.bootstrap-scope .glyphicon-queen:before {
	content: "\e212";
}
.bootstrap-scope .glyphicon-pawn:before {
	content: "\e213";
}
.bootstrap-scope .glyphicon-bishop:before {
	content: "\e214";
}
.bootstrap-scope .glyphicon-knight:before {
	content: "\e215";
}
.bootstrap-scope .glyphicon-baby-formula:before {
	content: "\e216";
}
.bootstrap-scope .glyphicon-tent:before {
	content: "\26fa";
}
.bootstrap-scope .glyphicon-blackboard:before {
	content: "\e218";
}
.bootstrap-scope .glyphicon-bed:before {
	content: "\e219";
}
.bootstrap-scope .glyphicon-apple:before {
	content: "\f8ff";
}
.bootstrap-scope .glyphicon-erase:before {
	content: "\e221";
}
.bootstrap-scope .glyphicon-hourglass:before {
	content: "\231b";
}
.bootstrap-scope .glyphicon-lamp:before {
	content: "\e223";
}
.bootstrap-scope .glyphicon-duplicate:before {
	content: "\e224";
}
.bootstrap-scope .glyphicon-piggy-bank:before {
	content: "\e225";
}
.bootstrap-scope .glyphicon-scissors:before {
	content: "\e226";
}
.bootstrap-scope .glyphicon-bitcoin:before {
	content: "\e227";
}
.bootstrap-scope .glyphicon-btc:before {
	content: "\e227";
}
.bootstrap-scope .glyphicon-xbt:before {
	content: "\e227";
}
.bootstrap-scope .glyphicon-yen:before {
	content: "\00a5";
}
.bootstrap-scope .glyphicon-jpy:before {
	content: "\00a5";
}
.bootstrap-scope .glyphicon-ruble:before {
	content: "\20bd";
}
.bootstrap-scope .glyphicon-rub:before {
	content: "\20bd";
}
.bootstrap-scope .glyphicon-scale:before {
	content: "\e230";
}
.bootstrap-scope .glyphicon-ice-lolly:before {
	content: "\e231";
}
.bootstrap-scope .glyphicon-ice-lolly-tasted:before {
	content: "\e232";
}
.bootstrap-scope .glyphicon-education:before {
	content: "\e233";
}
.bootstrap-scope .glyphicon-option-horizontal:before {
	content: "\e234";
}
.bootstrap-scope .glyphicon-option-vertical:before {
	content: "\e235";
}
.bootstrap-scope .glyphicon-menu-hamburger:before {
	content: "\e236";
}
.bootstrap-scope .glyphicon-modal-window:before {
	content: "\e237";
}
.bootstrap-scope .glyphicon-oil:before {
	content: "\e238";
}
.bootstrap-scope .glyphicon-grain:before {
	content: "\e239";
}
.bootstrap-scope .glyphicon-sunglasses:before {
	content: "\e240";
}
.bootstrap-scope .glyphicon-text-size:before {
	content: "\e241";
}
.bootstrap-scope .glyphicon-text-color:before {
	content: "\e242";
}
.bootstrap-scope .glyphicon-text-background:before {
	content: "\e243";
}
.bootstrap-scope .glyphicon-object-align-top:before {
	content: "\e244";
}
.bootstrap-scope .glyphicon-object-align-bottom:before {
	content: "\e245";
}
.bootstrap-scope .glyphicon-object-align-horizontal:before {
	content: "\e246";
}
.bootstrap-scope .glyphicon-object-align-left:before {
	content: "\e247";
}
.bootstrap-scope .glyphicon-object-align-vertical:before {
	content: "\e248";
}
.bootstrap-scope .glyphicon-object-align-right:before {
	content: "\e249";
}
.bootstrap-scope .glyphicon-triangle-right:before {
	content: "\e250";
}
.bootstrap-scope .glyphicon-triangle-left:before {
	content: "\e251";
}
.bootstrap-scope .glyphicon-triangle-bottom:before {
	content: "\e252";
}
.bootstrap-scope .glyphicon-triangle-top:before {
	content: "\e253";
}
.bootstrap-scope .glyphicon-console:before {
	content: "\e254";
}
.bootstrap-scope .glyphicon-superscript:before {
	content: "\e255";
}
.bootstrap-scope .glyphicon-subscript:before {
	content: "\e256";
}
.bootstrap-scope .glyphicon-menu-left:before {
	content: "\e257";
}
.bootstrap-scope .glyphicon-menu-right:before {
	content: "\e258";
}
.bootstrap-scope .glyphicon-menu-down:before {
	content: "\e259";
}
.bootstrap-scope .glyphicon-menu-up:before {
	content: "\e260";
}
.bootstrap-scope * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bootstrap-scope *:before,
.bootstrap-scope *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bootstrap-scope html {
	font-size: 10px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.bootstrap-scope body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
	background-color: #fff;
}
.bootstrap-scope input,
.bootstrap-scope button,
.bootstrap-scope select,
.bootstrap-scope textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
.bootstrap-scope a {
	color: #337ab7;
	text-decoration: none;
}
.bootstrap-scope a:hover,
.bootstrap-scope a:focus {
	color: #23527c;
	text-decoration: underline;
}
.bootstrap-scope a:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.bootstrap-scope figure {
	margin: 0;
}
.bootstrap-scope img {
	vertical-align: middle;
}
.bootstrap-scope .img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}
.bootstrap-scope .img-rounded {
	border-radius: 6px;
}
.bootstrap-scope .img-thumbnail {
	padding: 4px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto;
}
.bootstrap-scope .img-circle {
	border-radius: 50%;
}
.bootstrap-scope hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #eeeeee;
}
.bootstrap-scope .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
.bootstrap-scope .sr-only-focusable:active,
.bootstrap-scope .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}
.bootstrap-scope [role="button"] {
	cursor: pointer;
}
.bootstrap-scope h1,
.bootstrap-scope h2,
.bootstrap-scope h3,
.bootstrap-scope h4,
.bootstrap-scope h5,
.bootstrap-scope h6,
.bootstrap-scope .h1,
.bootstrap-scope .h2,
.bootstrap-scope .h3,
.bootstrap-scope .h4,
.bootstrap-scope .h5,
.bootstrap-scope .h6 {
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	color: inherit;
}
.bootstrap-scope h1 small,
.bootstrap-scope h2 small,
.bootstrap-scope h3 small,
.bootstrap-scope h4 small,
.bootstrap-scope h5 small,
.bootstrap-scope h6 small,
.bootstrap-scope .h1 small,
.bootstrap-scope .h2 small,
.bootstrap-scope .h3 small,
.bootstrap-scope .h4 small,
.bootstrap-scope .h5 small,
.bootstrap-scope .h6 small,
.bootstrap-scope h1 .small,
.bootstrap-scope h2 .small,
.bootstrap-scope h3 .small,
.bootstrap-scope h4 .small,
.bootstrap-scope h5 .small,
.bootstrap-scope h6 .small,
.bootstrap-scope .h1 .small,
.bootstrap-scope .h2 .small,
.bootstrap-scope .h3 .small,
.bootstrap-scope .h4 .small,
.bootstrap-scope .h5 .small,
.bootstrap-scope .h6 .small {
	font-weight: normal;
	line-height: 1;
	color: #777777;
}
.bootstrap-scope h1,
.bootstrap-scope .h1,
.bootstrap-scope h2,
.bootstrap-scope .h2,
.bootstrap-scope h3,
.bootstrap-scope .h3 {
	margin-top: 20px;
	margin-bottom: 10px;
}
.bootstrap-scope h1 small,
.bootstrap-scope .h1 small,
.bootstrap-scope h2 small,
.bootstrap-scope .h2 small,
.bootstrap-scope h3 small,
.bootstrap-scope .h3 small,
.bootstrap-scope h1 .small,
.bootstrap-scope .h1 .small,
.bootstrap-scope h2 .small,
.bootstrap-scope .h2 .small,
.bootstrap-scope h3 .small,
.bootstrap-scope .h3 .small {
	font-size: 65%;
}
.bootstrap-scope h4,
.bootstrap-scope .h4,
.bootstrap-scope h5,
.bootstrap-scope .h5,
.bootstrap-scope h6,
.bootstrap-scope .h6 {
	margin-top: 10px;
	margin-bottom: 10px;
}
.bootstrap-scope h4 small,
.bootstrap-scope .h4 small,
.bootstrap-scope h5 small,
.bootstrap-scope .h5 small,
.bootstrap-scope h6 small,
.bootstrap-scope .h6 small,
.bootstrap-scope h4 .small,
.bootstrap-scope .h4 .small,
.bootstrap-scope h5 .small,
.bootstrap-scope .h5 .small,
.bootstrap-scope h6 .small,
.bootstrap-scope .h6 .small {
	font-size: 75%;
}
.bootstrap-scope h1,
.bootstrap-scope .h1 {
	font-size: 36px;
}
.bootstrap-scope h2,
.bootstrap-scope .h2 {
	font-size: 30px;
}
.bootstrap-scope h3,
.bootstrap-scope .h3 {
	font-size: 24px;
}
.bootstrap-scope h4,
.bootstrap-scope .h4 {
	font-size: 18px;
}
.bootstrap-scope h5,
.bootstrap-scope .h5 {
	font-size: 14px;
}
.bootstrap-scope h6,
.bootstrap-scope .h6 {
	font-size: 12px;
}
.bootstrap-scope p {
	margin: 0 0 10px;
}
.bootstrap-scope .lead {
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.4;
}
@media (min-width: 768px) {
	.bootstrap-scope .lead {
		font-size: 21px;
	}
}
.bootstrap-scope small,
.bootstrap-scope .small {
	font-size: 85%;
}
.bootstrap-scope mark,
.bootstrap-scope .mark {
	background-color: #fcf8e3;
	padding: 0.2em;
}
.bootstrap-scope .text-left {
	text-align: left;
}
.bootstrap-scope .text-right {
	text-align: right;
}
.bootstrap-scope .text-center {
	text-align: center;
}
.bootstrap-scope .text-justify {
	text-align: justify;
}
.bootstrap-scope .text-nowrap {
	white-space: nowrap;
}
.bootstrap-scope .text-lowercase {
	text-transform: lowercase;
}
.bootstrap-scope .text-uppercase {
	text-transform: uppercase;
}
.bootstrap-scope .text-capitalize {
	text-transform: capitalize;
}
.bootstrap-scope .text-muted {
	color: #777777;
}
.bootstrap-scope .text-primary {
	color: #337ab7;
}
a.bootstrap-scope .text-primary:hover {
	color: #286090;
}
.bootstrap-scope .text-success {
	color: #3c763d;
}
a.bootstrap-scope .text-success:hover {
	color: #2b542c;
}
.bootstrap-scope .text-info {
	color: #31708f;
}
a.bootstrap-scope .text-info:hover {
	color: #245269;
}
.bootstrap-scope .text-warning {
	color: #8a6d3b;
}
a.bootstrap-scope .text-warning:hover {
	color: #66512c;
}
.bootstrap-scope .text-danger {
	color: #a94442;
}
a.bootstrap-scope .text-danger:hover {
	color: #843534;
}
.bootstrap-scope .bg-primary {
	color: #fff;
	background-color: #337ab7;
}
a.bootstrap-scope .bg-primary:hover {
	background-color: #286090;
}
.bootstrap-scope .bg-success {
	background-color: #dff0d8;
}
a.bootstrap-scope .bg-success:hover {
	background-color: #c1e2b3;
}
.bootstrap-scope .bg-info {
	background-color: #d9edf7;
}
a.bootstrap-scope .bg-info:hover {
	background-color: #afd9ee;
}
.bootstrap-scope .bg-warning {
	background-color: #fcf8e3;
}
a.bootstrap-scope .bg-warning:hover {
	background-color: #f7ecb5;
}
.bootstrap-scope .bg-danger {
	background-color: #f2dede;
}
a.bootstrap-scope .bg-danger:hover {
	background-color: #e4b9b9;
}
.bootstrap-scope .page-header {
	padding-bottom: 9px;
	margin: 40px 0 20px;
	border-bottom: 1px solid #eeeeee;
}
.bootstrap-scope ul,
.bootstrap-scope ol {
	margin-top: 0;
	margin-bottom: 10px;
}
.bootstrap-scope ul ul,
.bootstrap-scope ol ul,
.bootstrap-scope ul ol,
.bootstrap-scope ol ol {
	margin-bottom: 0;
}
.bootstrap-scope .list-unstyled {
	padding-left: 0;
	list-style: none;
}
.bootstrap-scope .list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px;
}
.bootstrap-scope .list-inline > li {
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
}
.bootstrap-scope dl {
	margin-top: 0;
	margin-bottom: 20px;
}
.bootstrap-scope dt,
.bootstrap-scope dd {
	line-height: 1.42857143;
}
.bootstrap-scope dt {
	font-weight: bold;
}
.bootstrap-scope dd {
	margin-left: 0;
}
@media (min-width: 768px) {
	.bootstrap-scope .dl-horizontal dt {
		float: left;
		width: 160px;
		clear: left;
		text-align: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.bootstrap-scope .dl-horizontal dd {
		margin-left: 180px;
	}
}
.bootstrap-scope abbr[title],
.bootstrap-scope abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted #777777;
}
.bootstrap-scope .initialism {
	font-size: 90%;
	text-transform: uppercase;
}
.bootstrap-scope blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 5px solid #eeeeee;
}
.bootstrap-scope blockquote p:last-child,
.bootstrap-scope blockquote ul:last-child,
.bootstrap-scope blockquote ol:last-child {
	margin-bottom: 0;
}
.bootstrap-scope blockquote footer,
.bootstrap-scope blockquote small,
.bootstrap-scope blockquote .small {
	display: block;
	font-size: 80%;
	line-height: 1.42857143;
	color: #777777;
}
.bootstrap-scope blockquote footer:before,
.bootstrap-scope blockquote small:before,
.bootstrap-scope blockquote .small:before {
	content: "\2014 \00A0";
}
.bootstrap-scope .blockquote-reverse,
.bootstrap-scope blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #eeeeee;
	border-left: 0;
	text-align: right;
}
.bootstrap-scope .blockquote-reverse footer:before,
.bootstrap-scope blockquote.pull-right footer:before,
.bootstrap-scope .blockquote-reverse small:before,
.bootstrap-scope blockquote.pull-right small:before,
.bootstrap-scope .blockquote-reverse .small:before,
.bootstrap-scope blockquote.pull-right .small:before {
	content: "";
}
.bootstrap-scope .blockquote-reverse footer:after,
.bootstrap-scope blockquote.pull-right footer:after,
.bootstrap-scope .blockquote-reverse small:after,
.bootstrap-scope blockquote.pull-right small:after,
.bootstrap-scope .blockquote-reverse .small:after,
.bootstrap-scope blockquote.pull-right .small:after {
	content: "\00A0 \2014";
}
.bootstrap-scope address {
	margin-bottom: 20px;
	font-style: normal;
	line-height: 1.42857143;
}
.bootstrap-scope code,
.bootstrap-scope kbd,
.bootstrap-scope pre,
.bootstrap-scope samp {
	font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.bootstrap-scope code {
	padding: 2px 4px;
	font-size: 90%;
	color: #c7254e;
	background-color: #f9f2f4;
	border-radius: 4px;
}
.bootstrap-scope kbd {
	padding: 2px 4px;
	font-size: 90%;
	color: #fff;
	background-color: #333;
	border-radius: 3px;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-scope kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: bold;
	box-shadow: none;
}
.bootstrap-scope pre {
	display: block;
	padding: 9.5px;
	margin: 0 0 10px;
	font-size: 13px;
	line-height: 1.42857143;
	word-break: break-all;
	word-wrap: break-word;
	color: #333333;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.bootstrap-scope pre code {
	padding: 0;
	font-size: inherit;
	color: inherit;
	white-space: pre-wrap;
	background-color: transparent;
	border-radius: 0;
}
.bootstrap-scope .pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}
.bootstrap-scope .container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
@media (min-width: 768px) {
	.bootstrap-scope .container {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.bootstrap-scope .container {
		width: 970px;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .container {
		width: 1170px;
	}
}
.bootstrap-scope .container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
.bootstrap-scope .row {
	margin-left: -15px;
	margin-right: -15px;
}
.bootstrap-scope .col-xs-1,
.bootstrap-scope .col-sm-1,
.bootstrap-scope .col-md-1,
.bootstrap-scope .col-lg-1,
.bootstrap-scope .col-xs-2,
.bootstrap-scope .col-sm-2,
.bootstrap-scope .col-md-2,
.bootstrap-scope .col-lg-2,
.bootstrap-scope .col-xs-3,
.bootstrap-scope .col-sm-3,
.bootstrap-scope .col-md-3,
.bootstrap-scope .col-lg-3,
.bootstrap-scope .col-xs-4,
.bootstrap-scope .col-sm-4,
.bootstrap-scope .col-md-4,
.bootstrap-scope .col-lg-4,
.bootstrap-scope .col-xs-5,
.bootstrap-scope .col-sm-5,
.bootstrap-scope .col-md-5,
.bootstrap-scope .col-lg-5,
.bootstrap-scope .col-xs-6,
.bootstrap-scope .col-sm-6,
.bootstrap-scope .col-md-6,
.bootstrap-scope .col-lg-6,
.bootstrap-scope .col-xs-7,
.bootstrap-scope .col-sm-7,
.bootstrap-scope .col-md-7,
.bootstrap-scope .col-lg-7,
.bootstrap-scope .col-xs-8,
.bootstrap-scope .col-sm-8,
.bootstrap-scope .col-md-8,
.bootstrap-scope .col-lg-8,
.bootstrap-scope .col-xs-9,
.bootstrap-scope .col-sm-9,
.bootstrap-scope .col-md-9,
.bootstrap-scope .col-lg-9,
.bootstrap-scope .col-xs-10,
.bootstrap-scope .col-sm-10,
.bootstrap-scope .col-md-10,
.bootstrap-scope .col-lg-10,
.bootstrap-scope .col-xs-11,
.bootstrap-scope .col-sm-11,
.bootstrap-scope .col-md-11,
.bootstrap-scope .col-lg-11,
.bootstrap-scope .col-xs-12,
.bootstrap-scope .col-sm-12,
.bootstrap-scope .col-md-12,
.bootstrap-scope .col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}
.bootstrap-scope .col-xs-1,
.bootstrap-scope .col-xs-2,
.bootstrap-scope .col-xs-3,
.bootstrap-scope .col-xs-4,
.bootstrap-scope .col-xs-5,
.bootstrap-scope .col-xs-6,
.bootstrap-scope .col-xs-7,
.bootstrap-scope .col-xs-8,
.bootstrap-scope .col-xs-9,
.bootstrap-scope .col-xs-10,
.bootstrap-scope .col-xs-11,
.bootstrap-scope .col-xs-12 {
	float: left;
}
.bootstrap-scope .col-xs-12 {
	width: 100%;
}
.bootstrap-scope .col-xs-11 {
	width: 91.66666667%;
}
.bootstrap-scope .col-xs-10 {
	width: 83.33333333%;
}
.bootstrap-scope .col-xs-9 {
	width: 75%;
}
.bootstrap-scope .col-xs-8 {
	width: 66.66666667%;
}
.bootstrap-scope .col-xs-7 {
	width: 58.33333333%;
}
.bootstrap-scope .col-xs-6 {
	width: 50%;
}
.bootstrap-scope .col-xs-5 {
	width: 41.66666667%;
}
.bootstrap-scope .col-xs-4 {
	width: 33.33333333%;
}
.bootstrap-scope .col-xs-3 {
	width: 25%;
}
.bootstrap-scope .col-xs-2 {
	width: 16.66666667%;
}
.bootstrap-scope .col-xs-1 {
	width: 8.33333333%;
}
.bootstrap-scope .col-xs-pull-12 {
	right: 100%;
}
.bootstrap-scope .col-xs-pull-11 {
	right: 91.66666667%;
}
.bootstrap-scope .col-xs-pull-10 {
	right: 83.33333333%;
}
.bootstrap-scope .col-xs-pull-9 {
	right: 75%;
}
.bootstrap-scope .col-xs-pull-8 {
	right: 66.66666667%;
}
.bootstrap-scope .col-xs-pull-7 {
	right: 58.33333333%;
}
.bootstrap-scope .col-xs-pull-6 {
	right: 50%;
}
.bootstrap-scope .col-xs-pull-5 {
	right: 41.66666667%;
}
.bootstrap-scope .col-xs-pull-4 {
	right: 33.33333333%;
}
.bootstrap-scope .col-xs-pull-3 {
	right: 25%;
}
.bootstrap-scope .col-xs-pull-2 {
	right: 16.66666667%;
}
.bootstrap-scope .col-xs-pull-1 {
	right: 8.33333333%;
}
.bootstrap-scope .col-xs-pull-0 {
	right: auto;
}
.bootstrap-scope .col-xs-push-12 {
	left: 100%;
}
.bootstrap-scope .col-xs-push-11 {
	left: 91.66666667%;
}
.bootstrap-scope .col-xs-push-10 {
	left: 83.33333333%;
}
.bootstrap-scope .col-xs-push-9 {
	left: 75%;
}
.bootstrap-scope .col-xs-push-8 {
	left: 66.66666667%;
}
.bootstrap-scope .col-xs-push-7 {
	left: 58.33333333%;
}
.bootstrap-scope .col-xs-push-6 {
	left: 50%;
}
.bootstrap-scope .col-xs-push-5 {
	left: 41.66666667%;
}
.bootstrap-scope .col-xs-push-4 {
	left: 33.33333333%;
}
.bootstrap-scope .col-xs-push-3 {
	left: 25%;
}
.bootstrap-scope .col-xs-push-2 {
	left: 16.66666667%;
}
.bootstrap-scope .col-xs-push-1 {
	left: 8.33333333%;
}
.bootstrap-scope .col-xs-push-0 {
	left: auto;
}
.bootstrap-scope .col-xs-offset-12 {
	margin-left: 100%;
}
.bootstrap-scope .col-xs-offset-11 {
	margin-left: 91.66666667%;
}
.bootstrap-scope .col-xs-offset-10 {
	margin-left: 83.33333333%;
}
.bootstrap-scope .col-xs-offset-9 {
	margin-left: 75%;
}
.bootstrap-scope .col-xs-offset-8 {
	margin-left: 66.66666667%;
}
.bootstrap-scope .col-xs-offset-7 {
	margin-left: 58.33333333%;
}
.bootstrap-scope .col-xs-offset-6 {
	margin-left: 50%;
}
.bootstrap-scope .col-xs-offset-5 {
	margin-left: 41.66666667%;
}
.bootstrap-scope .col-xs-offset-4 {
	margin-left: 33.33333333%;
}
.bootstrap-scope .col-xs-offset-3 {
	margin-left: 25%;
}
.bootstrap-scope .col-xs-offset-2 {
	margin-left: 16.66666667%;
}
.bootstrap-scope .col-xs-offset-1 {
	margin-left: 8.33333333%;
}
.bootstrap-scope .col-xs-offset-0 {
	margin-left: 0%;
}
@media (min-width: 768px) {
	.bootstrap-scope .col-sm-1,
	.bootstrap-scope .col-sm-2,
	.bootstrap-scope .col-sm-3,
	.bootstrap-scope .col-sm-4,
	.bootstrap-scope .col-sm-5,
	.bootstrap-scope .col-sm-6,
	.bootstrap-scope .col-sm-7,
	.bootstrap-scope .col-sm-8,
	.bootstrap-scope .col-sm-9,
	.bootstrap-scope .col-sm-10,
	.bootstrap-scope .col-sm-11,
	.bootstrap-scope .col-sm-12 {
		float: left;
	}
	.bootstrap-scope .col-sm-12 {
		width: 100%;
	}
	.bootstrap-scope .col-sm-11 {
		width: 91.66666667%;
	}
	.bootstrap-scope .col-sm-10 {
		width: 83.33333333%;
	}
	.bootstrap-scope .col-sm-9 {
		width: 75%;
	}
	.bootstrap-scope .col-sm-8 {
		width: 66.66666667%;
	}
	.bootstrap-scope .col-sm-7 {
		width: 58.33333333%;
	}
	.bootstrap-scope .col-sm-6 {
		width: 50%;
	}
	.bootstrap-scope .col-sm-5 {
		width: 41.66666667%;
	}
	.bootstrap-scope .col-sm-4 {
		width: 33.33333333%;
	}
	.bootstrap-scope .col-sm-3 {
		width: 25%;
	}
	.bootstrap-scope .col-sm-2 {
		width: 16.66666667%;
	}
	.bootstrap-scope .col-sm-1 {
		width: 8.33333333%;
	}
	.bootstrap-scope .col-sm-pull-12 {
		right: 100%;
	}
	.bootstrap-scope .col-sm-pull-11 {
		right: 91.66666667%;
	}
	.bootstrap-scope .col-sm-pull-10 {
		right: 83.33333333%;
	}
	.bootstrap-scope .col-sm-pull-9 {
		right: 75%;
	}
	.bootstrap-scope .col-sm-pull-8 {
		right: 66.66666667%;
	}
	.bootstrap-scope .col-sm-pull-7 {
		right: 58.33333333%;
	}
	.bootstrap-scope .col-sm-pull-6 {
		right: 50%;
	}
	.bootstrap-scope .col-sm-pull-5 {
		right: 41.66666667%;
	}
	.bootstrap-scope .col-sm-pull-4 {
		right: 33.33333333%;
	}
	.bootstrap-scope .col-sm-pull-3 {
		right: 25%;
	}
	.bootstrap-scope .col-sm-pull-2 {
		right: 16.66666667%;
	}
	.bootstrap-scope .col-sm-pull-1 {
		right: 8.33333333%;
	}
	.bootstrap-scope .col-sm-pull-0 {
		right: auto;
	}
	.bootstrap-scope .col-sm-push-12 {
		left: 100%;
	}
	.bootstrap-scope .col-sm-push-11 {
		left: 91.66666667%;
	}
	.bootstrap-scope .col-sm-push-10 {
		left: 83.33333333%;
	}
	.bootstrap-scope .col-sm-push-9 {
		left: 75%;
	}
	.bootstrap-scope .col-sm-push-8 {
		left: 66.66666667%;
	}
	.bootstrap-scope .col-sm-push-7 {
		left: 58.33333333%;
	}
	.bootstrap-scope .col-sm-push-6 {
		left: 50%;
	}
	.bootstrap-scope .col-sm-push-5 {
		left: 41.66666667%;
	}
	.bootstrap-scope .col-sm-push-4 {
		left: 33.33333333%;
	}
	.bootstrap-scope .col-sm-push-3 {
		left: 25%;
	}
	.bootstrap-scope .col-sm-push-2 {
		left: 16.66666667%;
	}
	.bootstrap-scope .col-sm-push-1 {
		left: 8.33333333%;
	}
	.bootstrap-scope .col-sm-push-0 {
		left: auto;
	}
	.bootstrap-scope .col-sm-offset-12 {
		margin-left: 100%;
	}
	.bootstrap-scope .col-sm-offset-11 {
		margin-left: 91.66666667%;
	}
	.bootstrap-scope .col-sm-offset-10 {
		margin-left: 83.33333333%;
	}
	.bootstrap-scope .col-sm-offset-9 {
		margin-left: 75%;
	}
	.bootstrap-scope .col-sm-offset-8 {
		margin-left: 66.66666667%;
	}
	.bootstrap-scope .col-sm-offset-7 {
		margin-left: 58.33333333%;
	}
	.bootstrap-scope .col-sm-offset-6 {
		margin-left: 50%;
	}
	.bootstrap-scope .col-sm-offset-5 {
		margin-left: 41.66666667%;
	}
	.bootstrap-scope .col-sm-offset-4 {
		margin-left: 33.33333333%;
	}
	.bootstrap-scope .col-sm-offset-3 {
		margin-left: 25%;
	}
	.bootstrap-scope .col-sm-offset-2 {
		margin-left: 16.66666667%;
	}
	.bootstrap-scope .col-sm-offset-1 {
		margin-left: 8.33333333%;
	}
	.bootstrap-scope .col-sm-offset-0 {
		margin-left: 0%;
	}
}
@media (min-width: 992px) {
	.bootstrap-scope .col-md-1,
	.bootstrap-scope .col-md-2,
	.bootstrap-scope .col-md-3,
	.bootstrap-scope .col-md-4,
	.bootstrap-scope .col-md-5,
	.bootstrap-scope .col-md-6,
	.bootstrap-scope .col-md-7,
	.bootstrap-scope .col-md-8,
	.bootstrap-scope .col-md-9,
	.bootstrap-scope .col-md-10,
	.bootstrap-scope .col-md-11,
	.bootstrap-scope .col-md-12 {
		float: left;
	}
	.bootstrap-scope .col-md-12 {
		width: 100%;
	}
	.bootstrap-scope .col-md-11 {
		width: 91.66666667%;
	}
	.bootstrap-scope .col-md-10 {
		width: 83.33333333%;
	}
	.bootstrap-scope .col-md-9 {
		width: 75%;
	}
	.bootstrap-scope .col-md-8 {
		width: 66.66666667%;
	}
	.bootstrap-scope .col-md-7 {
		width: 58.33333333%;
	}
	.bootstrap-scope .col-md-6 {
		width: 50%;
	}
	.bootstrap-scope .col-md-5 {
		width: 41.66666667%;
	}
	.bootstrap-scope .col-md-4 {
		width: 33.33333333%;
	}
	.bootstrap-scope .col-md-3 {
		width: 25%;
	}
	.bootstrap-scope .col-md-2 {
		width: 16.66666667%;
	}
	.bootstrap-scope .col-md-1 {
		width: 8.33333333%;
	}
	.bootstrap-scope .col-md-pull-12 {
		right: 100%;
	}
	.bootstrap-scope .col-md-pull-11 {
		right: 91.66666667%;
	}
	.bootstrap-scope .col-md-pull-10 {
		right: 83.33333333%;
	}
	.bootstrap-scope .col-md-pull-9 {
		right: 75%;
	}
	.bootstrap-scope .col-md-pull-8 {
		right: 66.66666667%;
	}
	.bootstrap-scope .col-md-pull-7 {
		right: 58.33333333%;
	}
	.bootstrap-scope .col-md-pull-6 {
		right: 50%;
	}
	.bootstrap-scope .col-md-pull-5 {
		right: 41.66666667%;
	}
	.bootstrap-scope .col-md-pull-4 {
		right: 33.33333333%;
	}
	.bootstrap-scope .col-md-pull-3 {
		right: 25%;
	}
	.bootstrap-scope .col-md-pull-2 {
		right: 16.66666667%;
	}
	.bootstrap-scope .col-md-pull-1 {
		right: 8.33333333%;
	}
	.bootstrap-scope .col-md-pull-0 {
		right: auto;
	}
	.bootstrap-scope .col-md-push-12 {
		left: 100%;
	}
	.bootstrap-scope .col-md-push-11 {
		left: 91.66666667%;
	}
	.bootstrap-scope .col-md-push-10 {
		left: 83.33333333%;
	}
	.bootstrap-scope .col-md-push-9 {
		left: 75%;
	}
	.bootstrap-scope .col-md-push-8 {
		left: 66.66666667%;
	}
	.bootstrap-scope .col-md-push-7 {
		left: 58.33333333%;
	}
	.bootstrap-scope .col-md-push-6 {
		left: 50%;
	}
	.bootstrap-scope .col-md-push-5 {
		left: 41.66666667%;
	}
	.bootstrap-scope .col-md-push-4 {
		left: 33.33333333%;
	}
	.bootstrap-scope .col-md-push-3 {
		left: 25%;
	}
	.bootstrap-scope .col-md-push-2 {
		left: 16.66666667%;
	}
	.bootstrap-scope .col-md-push-1 {
		left: 8.33333333%;
	}
	.bootstrap-scope .col-md-push-0 {
		left: auto;
	}
	.bootstrap-scope .col-md-offset-12 {
		margin-left: 100%;
	}
	.bootstrap-scope .col-md-offset-11 {
		margin-left: 91.66666667%;
	}
	.bootstrap-scope .col-md-offset-10 {
		margin-left: 83.33333333%;
	}
	.bootstrap-scope .col-md-offset-9 {
		margin-left: 75%;
	}
	.bootstrap-scope .col-md-offset-8 {
		margin-left: 66.66666667%;
	}
	.bootstrap-scope .col-md-offset-7 {
		margin-left: 58.33333333%;
	}
	.bootstrap-scope .col-md-offset-6 {
		margin-left: 50%;
	}
	.bootstrap-scope .col-md-offset-5 {
		margin-left: 41.66666667%;
	}
	.bootstrap-scope .col-md-offset-4 {
		margin-left: 33.33333333%;
	}
	.bootstrap-scope .col-md-offset-3 {
		margin-left: 25%;
	}
	.bootstrap-scope .col-md-offset-2 {
		margin-left: 16.66666667%;
	}
	.bootstrap-scope .col-md-offset-1 {
		margin-left: 8.33333333%;
	}
	.bootstrap-scope .col-md-offset-0 {
		margin-left: 0%;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .col-lg-1,
	.bootstrap-scope .col-lg-2,
	.bootstrap-scope .col-lg-3,
	.bootstrap-scope .col-lg-4,
	.bootstrap-scope .col-lg-5,
	.bootstrap-scope .col-lg-6,
	.bootstrap-scope .col-lg-7,
	.bootstrap-scope .col-lg-8,
	.bootstrap-scope .col-lg-9,
	.bootstrap-scope .col-lg-10,
	.bootstrap-scope .col-lg-11,
	.bootstrap-scope .col-lg-12 {
		float: left;
	}
	.bootstrap-scope .col-lg-12 {
		width: 100%;
	}
	.bootstrap-scope .col-lg-11 {
		width: 91.66666667%;
	}
	.bootstrap-scope .col-lg-10 {
		width: 83.33333333%;
	}
	.bootstrap-scope .col-lg-9 {
		width: 75%;
	}
	.bootstrap-scope .col-lg-8 {
		width: 66.66666667%;
	}
	.bootstrap-scope .col-lg-7 {
		width: 58.33333333%;
	}
	.bootstrap-scope .col-lg-6 {
		width: 50%;
	}
	.bootstrap-scope .col-lg-5 {
		width: 41.66666667%;
	}
	.bootstrap-scope .col-lg-4 {
		width: 33.33333333%;
	}
	.bootstrap-scope .col-lg-3 {
		width: 25%;
	}
	.bootstrap-scope .col-lg-2 {
		width: 16.66666667%;
	}
	.bootstrap-scope .col-lg-1 {
		width: 8.33333333%;
	}
	.bootstrap-scope .col-lg-pull-12 {
		right: 100%;
	}
	.bootstrap-scope .col-lg-pull-11 {
		right: 91.66666667%;
	}
	.bootstrap-scope .col-lg-pull-10 {
		right: 83.33333333%;
	}
	.bootstrap-scope .col-lg-pull-9 {
		right: 75%;
	}
	.bootstrap-scope .col-lg-pull-8 {
		right: 66.66666667%;
	}
	.bootstrap-scope .col-lg-pull-7 {
		right: 58.33333333%;
	}
	.bootstrap-scope .col-lg-pull-6 {
		right: 50%;
	}
	.bootstrap-scope .col-lg-pull-5 {
		right: 41.66666667%;
	}
	.bootstrap-scope .col-lg-pull-4 {
		right: 33.33333333%;
	}
	.bootstrap-scope .col-lg-pull-3 {
		right: 25%;
	}
	.bootstrap-scope .col-lg-pull-2 {
		right: 16.66666667%;
	}
	.bootstrap-scope .col-lg-pull-1 {
		right: 8.33333333%;
	}
	.bootstrap-scope .col-lg-pull-0 {
		right: auto;
	}
	.bootstrap-scope .col-lg-push-12 {
		left: 100%;
	}
	.bootstrap-scope .col-lg-push-11 {
		left: 91.66666667%;
	}
	.bootstrap-scope .col-lg-push-10 {
		left: 83.33333333%;
	}
	.bootstrap-scope .col-lg-push-9 {
		left: 75%;
	}
	.bootstrap-scope .col-lg-push-8 {
		left: 66.66666667%;
	}
	.bootstrap-scope .col-lg-push-7 {
		left: 58.33333333%;
	}
	.bootstrap-scope .col-lg-push-6 {
		left: 50%;
	}
	.bootstrap-scope .col-lg-push-5 {
		left: 41.66666667%;
	}
	.bootstrap-scope .col-lg-push-4 {
		left: 33.33333333%;
	}
	.bootstrap-scope .col-lg-push-3 {
		left: 25%;
	}
	.bootstrap-scope .col-lg-push-2 {
		left: 16.66666667%;
	}
	.bootstrap-scope .col-lg-push-1 {
		left: 8.33333333%;
	}
	.bootstrap-scope .col-lg-push-0 {
		left: auto;
	}
	.bootstrap-scope .col-lg-offset-12 {
		margin-left: 100%;
	}
	.bootstrap-scope .col-lg-offset-11 {
		margin-left: 91.66666667%;
	}
	.bootstrap-scope .col-lg-offset-10 {
		margin-left: 83.33333333%;
	}
	.bootstrap-scope .col-lg-offset-9 {
		margin-left: 75%;
	}
	.bootstrap-scope .col-lg-offset-8 {
		margin-left: 66.66666667%;
	}
	.bootstrap-scope .col-lg-offset-7 {
		margin-left: 58.33333333%;
	}
	.bootstrap-scope .col-lg-offset-6 {
		margin-left: 50%;
	}
	.bootstrap-scope .col-lg-offset-5 {
		margin-left: 41.66666667%;
	}
	.bootstrap-scope .col-lg-offset-4 {
		margin-left: 33.33333333%;
	}
	.bootstrap-scope .col-lg-offset-3 {
		margin-left: 25%;
	}
	.bootstrap-scope .col-lg-offset-2 {
		margin-left: 16.66666667%;
	}
	.bootstrap-scope .col-lg-offset-1 {
		margin-left: 8.33333333%;
	}
	.bootstrap-scope .col-lg-offset-0 {
		margin-left: 0%;
	}
}
.bootstrap-scope table {
	background-color: transparent;
}
.bootstrap-scope caption {
	padding-top: 8px;
	padding-bottom: 8px;
	color: #777777;
	text-align: left;
}
.bootstrap-scope th {
	text-align: left;
}
.bootstrap-scope .table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
}
.bootstrap-scope .table > thead > tr > th,
.bootstrap-scope .table > tbody > tr > th,
.bootstrap-scope .table > tfoot > tr > th,
.bootstrap-scope .table > thead > tr > td,
.bootstrap-scope .table > tbody > tr > td,
.bootstrap-scope .table > tfoot > tr > td {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border-top: 1px solid #ddd;
}
.bootstrap-scope .table > thead > tr > th {
	vertical-align: bottom;
	border-bottom: 2px solid #ddd;
}
.bootstrap-scope .table > caption + thead > tr:first-child > th,
.bootstrap-scope .table > colgroup + thead > tr:first-child > th,
.bootstrap-scope .table > thead:first-child > tr:first-child > th,
.bootstrap-scope .table > caption + thead > tr:first-child > td,
.bootstrap-scope .table > colgroup + thead > tr:first-child > td,
.bootstrap-scope .table > thead:first-child > tr:first-child > td {
	border-top: 0;
}
.bootstrap-scope .table > tbody + tbody {
	border-top: 2px solid #ddd;
}
.bootstrap-scope .table .table {
	background-color: #fff;
}
.bootstrap-scope .table-condensed > thead > tr > th,
.bootstrap-scope .table-condensed > tbody > tr > th,
.bootstrap-scope .table-condensed > tfoot > tr > th,
.bootstrap-scope .table-condensed > thead > tr > td,
.bootstrap-scope .table-condensed > tbody > tr > td,
.bootstrap-scope .table-condensed > tfoot > tr > td {
	padding: 5px;
}
.bootstrap-scope .table-bordered {
	border: 1px solid #ddd;
}
.bootstrap-scope .table-bordered > thead > tr > th,
.bootstrap-scope .table-bordered > tbody > tr > th,
.bootstrap-scope .table-bordered > tfoot > tr > th,
.bootstrap-scope .table-bordered > thead > tr > td,
.bootstrap-scope .table-bordered > tbody > tr > td,
.bootstrap-scope .table-bordered > tfoot > tr > td {
	border: 1px solid #ddd;
}
.bootstrap-scope .table-bordered > thead > tr > th,
.bootstrap-scope .table-bordered > thead > tr > td {
	border-bottom-width: 2px;
}
.bootstrap-scope .table-striped > tbody > tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}
.bootstrap-scope .table-hover > tbody > tr:hover {
	background-color: #f5f5f5;
}
.bootstrap-scope table col[class*="col-"] {
	position: static;
	float: none;
	display: table-column;
}
.bootstrap-scope table td[class*="col-"],
.bootstrap-scope table th[class*="col-"] {
	position: static;
	float: none;
	display: table-cell;
}
.bootstrap-scope .table > thead > tr > td.active,
.bootstrap-scope .table > tbody > tr > td.active,
.bootstrap-scope .table > tfoot > tr > td.active,
.bootstrap-scope .table > thead > tr > th.active,
.bootstrap-scope .table > tbody > tr > th.active,
.bootstrap-scope .table > tfoot > tr > th.active,
.bootstrap-scope .table > thead > tr.active > td,
.bootstrap-scope .table > tbody > tr.active > td,
.bootstrap-scope .table > tfoot > tr.active > td,
.bootstrap-scope .table > thead > tr.active > th,
.bootstrap-scope .table > tbody > tr.active > th,
.bootstrap-scope .table > tfoot > tr.active > th {
	background-color: #f5f5f5;
}
.bootstrap-scope .table-hover > tbody > tr > td.active:hover,
.bootstrap-scope .table-hover > tbody > tr > th.active:hover,
.bootstrap-scope .table-hover > tbody > tr.active:hover > td,
.bootstrap-scope .table-hover > tbody > tr:hover > .active,
.bootstrap-scope .table-hover > tbody > tr.active:hover > th {
	background-color: #e8e8e8;
}
.bootstrap-scope .table > thead > tr > td.success,
.bootstrap-scope .table > tbody > tr > td.success,
.bootstrap-scope .table > tfoot > tr > td.success,
.bootstrap-scope .table > thead > tr > th.success,
.bootstrap-scope .table > tbody > tr > th.success,
.bootstrap-scope .table > tfoot > tr > th.success,
.bootstrap-scope .table > thead > tr.success > td,
.bootstrap-scope .table > tbody > tr.success > td,
.bootstrap-scope .table > tfoot > tr.success > td,
.bootstrap-scope .table > thead > tr.success > th,
.bootstrap-scope .table > tbody > tr.success > th,
.bootstrap-scope .table > tfoot > tr.success > th {
	background-color: #dff0d8;
}
.bootstrap-scope .table-hover > tbody > tr > td.success:hover,
.bootstrap-scope .table-hover > tbody > tr > th.success:hover,
.bootstrap-scope .table-hover > tbody > tr.success:hover > td,
.bootstrap-scope .table-hover > tbody > tr:hover > .success,
.bootstrap-scope .table-hover > tbody > tr.success:hover > th {
	background-color: #d0e9c6;
}
.bootstrap-scope .table > thead > tr > td.info,
.bootstrap-scope .table > tbody > tr > td.info,
.bootstrap-scope .table > tfoot > tr > td.info,
.bootstrap-scope .table > thead > tr > th.info,
.bootstrap-scope .table > tbody > tr > th.info,
.bootstrap-scope .table > tfoot > tr > th.info,
.bootstrap-scope .table > thead > tr.info > td,
.bootstrap-scope .table > tbody > tr.info > td,
.bootstrap-scope .table > tfoot > tr.info > td,
.bootstrap-scope .table > thead > tr.info > th,
.bootstrap-scope .table > tbody > tr.info > th,
.bootstrap-scope .table > tfoot > tr.info > th {
	background-color: #d9edf7;
}
.bootstrap-scope .table-hover > tbody > tr > td.info:hover,
.bootstrap-scope .table-hover > tbody > tr > th.info:hover,
.bootstrap-scope .table-hover > tbody > tr.info:hover > td,
.bootstrap-scope .table-hover > tbody > tr:hover > .info,
.bootstrap-scope .table-hover > tbody > tr.info:hover > th {
	background-color: #c4e3f3;
}
.bootstrap-scope .table > thead > tr > td.warning,
.bootstrap-scope .table > tbody > tr > td.warning,
.bootstrap-scope .table > tfoot > tr > td.warning,
.bootstrap-scope .table > thead > tr > th.warning,
.bootstrap-scope .table > tbody > tr > th.warning,
.bootstrap-scope .table > tfoot > tr > th.warning,
.bootstrap-scope .table > thead > tr.warning > td,
.bootstrap-scope .table > tbody > tr.warning > td,
.bootstrap-scope .table > tfoot > tr.warning > td,
.bootstrap-scope .table > thead > tr.warning > th,
.bootstrap-scope .table > tbody > tr.warning > th,
.bootstrap-scope .table > tfoot > tr.warning > th {
	background-color: #fcf8e3;
}
.bootstrap-scope .table-hover > tbody > tr > td.warning:hover,
.bootstrap-scope .table-hover > tbody > tr > th.warning:hover,
.bootstrap-scope .table-hover > tbody > tr.warning:hover > td,
.bootstrap-scope .table-hover > tbody > tr:hover > .warning,
.bootstrap-scope .table-hover > tbody > tr.warning:hover > th {
	background-color: #faf2cc;
}
.bootstrap-scope .table > thead > tr > td.danger,
.bootstrap-scope .table > tbody > tr > td.danger,
.bootstrap-scope .table > tfoot > tr > td.danger,
.bootstrap-scope .table > thead > tr > th.danger,
.bootstrap-scope .table > tbody > tr > th.danger,
.bootstrap-scope .table > tfoot > tr > th.danger,
.bootstrap-scope .table > thead > tr.danger > td,
.bootstrap-scope .table > tbody > tr.danger > td,
.bootstrap-scope .table > tfoot > tr.danger > td,
.bootstrap-scope .table > thead > tr.danger > th,
.bootstrap-scope .table > tbody > tr.danger > th,
.bootstrap-scope .table > tfoot > tr.danger > th {
	background-color: #f2dede;
}
.bootstrap-scope .table-hover > tbody > tr > td.danger:hover,
.bootstrap-scope .table-hover > tbody > tr > th.danger:hover,
.bootstrap-scope .table-hover > tbody > tr.danger:hover > td,
.bootstrap-scope .table-hover > tbody > tr:hover > .danger,
.bootstrap-scope .table-hover > tbody > tr.danger:hover > th {
	background-color: #ebcccc;
}
.bootstrap-scope .table-responsive {
	overflow-x: auto;
	min-height: 0.01%;
}
@media screen and (max-width: 767px) {
	.bootstrap-scope .table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
	}
	.bootstrap-scope .table-responsive > .table {
		margin-bottom: 0;
	}
	.bootstrap-scope .table-responsive > .table > thead > tr > th,
	.bootstrap-scope .table-responsive > .table > tbody > tr > th,
	.bootstrap-scope .table-responsive > .table > tfoot > tr > th,
	.bootstrap-scope .table-responsive > .table > thead > tr > td,
	.bootstrap-scope .table-responsive > .table > tbody > tr > td,
	.bootstrap-scope .table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}
	.bootstrap-scope .table-responsive > .table-bordered {
		border: 0;
	}
	.bootstrap-scope .table-responsive > .table-bordered > thead > tr > th:first-child,
	.bootstrap-scope .table-responsive > .table-bordered > tbody > tr > th:first-child,
	.bootstrap-scope .table-responsive > .table-bordered > tfoot > tr > th:first-child,
	.bootstrap-scope .table-responsive > .table-bordered > thead > tr > td:first-child,
	.bootstrap-scope .table-responsive > .table-bordered > tbody > tr > td:first-child,
	.bootstrap-scope .table-responsive > .table-bordered > tfoot > tr > td:first-child {
		border-left: 0;
	}
	.bootstrap-scope .table-responsive > .table-bordered > thead > tr > th:last-child,
	.bootstrap-scope .table-responsive > .table-bordered > tbody > tr > th:last-child,
	.bootstrap-scope .table-responsive > .table-bordered > tfoot > tr > th:last-child,
	.bootstrap-scope .table-responsive > .table-bordered > thead > tr > td:last-child,
	.bootstrap-scope .table-responsive > .table-bordered > tbody > tr > td:last-child,
	.bootstrap-scope .table-responsive > .table-bordered > tfoot > tr > td:last-child {
		border-right: 0;
	}
	.bootstrap-scope .table-responsive > .table-bordered > tbody > tr:last-child > th,
	.bootstrap-scope .table-responsive > .table-bordered > tfoot > tr:last-child > th,
	.bootstrap-scope .table-responsive > .table-bordered > tbody > tr:last-child > td,
	.bootstrap-scope .table-responsive > .table-bordered > tfoot > tr:last-child > td {
		border-bottom: 0;
	}
}
.bootstrap-scope fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
}
.bootstrap-scope legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 20px;
	font-size: 21px;
	line-height: inherit;
	color: #333333;
	border: 0;
	border-bottom: 1px solid #e5e5e5;
}
.bootstrap-scope label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}
.bootstrap-scope input[type="search"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bootstrap-scope input[type="radio"],
.bootstrap-scope input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
}
.bootstrap-scope input[type="file"] {
	display: block;
}
.bootstrap-scope input[type="range"] {
	display: block;
	width: 100%;
}
.bootstrap-scope select[multiple],
.bootstrap-scope select[size] {
	height: auto;
}
.bootstrap-scope input[type="file"]:focus,
.bootstrap-scope input[type="radio"]:focus,
.bootstrap-scope input[type="checkbox"]:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.bootstrap-scope output {
	display: block;
	padding-top: 7px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
}
.bootstrap-scope .form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.bootstrap-scope .form-control:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.bootstrap-scope .form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}
.bootstrap-scope .form-control:-ms-input-placeholder {
	color: #999;
}
.bootstrap-scope .form-control::-webkit-input-placeholder {
	color: #999;
}
.bootstrap-scope .form-control[disabled],
.bootstrap-scope .form-control[readonly],
fieldset[disabled] .bootstrap-scope .form-control {
	background-color: #eeeeee;
	opacity: 1;
}
.bootstrap-scope .form-control[disabled],
fieldset[disabled] .bootstrap-scope .form-control {
	cursor: not-allowed;
}
textarea.bootstrap-scope .form-control {
	height: auto;
}
.bootstrap-scope input[type="search"] {
	-webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.bootstrap-scope input[type="date"],
	.bootstrap-scope input[type="time"],
	.bootstrap-scope input[type="datetime-local"],
	.bootstrap-scope input[type="month"] {
		line-height: 34px;
	}
	.bootstrap-scope input[type="date"].input-sm,
	.bootstrap-scope input[type="time"].input-sm,
	.bootstrap-scope input[type="datetime-local"].input-sm,
	.bootstrap-scope input[type="month"].input-sm,
	.input-group-sm .bootstrap-scope input[type="date"],
	.input-group-sm .bootstrap-scope input[type="time"],
	.input-group-sm .bootstrap-scope input[type="datetime-local"],
	.input-group-sm .bootstrap-scope input[type="month"] {
		line-height: 30px;
	}
	.bootstrap-scope input[type="date"].input-lg,
	.bootstrap-scope input[type="time"].input-lg,
	.bootstrap-scope input[type="datetime-local"].input-lg,
	.bootstrap-scope input[type="month"].input-lg,
	.input-group-lg .bootstrap-scope input[type="date"],
	.input-group-lg .bootstrap-scope input[type="time"],
	.input-group-lg .bootstrap-scope input[type="datetime-local"],
	.input-group-lg .bootstrap-scope input[type="month"] {
		line-height: 46px;
	}
}
.bootstrap-scope .form-group {
	margin-bottom: 15px;
}
.bootstrap-scope .radio,
.bootstrap-scope .checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
}
.bootstrap-scope .radio label,
.bootstrap-scope .checkbox label {
	min-height: 20px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
}
.bootstrap-scope .radio input[type="radio"],
.bootstrap-scope .radio-inline input[type="radio"],
.bootstrap-scope .checkbox input[type="checkbox"],
.bootstrap-scope .checkbox-inline input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px \9;
}
.bootstrap-scope .radio + .radio,
.bootstrap-scope .checkbox + .checkbox {
	margin-top: -5px;
}
.bootstrap-scope .radio-inline,
.bootstrap-scope .checkbox-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
}
.bootstrap-scope .radio-inline + .radio-inline,
.bootstrap-scope .checkbox-inline + .checkbox-inline {
	margin-top: 0;
	margin-left: 10px;
}
.bootstrap-scope input[type="radio"][disabled],
.bootstrap-scope input[type="checkbox"][disabled],
.bootstrap-scope input[type="radio"].disabled,
.bootstrap-scope input[type="checkbox"].disabled,
fieldset[disabled] .bootstrap-scope input[type="radio"],
fieldset[disabled] .bootstrap-scope input[type="checkbox"] {
	cursor: not-allowed;
}
.bootstrap-scope .radio-inline.disabled,
.bootstrap-scope .checkbox-inline.disabled,
fieldset[disabled] .bootstrap-scope .radio-inline,
fieldset[disabled] .bootstrap-scope .checkbox-inline {
	cursor: not-allowed;
}
.bootstrap-scope .radio.disabled label,
.bootstrap-scope .checkbox.disabled label,
fieldset[disabled] .bootstrap-scope .radio label,
fieldset[disabled] .bootstrap-scope .checkbox label {
	cursor: not-allowed;
}
.bootstrap-scope .form-control-static {
	padding-top: 7px;
	padding-bottom: 7px;
	margin-bottom: 0;
	min-height: 34px;
}
.bootstrap-scope .form-control-static.input-lg,
.bootstrap-scope .form-control-static.input-sm {
	padding-left: 0;
	padding-right: 0;
}
.bootstrap-scope .input-sm {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
select.bootstrap-scope .input-sm {
	height: 30px;
	line-height: 30px;
}
textarea.bootstrap-scope .input-sm,
select[multiple].bootstrap-scope .input-sm {
	height: auto;
}
.bootstrap-scope .form-group-sm .form-control {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
select.bootstrap-scope .form-group-sm .form-control {
	height: 30px;
	line-height: 30px;
}
textarea.bootstrap-scope .form-group-sm .form-control,
select[multiple].bootstrap-scope .form-group-sm .form-control {
	height: auto;
}
.bootstrap-scope .form-group-sm .form-control-static {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	min-height: 32px;
}
.bootstrap-scope .input-lg {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}
select.bootstrap-scope .input-lg {
	height: 46px;
	line-height: 46px;
}
textarea.bootstrap-scope .input-lg,
select[multiple].bootstrap-scope .input-lg {
	height: auto;
}
.bootstrap-scope .form-group-lg .form-control {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}
select.bootstrap-scope .form-group-lg .form-control {
	height: 46px;
	line-height: 46px;
}
textarea.bootstrap-scope .form-group-lg .form-control,
select[multiple].bootstrap-scope .form-group-lg .form-control {
	height: auto;
}
.bootstrap-scope .form-group-lg .form-control-static {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	min-height: 38px;
}
.bootstrap-scope .has-feedback {
	position: relative;
}
.bootstrap-scope .has-feedback .form-control {
	padding-right: 42.5px;
}
.bootstrap-scope .form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	pointer-events: none;
}
.bootstrap-scope .input-lg + .form-control-feedback {
	width: 46px;
	height: 46px;
	line-height: 46px;
}
.bootstrap-scope .input-sm + .form-control-feedback {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
.bootstrap-scope .has-success .help-block,
.bootstrap-scope .has-success .control-label,
.bootstrap-scope .has-success .radio,
.bootstrap-scope .has-success .checkbox,
.bootstrap-scope .has-success .radio-inline,
.bootstrap-scope .has-success .checkbox-inline,
.bootstrap-scope .has-success.radio label,
.bootstrap-scope .has-success.checkbox label,
.bootstrap-scope .has-success.radio-inline label,
.bootstrap-scope .has-success.checkbox-inline label {
	color: #3c763d;
}
.bootstrap-scope .has-success .form-control {
	border-color: #3c763d;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .has-success .form-control:focus {
	border-color: #2b542c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.bootstrap-scope .has-success .input-group-addon {
	color: #3c763d;
	border-color: #3c763d;
	background-color: #dff0d8;
}
.bootstrap-scope .has-success .form-control-feedback {
	color: #3c763d;
}
.bootstrap-scope .has-warning .help-block,
.bootstrap-scope .has-warning .control-label,
.bootstrap-scope .has-warning .radio,
.bootstrap-scope .has-warning .checkbox,
.bootstrap-scope .has-warning .radio-inline,
.bootstrap-scope .has-warning .checkbox-inline,
.bootstrap-scope .has-warning.radio label,
.bootstrap-scope .has-warning.checkbox label,
.bootstrap-scope .has-warning.radio-inline label,
.bootstrap-scope .has-warning.checkbox-inline label {
	color: #8a6d3b;
}
.bootstrap-scope .has-warning .form-control {
	border-color: #8a6d3b;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .has-warning .form-control:focus {
	border-color: #66512c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.bootstrap-scope .has-warning .input-group-addon {
	color: #8a6d3b;
	border-color: #8a6d3b;
	background-color: #fcf8e3;
}
.bootstrap-scope .has-warning .form-control-feedback {
	color: #8a6d3b;
}
.bootstrap-scope .has-error .help-block,
.bootstrap-scope .has-error .control-label,
.bootstrap-scope .has-error .radio,
.bootstrap-scope .has-error .checkbox,
.bootstrap-scope .has-error .radio-inline,
.bootstrap-scope .has-error .checkbox-inline,
.bootstrap-scope .has-error.radio label,
.bootstrap-scope .has-error.checkbox label,
.bootstrap-scope .has-error.radio-inline label,
.bootstrap-scope .has-error.checkbox-inline label {
	color: #a94442;
}
.bootstrap-scope .has-error .form-control {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bootstrap-scope .has-error .form-control:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.bootstrap-scope .has-error .input-group-addon {
	color: #a94442;
	border-color: #a94442;
	background-color: #f2dede;
}
.bootstrap-scope .has-error .form-control-feedback {
	color: #a94442;
}
.bootstrap-scope .has-feedback label ~ .form-control-feedback {
	top: 25px;
}
.bootstrap-scope .has-feedback label.sr-only ~ .form-control-feedback {
	top: 0;
}
.bootstrap-scope .help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: #737373;
}
@media (min-width: 768px) {
	.bootstrap-scope .form-inline .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.bootstrap-scope .form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.bootstrap-scope .form-inline .form-control-static {
		display: inline-block;
	}
	.bootstrap-scope .form-inline .input-group {
		display: inline-table;
		vertical-align: middle;
	}
	.bootstrap-scope .form-inline .input-group .input-group-addon,
	.bootstrap-scope .form-inline .input-group .input-group-btn,
	.bootstrap-scope .form-inline .input-group .form-control {
		width: auto;
	}
	.bootstrap-scope .form-inline .input-group > .form-control {
		width: 100%;
	}
	.bootstrap-scope .form-inline .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}
	.bootstrap-scope .form-inline .radio,
	.bootstrap-scope .form-inline .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.bootstrap-scope .form-inline .radio label,
	.bootstrap-scope .form-inline .checkbox label {
		padding-left: 0;
	}
	.bootstrap-scope .form-inline .radio input[type="radio"],
	.bootstrap-scope .form-inline .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	}
	.bootstrap-scope .form-inline .has-feedback .form-control-feedback {
		top: 0;
	}
}
.bootstrap-scope .form-horizontal .radio,
.bootstrap-scope .form-horizontal .checkbox,
.bootstrap-scope .form-horizontal .radio-inline,
.bootstrap-scope .form-horizontal .checkbox-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
}
.bootstrap-scope .form-horizontal .radio,
.bootstrap-scope .form-horizontal .checkbox {
	min-height: 27px;
}
.bootstrap-scope .form-horizontal .form-group {
	margin-left: -15px;
	margin-right: -15px;
}
@media (min-width: 768px) {
	.bootstrap-scope .form-horizontal .control-label {
		text-align: right;
		margin-bottom: 0;
		padding-top: 7px;
	}
}
.bootstrap-scope .form-horizontal .has-feedback .form-control-feedback {
	right: 15px;
}
@media (min-width: 768px) {
	.bootstrap-scope .form-horizontal .form-group-lg .control-label {
		padding-top: 14.333333px;
	}
}
@media (min-width: 768px) {
	.bootstrap-scope .form-horizontal .form-group-sm .control-label {
		padding-top: 6px;
	}
}
.bootstrap-scope .btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.bootstrap-scope .btn:focus,
.bootstrap-scope .btn:active:focus,
.bootstrap-scope .btn.active:focus,
.bootstrap-scope .btn.focus,
.bootstrap-scope .btn:active.focus,
.bootstrap-scope .btn.active.focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.bootstrap-scope .btn:hover,
.bootstrap-scope .btn:focus,
.bootstrap-scope .btn.focus {
	color: #333;
	text-decoration: none;
}
.bootstrap-scope .btn:active,
.bootstrap-scope .btn.active {
	outline: 0;
	background-image: none;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.bootstrap-scope .btn.disabled,
.bootstrap-scope .btn[disabled],
fieldset[disabled] .bootstrap-scope .btn {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}
.bootstrap-scope .btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}
.bootstrap-scope .btn-default:hover,
.bootstrap-scope .btn-default:focus,
.bootstrap-scope .btn-default.focus,
.bootstrap-scope .btn-default:active,
.bootstrap-scope .btn-default.active,
.open > .dropdown-toggle.bootstrap-scope .btn-default {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad;
}
.bootstrap-scope .btn-default:active,
.bootstrap-scope .btn-default.active,
.open > .dropdown-toggle.bootstrap-scope .btn-default {
	background-image: none;
}
.bootstrap-scope .btn-default.disabled,
.bootstrap-scope .btn-default[disabled],
fieldset[disabled] .bootstrap-scope .btn-default,
.bootstrap-scope .btn-default.disabled:hover,
.bootstrap-scope .btn-default[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-default:hover,
.bootstrap-scope .btn-default.disabled:focus,
.bootstrap-scope .btn-default[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-default:focus,
.bootstrap-scope .btn-default.disabled.focus,
.bootstrap-scope .btn-default[disabled].focus,
fieldset[disabled] .bootstrap-scope .btn-default.focus,
.bootstrap-scope .btn-default.disabled:active,
.bootstrap-scope .btn-default[disabled]:active,
fieldset[disabled] .bootstrap-scope .btn-default:active,
.bootstrap-scope .btn-default.disabled.active,
.bootstrap-scope .btn-default[disabled].active,
fieldset[disabled] .bootstrap-scope .btn-default.active {
	background-color: #fff;
	border-color: #ccc;
}
.bootstrap-scope .btn-default .badge {
	color: #fff;
	background-color: #333;
}
.bootstrap-scope .btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
}
.bootstrap-scope .btn-primary:hover,
.bootstrap-scope .btn-primary:focus,
.bootstrap-scope .btn-primary.focus,
.bootstrap-scope .btn-primary:active,
.bootstrap-scope .btn-primary.active,
.open > .dropdown-toggle.bootstrap-scope .btn-primary {
	color: #fff;
	background-color: #286090;
	border-color: #204d74;
}
.bootstrap-scope .btn-primary:active,
.bootstrap-scope .btn-primary.active,
.open > .dropdown-toggle.bootstrap-scope .btn-primary {
	background-image: none;
}
.bootstrap-scope .btn-primary.disabled,
.bootstrap-scope .btn-primary[disabled],
fieldset[disabled] .bootstrap-scope .btn-primary,
.bootstrap-scope .btn-primary.disabled:hover,
.bootstrap-scope .btn-primary[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-primary:hover,
.bootstrap-scope .btn-primary.disabled:focus,
.bootstrap-scope .btn-primary[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-primary:focus,
.bootstrap-scope .btn-primary.disabled.focus,
.bootstrap-scope .btn-primary[disabled].focus,
fieldset[disabled] .bootstrap-scope .btn-primary.focus,
.bootstrap-scope .btn-primary.disabled:active,
.bootstrap-scope .btn-primary[disabled]:active,
fieldset[disabled] .bootstrap-scope .btn-primary:active,
.bootstrap-scope .btn-primary.disabled.active,
.bootstrap-scope .btn-primary[disabled].active,
fieldset[disabled] .bootstrap-scope .btn-primary.active {
	background-color: #337ab7;
	border-color: #2e6da4;
}
.bootstrap-scope .btn-primary .badge {
	color: #337ab7;
	background-color: #fff;
}
.bootstrap-scope .btn-success {
	color: #fff;
	background-color: #5cb85c;
	border-color: #4cae4c;
}
.bootstrap-scope .btn-success:hover,
.bootstrap-scope .btn-success:focus,
.bootstrap-scope .btn-success.focus,
.bootstrap-scope .btn-success:active,
.bootstrap-scope .btn-success.active,
.open > .dropdown-toggle.bootstrap-scope .btn-success {
	color: #fff;
	background-color: #449d44;
	border-color: #398439;
}
.bootstrap-scope .btn-success:active,
.bootstrap-scope .btn-success.active,
.open > .dropdown-toggle.bootstrap-scope .btn-success {
	background-image: none;
}
.bootstrap-scope .btn-success.disabled,
.bootstrap-scope .btn-success[disabled],
fieldset[disabled] .bootstrap-scope .btn-success,
.bootstrap-scope .btn-success.disabled:hover,
.bootstrap-scope .btn-success[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-success:hover,
.bootstrap-scope .btn-success.disabled:focus,
.bootstrap-scope .btn-success[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-success:focus,
.bootstrap-scope .btn-success.disabled.focus,
.bootstrap-scope .btn-success[disabled].focus,
fieldset[disabled] .bootstrap-scope .btn-success.focus,
.bootstrap-scope .btn-success.disabled:active,
.bootstrap-scope .btn-success[disabled]:active,
fieldset[disabled] .bootstrap-scope .btn-success:active,
.bootstrap-scope .btn-success.disabled.active,
.bootstrap-scope .btn-success[disabled].active,
fieldset[disabled] .bootstrap-scope .btn-success.active {
	background-color: #5cb85c;
	border-color: #4cae4c;
}
.bootstrap-scope .btn-success .badge {
	color: #5cb85c;
	background-color: #fff;
}
.bootstrap-scope .btn-info {
	color: #fff;
	background-color: #5bc0de;
	border-color: #46b8da;
}
.bootstrap-scope .btn-info:hover,
.bootstrap-scope .btn-info:focus,
.bootstrap-scope .btn-info.focus,
.bootstrap-scope .btn-info:active,
.bootstrap-scope .btn-info.active,
.open > .dropdown-toggle.bootstrap-scope .btn-info {
	color: #fff;
	background-color: #31b0d5;
	border-color: #269abc;
}
.bootstrap-scope .btn-info:active,
.bootstrap-scope .btn-info.active,
.open > .dropdown-toggle.bootstrap-scope .btn-info {
	background-image: none;
}
.bootstrap-scope .btn-info.disabled,
.bootstrap-scope .btn-info[disabled],
fieldset[disabled] .bootstrap-scope .btn-info,
.bootstrap-scope .btn-info.disabled:hover,
.bootstrap-scope .btn-info[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-info:hover,
.bootstrap-scope .btn-info.disabled:focus,
.bootstrap-scope .btn-info[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-info:focus,
.bootstrap-scope .btn-info.disabled.focus,
.bootstrap-scope .btn-info[disabled].focus,
fieldset[disabled] .bootstrap-scope .btn-info.focus,
.bootstrap-scope .btn-info.disabled:active,
.bootstrap-scope .btn-info[disabled]:active,
fieldset[disabled] .bootstrap-scope .btn-info:active,
.bootstrap-scope .btn-info.disabled.active,
.bootstrap-scope .btn-info[disabled].active,
fieldset[disabled] .bootstrap-scope .btn-info.active {
	background-color: #5bc0de;
	border-color: #46b8da;
}
.bootstrap-scope .btn-info .badge {
	color: #5bc0de;
	background-color: #fff;
}
.bootstrap-scope .btn-warning {
	color: #fff;
	background-color: #f0ad4e;
	border-color: #eea236;
}
.bootstrap-scope .btn-warning:hover,
.bootstrap-scope .btn-warning:focus,
.bootstrap-scope .btn-warning.focus,
.bootstrap-scope .btn-warning:active,
.bootstrap-scope .btn-warning.active,
.open > .dropdown-toggle.bootstrap-scope .btn-warning {
	color: #fff;
	background-color: #ec971f;
	border-color: #d58512;
}
.bootstrap-scope .btn-warning:active,
.bootstrap-scope .btn-warning.active,
.open > .dropdown-toggle.bootstrap-scope .btn-warning {
	background-image: none;
}
.bootstrap-scope .btn-warning.disabled,
.bootstrap-scope .btn-warning[disabled],
fieldset[disabled] .bootstrap-scope .btn-warning,
.bootstrap-scope .btn-warning.disabled:hover,
.bootstrap-scope .btn-warning[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-warning:hover,
.bootstrap-scope .btn-warning.disabled:focus,
.bootstrap-scope .btn-warning[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-warning:focus,
.bootstrap-scope .btn-warning.disabled.focus,
.bootstrap-scope .btn-warning[disabled].focus,
fieldset[disabled] .bootstrap-scope .btn-warning.focus,
.bootstrap-scope .btn-warning.disabled:active,
.bootstrap-scope .btn-warning[disabled]:active,
fieldset[disabled] .bootstrap-scope .btn-warning:active,
.bootstrap-scope .btn-warning.disabled.active,
.bootstrap-scope .btn-warning[disabled].active,
fieldset[disabled] .bootstrap-scope .btn-warning.active {
	background-color: #f0ad4e;
	border-color: #eea236;
}
.bootstrap-scope .btn-warning .badge {
	color: #f0ad4e;
	background-color: #fff;
}
.bootstrap-scope .btn-danger {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a;
}
.bootstrap-scope .btn-danger:hover,
.bootstrap-scope .btn-danger:focus,
.bootstrap-scope .btn-danger.focus,
.bootstrap-scope .btn-danger:active,
.bootstrap-scope .btn-danger.active,
.open > .dropdown-toggle.bootstrap-scope .btn-danger {
	color: #fff;
	background-color: #c9302c;
	border-color: #ac2925;
}
.bootstrap-scope .btn-danger:active,
.bootstrap-scope .btn-danger.active,
.open > .dropdown-toggle.bootstrap-scope .btn-danger {
	background-image: none;
}
.bootstrap-scope .btn-danger.disabled,
.bootstrap-scope .btn-danger[disabled],
fieldset[disabled] .bootstrap-scope .btn-danger,
.bootstrap-scope .btn-danger.disabled:hover,
.bootstrap-scope .btn-danger[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-danger:hover,
.bootstrap-scope .btn-danger.disabled:focus,
.bootstrap-scope .btn-danger[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-danger:focus,
.bootstrap-scope .btn-danger.disabled.focus,
.bootstrap-scope .btn-danger[disabled].focus,
fieldset[disabled] .bootstrap-scope .btn-danger.focus,
.bootstrap-scope .btn-danger.disabled:active,
.bootstrap-scope .btn-danger[disabled]:active,
fieldset[disabled] .bootstrap-scope .btn-danger:active,
.bootstrap-scope .btn-danger.disabled.active,
.bootstrap-scope .btn-danger[disabled].active,
fieldset[disabled] .bootstrap-scope .btn-danger.active {
	background-color: #d9534f;
	border-color: #d43f3a;
}
.bootstrap-scope .btn-danger .badge {
	color: #d9534f;
	background-color: #fff;
}
.bootstrap-scope .btn-link {
	color: #337ab7;
	font-weight: normal;
	border-radius: 0;
}
.bootstrap-scope .btn-link,
.bootstrap-scope .btn-link:active,
.bootstrap-scope .btn-link.active,
.bootstrap-scope .btn-link[disabled],
fieldset[disabled] .bootstrap-scope .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.bootstrap-scope .btn-link,
.bootstrap-scope .btn-link:hover,
.bootstrap-scope .btn-link:focus,
.bootstrap-scope .btn-link:active {
	border-color: transparent;
}
.bootstrap-scope .btn-link:hover,
.bootstrap-scope .btn-link:focus {
	color: #23527c;
	text-decoration: underline;
	background-color: transparent;
}
.bootstrap-scope .btn-link[disabled]:hover,
fieldset[disabled] .bootstrap-scope .btn-link:hover,
.bootstrap-scope .btn-link[disabled]:focus,
fieldset[disabled] .bootstrap-scope .btn-link:focus {
	color: #777777;
	text-decoration: none;
}
.bootstrap-scope .btn-lg {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}
.bootstrap-scope .btn-sm {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
.bootstrap-scope .btn-xs {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
.bootstrap-scope .btn-block {
	display: block;
	width: 100%;
}
.bootstrap-scope .btn-block + .btn-block {
	margin-top: 5px;
}
.bootstrap-scope input[type="submit"].btn-block,
.bootstrap-scope input[type="reset"].btn-block,
.bootstrap-scope input[type="button"].btn-block {
	width: 100%;
}
.bootstrap-scope .fade {
	opacity: 0;
	-webkit-transition: opacity 0.15s linear;
	-o-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear;
}
.bootstrap-scope .fade.in {
	opacity: 1;
}
.bootstrap-scope .collapse {
	display: none;
}
.bootstrap-scope .collapse.in {
	display: block;
}
tr.bootstrap-scope .collapse.in {
	display: table-row;
}
tbody.bootstrap-scope .collapse.in {
	display: table-row-group;
}
.bootstrap-scope .collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-property: height, visibility;
	transition-property: height, visibility;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
}
.bootstrap-scope .caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}
.bootstrap-scope .dropup,
.bootstrap-scope .dropdown {
	position: relative;
}
.bootstrap-scope .dropdown-toggle:focus {
	outline: 0;
}
.bootstrap-scope .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
}
.bootstrap-scope .dropdown-menu.pull-right {
	right: 0;
	left: auto;
}
.bootstrap-scope .dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}
.bootstrap-scope .dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857143;
	color: #333333;
	white-space: nowrap;
}
.bootstrap-scope .dropdown-menu > li > a:hover,
.bootstrap-scope .dropdown-menu > li > a:focus {
	text-decoration: none;
	color: #262626;
	background-color: #f5f5f5;
}
.bootstrap-scope .dropdown-menu > .active > a,
.bootstrap-scope .dropdown-menu > .active > a:hover,
.bootstrap-scope .dropdown-menu > .active > a:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	background-color: #337ab7;
}
.bootstrap-scope .dropdown-menu > .disabled > a,
.bootstrap-scope .dropdown-menu > .disabled > a:hover,
.bootstrap-scope .dropdown-menu > .disabled > a:focus {
	color: #777777;
}
.bootstrap-scope .dropdown-menu > .disabled > a:hover,
.bootstrap-scope .dropdown-menu > .disabled > a:focus {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	cursor: not-allowed;
}
.bootstrap-scope .open > .dropdown-menu {
	display: block;
}
.bootstrap-scope .open > a {
	outline: 0;
}
.bootstrap-scope .dropdown-menu-right {
	left: auto;
	right: 0;
}
.bootstrap-scope .dropdown-menu-left {
	left: 0;
	right: auto;
}
.bootstrap-scope .dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #777777;
	white-space: nowrap;
}
.bootstrap-scope .dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990;
}
.bootstrap-scope .pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}
.bootstrap-scope .dropup .caret,
.bootstrap-scope .navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px solid;
	content: "";
}
.bootstrap-scope .dropup .dropdown-menu,
.bootstrap-scope .navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 2px;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-right .dropdown-menu {
		left: auto;
		right: 0;
	}
	.bootstrap-scope .navbar-right .dropdown-menu-left {
		left: 0;
		right: auto;
	}
}
.bootstrap-scope .btn-group,
.bootstrap-scope .btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}
.bootstrap-scope .btn-group > .btn,
.bootstrap-scope .btn-group-vertical > .btn {
	position: relative;
	float: left;
}
.bootstrap-scope .btn-group > .btn:hover,
.bootstrap-scope .btn-group-vertical > .btn:hover,
.bootstrap-scope .btn-group > .btn:focus,
.bootstrap-scope .btn-group-vertical > .btn:focus,
.bootstrap-scope .btn-group > .btn:active,
.bootstrap-scope .btn-group-vertical > .btn:active,
.bootstrap-scope .btn-group > .btn.active,
.bootstrap-scope .btn-group-vertical > .btn.active {
	z-index: 2;
}
.bootstrap-scope .btn-group .btn + .btn,
.bootstrap-scope .btn-group .btn + .btn-group,
.bootstrap-scope .btn-group .btn-group + .btn,
.bootstrap-scope .btn-group .btn-group + .btn-group {
	margin-left: -1px;
}
.bootstrap-scope .btn-toolbar {
	margin-left: -5px;
}
.bootstrap-scope .btn-toolbar .btn-group,
.bootstrap-scope .btn-toolbar .input-group {
	float: left;
}
.bootstrap-scope .btn-toolbar > .btn,
.bootstrap-scope .btn-toolbar > .btn-group,
.bootstrap-scope .btn-toolbar > .input-group {
	margin-left: 5px;
}
.bootstrap-scope .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0;
}
.bootstrap-scope .btn-group > .btn:first-child {
	margin-left: 0;
}
.bootstrap-scope .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.bootstrap-scope .btn-group > .btn:last-child:not(:first-child),
.bootstrap-scope .btn-group > .dropdown-toggle:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .btn-group > .btn-group {
	float: left;
}
.bootstrap-scope .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}
.bootstrap-scope .btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.bootstrap-scope .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.bootstrap-scope .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .btn-group .dropdown-toggle:active,
.bootstrap-scope .btn-group.open .dropdown-toggle {
	outline: 0;
}
.bootstrap-scope .btn-group > .btn + .dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
}
.bootstrap-scope .btn-group > .btn-lg + .dropdown-toggle {
	padding-left: 12px;
	padding-right: 12px;
}
.bootstrap-scope .btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.bootstrap-scope .btn-group.open .dropdown-toggle.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.bootstrap-scope .btn .caret {
	margin-left: 0;
}
.bootstrap-scope .btn-lg .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0;
}
.bootstrap-scope .dropup .btn-lg .caret {
	border-width: 0 5px 5px;
}
.bootstrap-scope .btn-group-vertical > .btn,
.bootstrap-scope .btn-group-vertical > .btn-group,
.bootstrap-scope .btn-group-vertical > .btn-group > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
}
.bootstrap-scope .btn-group-vertical > .btn-group > .btn {
	float: none;
}
.bootstrap-scope .btn-group-vertical > .btn + .btn,
.bootstrap-scope .btn-group-vertical > .btn + .btn-group,
.bootstrap-scope .btn-group-vertical > .btn-group + .btn,
.bootstrap-scope .btn-group-vertical > .btn-group + .btn-group {
	margin-top: -1px;
	margin-left: 0;
}
.bootstrap-scope .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
	border-radius: 0;
}
.bootstrap-scope .btn-group-vertical > .btn:first-child:not(:last-child) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.bootstrap-scope .btn-group-vertical > .btn:last-child:not(:first-child) {
	border-bottom-left-radius: 4px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}
.bootstrap-scope .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.bootstrap-scope .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.bootstrap-scope .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
}
.bootstrap-scope .btn-group-justified > .btn,
.bootstrap-scope .btn-group-justified > .btn-group {
	float: none;
	display: table-cell;
	width: 1%;
}
.bootstrap-scope .btn-group-justified > .btn-group .btn {
	width: 100%;
}
.bootstrap-scope .btn-group-justified > .btn-group .dropdown-menu {
	left: auto;
}
.bootstrap-scope [data-toggle="buttons"] > .btn input[type="radio"],
.bootstrap-scope [data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
.bootstrap-scope [data-toggle="buttons"] > .btn input[type="checkbox"],
.bootstrap-scope [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}
.bootstrap-scope .input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}
.bootstrap-scope .input-group[class*="col-"] {
	float: none;
	padding-left: 0;
	padding-right: 0;
}
.bootstrap-scope .input-group .form-control {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0;
}
.bootstrap-scope .input-group-lg > .form-control,
.bootstrap-scope .input-group-lg > .input-group-addon,
.bootstrap-scope .input-group-lg > .input-group-btn > .btn {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}
select.bootstrap-scope .input-group-lg > .form-control,
select.bootstrap-scope .input-group-lg > .input-group-addon,
select.bootstrap-scope .input-group-lg > .input-group-btn > .btn {
	height: 46px;
	line-height: 46px;
}
textarea.bootstrap-scope .input-group-lg > .form-control,
textarea.bootstrap-scope .input-group-lg > .input-group-addon,
textarea.bootstrap-scope .input-group-lg > .input-group-btn > .btn,
select[multiple].bootstrap-scope .input-group-lg > .form-control,
select[multiple].bootstrap-scope .input-group-lg > .input-group-addon,
select[multiple].bootstrap-scope .input-group-lg > .input-group-btn > .btn {
	height: auto;
}
.bootstrap-scope .input-group-sm > .form-control,
.bootstrap-scope .input-group-sm > .input-group-addon,
.bootstrap-scope .input-group-sm > .input-group-btn > .btn {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
select.bootstrap-scope .input-group-sm > .form-control,
select.bootstrap-scope .input-group-sm > .input-group-addon,
select.bootstrap-scope .input-group-sm > .input-group-btn > .btn {
	height: 30px;
	line-height: 30px;
}
textarea.bootstrap-scope .input-group-sm > .form-control,
textarea.bootstrap-scope .input-group-sm > .input-group-addon,
textarea.bootstrap-scope .input-group-sm > .input-group-btn > .btn,
select[multiple].bootstrap-scope .input-group-sm > .form-control,
select[multiple].bootstrap-scope .input-group-sm > .input-group-addon,
select[multiple].bootstrap-scope .input-group-sm > .input-group-btn > .btn {
	height: auto;
}
.bootstrap-scope .input-group-addon,
.bootstrap-scope .input-group-btn,
.bootstrap-scope .input-group .form-control {
	display: table-cell;
}
.bootstrap-scope .input-group-addon:not(:first-child):not(:last-child),
.bootstrap-scope .input-group-btn:not(:first-child):not(:last-child),
.bootstrap-scope .input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0;
}
.bootstrap-scope .input-group-addon,
.bootstrap-scope .input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}
.bootstrap-scope .input-group-addon {
	padding: 6px 12px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #555555;
	text-align: center;
	background-color: #eeeeee;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.bootstrap-scope .input-group-addon.input-sm {
	padding: 5px 10px;
	font-size: 12px;
	border-radius: 3px;
}
.bootstrap-scope .input-group-addon.input-lg {
	padding: 10px 16px;
	font-size: 18px;
	border-radius: 6px;
}
.bootstrap-scope .input-group-addon input[type="radio"],
.bootstrap-scope .input-group-addon input[type="checkbox"] {
	margin-top: 0;
}
.bootstrap-scope .input-group .form-control:first-child,
.bootstrap-scope .input-group-addon:first-child,
.bootstrap-scope .input-group-btn:first-child > .btn,
.bootstrap-scope .input-group-btn:first-child > .btn-group > .btn,
.bootstrap-scope .input-group-btn:first-child > .dropdown-toggle,
.bootstrap-scope .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-scope .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.bootstrap-scope .input-group-addon:first-child {
	border-right: 0;
}
.bootstrap-scope .input-group .form-control:last-child,
.bootstrap-scope .input-group-addon:last-child,
.bootstrap-scope .input-group-btn:last-child > .btn,
.bootstrap-scope .input-group-btn:last-child > .btn-group > .btn,
.bootstrap-scope .input-group-btn:last-child > .dropdown-toggle,
.bootstrap-scope .input-group-btn:first-child > .btn:not(:first-child),
.bootstrap-scope .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .input-group-addon:last-child {
	border-left: 0;
}
.bootstrap-scope .input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
}
.bootstrap-scope .input-group-btn > .btn {
	position: relative;
}
.bootstrap-scope .input-group-btn > .btn + .btn {
	margin-left: -1px;
}
.bootstrap-scope .input-group-btn > .btn:hover,
.bootstrap-scope .input-group-btn > .btn:focus,
.bootstrap-scope .input-group-btn > .btn:active {
	z-index: 2;
}
.bootstrap-scope .input-group-btn:first-child > .btn,
.bootstrap-scope .input-group-btn:first-child > .btn-group {
	margin-right: -1px;
}
.bootstrap-scope .input-group-btn:last-child > .btn,
.bootstrap-scope .input-group-btn:last-child > .btn-group {
	margin-left: -1px;
}
.bootstrap-scope .nav {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}
.bootstrap-scope .nav > li {
	position: relative;
	display: block;
}
.bootstrap-scope .nav > li > a {
	position: relative;
	display: block;
	padding: 10px 15px;
}
.bootstrap-scope .nav > li > a:hover,
.bootstrap-scope .nav > li > a:focus {
	text-decoration: none;
	background-color: #eeeeee;
}
.bootstrap-scope .nav > li.disabled > a {
	color: #777777;
}
.bootstrap-scope .nav > li.disabled > a:hover,
.bootstrap-scope .nav > li.disabled > a:focus {
	color: #777777;
	text-decoration: none;
	background-color: transparent;
	cursor: not-allowed;
}
.bootstrap-scope .nav .open > a,
.bootstrap-scope .nav .open > a:hover,
.bootstrap-scope .nav .open > a:focus {
	background-color: #eeeeee;
	border-color: #337ab7;
}
.bootstrap-scope .nav .nav-divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}
.bootstrap-scope .nav > li > a > img {
	max-width: none;
}
.bootstrap-scope .nav-tabs {
	border-bottom: 1px solid #ddd;
}
.bootstrap-scope .nav-tabs > li {
	float: left;
	margin-bottom: -1px;
}
.bootstrap-scope .nav-tabs > li > a {
	margin-right: 2px;
	line-height: 1.42857143;
	border: 1px solid transparent;
	border-radius: 4px 4px 0 0;
}
.bootstrap-scope .nav-tabs > li > a:hover {
	border-color: #eeeeee #eeeeee #ddd;
}
.bootstrap-scope .nav-tabs > li.active > a,
.bootstrap-scope .nav-tabs > li.active > a:hover,
.bootstrap-scope .nav-tabs > li.active > a:focus {
	color: #555555;
	background-color: #fff;
	border: 1px solid #ddd;
	border-bottom-color: transparent;
	cursor: default;
}
.bootstrap-scope .nav-tabs.nav-justified {
	width: 100%;
	border-bottom: 0;
}
.bootstrap-scope .nav-tabs.nav-justified > li {
	float: none;
}
.bootstrap-scope .nav-tabs.nav-justified > li > a {
	text-align: center;
	margin-bottom: 5px;
}
.bootstrap-scope .nav-tabs.nav-justified > .dropdown .dropdown-menu {
	top: auto;
	left: auto;
}
@media (min-width: 768px) {
	.bootstrap-scope .nav-tabs.nav-justified > li {
		display: table-cell;
		width: 1%;
	}
	.bootstrap-scope .nav-tabs.nav-justified > li > a {
		margin-bottom: 0;
	}
}
.bootstrap-scope .nav-tabs.nav-justified > li > a {
	margin-right: 0;
	border-radius: 4px;
}
.bootstrap-scope .nav-tabs.nav-justified > .active > a,
.bootstrap-scope .nav-tabs.nav-justified > .active > a:hover,
.bootstrap-scope .nav-tabs.nav-justified > .active > a:focus {
	border: 1px solid #ddd;
}
@media (min-width: 768px) {
	.bootstrap-scope .nav-tabs.nav-justified > li > a {
		border-bottom: 1px solid #ddd;
		border-radius: 4px 4px 0 0;
	}
	.bootstrap-scope .nav-tabs.nav-justified > .active > a,
	.bootstrap-scope .nav-tabs.nav-justified > .active > a:hover,
	.bootstrap-scope .nav-tabs.nav-justified > .active > a:focus {
		border-bottom-color: #fff;
	}
}
.bootstrap-scope .nav-pills > li {
	float: left;
}
.bootstrap-scope .nav-pills > li > a {
	border-radius: 4px;
}
.bootstrap-scope .nav-pills > li + li {
	margin-left: 2px;
}
.bootstrap-scope .nav-pills > li.active > a,
.bootstrap-scope .nav-pills > li.active > a:hover,
.bootstrap-scope .nav-pills > li.active > a:focus {
	color: #fff;
	background-color: #337ab7;
}
.bootstrap-scope .nav-stacked > li {
	float: none;
}
.bootstrap-scope .nav-stacked > li + li {
	margin-top: 2px;
	margin-left: 0;
}
.bootstrap-scope .nav-justified {
	width: 100%;
}
.bootstrap-scope .nav-justified > li {
	float: none;
}
.bootstrap-scope .nav-justified > li > a {
	text-align: center;
	margin-bottom: 5px;
}
.bootstrap-scope .nav-justified > .dropdown .dropdown-menu {
	top: auto;
	left: auto;
}
@media (min-width: 768px) {
	.bootstrap-scope .nav-justified > li {
		display: table-cell;
		width: 1%;
	}
	.bootstrap-scope .nav-justified > li > a {
		margin-bottom: 0;
	}
}
.bootstrap-scope .nav-tabs-justified {
	border-bottom: 0;
}
.bootstrap-scope .nav-tabs-justified > li > a {
	margin-right: 0;
	border-radius: 4px;
}
.bootstrap-scope .nav-tabs-justified > .active > a,
.bootstrap-scope .nav-tabs-justified > .active > a:hover,
.bootstrap-scope .nav-tabs-justified > .active > a:focus {
	border: 1px solid #ddd;
}
@media (min-width: 768px) {
	.bootstrap-scope .nav-tabs-justified > li > a {
		border-bottom: 1px solid #ddd;
		border-radius: 4px 4px 0 0;
	}
	.bootstrap-scope .nav-tabs-justified > .active > a,
	.bootstrap-scope .nav-tabs-justified > .active > a:hover,
	.bootstrap-scope .nav-tabs-justified > .active > a:focus {
		border-bottom-color: #fff;
	}
}
.bootstrap-scope .tab-content > .tab-pane {
	display: none;
}
.bootstrap-scope .tab-content > .active {
	display: block;
}
.bootstrap-scope .nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .navbar {
	position: relative;
	min-height: 50px;
	margin-bottom: 20px;
	border: 1px solid transparent;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar {
		border-radius: 4px;
	}
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-header {
		float: left;
	}
}
.bootstrap-scope .navbar-collapse {
	overflow-x: visible;
	padding-right: 15px;
	padding-left: 15px;
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	-webkit-overflow-scrolling: touch;
}
.bootstrap-scope .navbar-collapse.in {
	overflow-y: auto;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-collapse {
		width: auto;
		border-top: 0;
		box-shadow: none;
	}
	.bootstrap-scope .navbar-collapse.collapse {
		display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important;
	}
	.bootstrap-scope .navbar-collapse.in {
		overflow-y: visible;
	}
	.navbar-fixed-top .bootstrap-scope .navbar-collapse,
	.navbar-static-top .bootstrap-scope .navbar-collapse,
	.navbar-fixed-bottom .bootstrap-scope .navbar-collapse {
		padding-left: 0;
		padding-right: 0;
	}
}
.bootstrap-scope .navbar-fixed-top .navbar-collapse,
.bootstrap-scope .navbar-fixed-bottom .navbar-collapse {
	max-height: 340px;
}
@media (max-device-width: 480px) and (orientation: landscape) {
	.bootstrap-scope .navbar-fixed-top .navbar-collapse,
	.bootstrap-scope .navbar-fixed-bottom .navbar-collapse {
		max-height: 200px;
	}
}
.bootstrap-scope .container > .navbar-header,
.bootstrap-scope .container-fluid > .navbar-header,
.bootstrap-scope .container > .navbar-collapse,
.bootstrap-scope .container-fluid > .navbar-collapse {
	margin-right: -15px;
	margin-left: -15px;
}
@media (min-width: 768px) {
	.bootstrap-scope .container > .navbar-header,
	.bootstrap-scope .container-fluid > .navbar-header,
	.bootstrap-scope .container > .navbar-collapse,
	.bootstrap-scope .container-fluid > .navbar-collapse {
		margin-right: 0;
		margin-left: 0;
	}
}
.bootstrap-scope .navbar-static-top {
	z-index: 1000;
	border-width: 0 0 1px;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-static-top {
		border-radius: 0;
	}
}
.bootstrap-scope .navbar-fixed-top,
.bootstrap-scope .navbar-fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-fixed-top,
	.bootstrap-scope .navbar-fixed-bottom {
		border-radius: 0;
	}
}
.bootstrap-scope .navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px;
}
.bootstrap-scope .navbar-fixed-bottom {
	bottom: 0;
	margin-bottom: 0;
	border-width: 1px 0 0;
}
.bootstrap-scope .navbar-brand {
	float: left;
	padding: 15px 15px;
	font-size: 18px;
	line-height: 20px;
	height: 50px;
}
.bootstrap-scope .navbar-brand:hover,
.bootstrap-scope .navbar-brand:focus {
	text-decoration: none;
}
.bootstrap-scope .navbar-brand > img {
	display: block;
}
@media (min-width: 768px) {
	.navbar > .container .bootstrap-scope .navbar-brand,
	.navbar > .container-fluid .bootstrap-scope .navbar-brand {
		margin-left: -15px;
	}
}
.bootstrap-scope .navbar-toggle {
	position: relative;
	float: right;
	margin-right: 15px;
	padding: 9px 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
}
.bootstrap-scope .navbar-toggle:focus {
	outline: 0;
}
.bootstrap-scope .navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px;
}
.bootstrap-scope .navbar-toggle .icon-bar + .icon-bar {
	margin-top: 4px;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-toggle {
		display: none;
	}
}
.bootstrap-scope .navbar-nav {
	margin: 7.5px -15px;
}
.bootstrap-scope .navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 20px;
}
@media (max-width: 767px) {
	.bootstrap-scope .navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		box-shadow: none;
	}
	.bootstrap-scope .navbar-nav .open .dropdown-menu > li > a,
	.bootstrap-scope .navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px;
	}
	.bootstrap-scope .navbar-nav .open .dropdown-menu > li > a {
		line-height: 20px;
	}
	.bootstrap-scope .navbar-nav .open .dropdown-menu > li > a:hover,
	.bootstrap-scope .navbar-nav .open .dropdown-menu > li > a:focus {
		background-image: none;
	}
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-nav {
		float: left;
		margin: 0;
	}
	.bootstrap-scope .navbar-nav > li {
		float: left;
	}
	.bootstrap-scope .navbar-nav > li > a {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}
.bootstrap-scope .navbar-form {
	margin-left: -15px;
	margin-right: -15px;
	padding: 10px 15px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	margin-top: 8px;
	margin-bottom: 8px;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-form .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.bootstrap-scope .navbar-form .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.bootstrap-scope .navbar-form .form-control-static {
		display: inline-block;
	}
	.bootstrap-scope .navbar-form .input-group {
		display: inline-table;
		vertical-align: middle;
	}
	.bootstrap-scope .navbar-form .input-group .input-group-addon,
	.bootstrap-scope .navbar-form .input-group .input-group-btn,
	.bootstrap-scope .navbar-form .input-group .form-control {
		width: auto;
	}
	.bootstrap-scope .navbar-form .input-group > .form-control {
		width: 100%;
	}
	.bootstrap-scope .navbar-form .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}
	.bootstrap-scope .navbar-form .radio,
	.bootstrap-scope .navbar-form .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.bootstrap-scope .navbar-form .radio label,
	.bootstrap-scope .navbar-form .checkbox label {
		padding-left: 0;
	}
	.bootstrap-scope .navbar-form .radio input[type="radio"],
	.bootstrap-scope .navbar-form .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	}
	.bootstrap-scope .navbar-form .has-feedback .form-control-feedback {
		top: 0;
	}
}
@media (max-width: 767px) {
	.bootstrap-scope .navbar-form .form-group {
		margin-bottom: 5px;
	}
	.bootstrap-scope .navbar-form .form-group:last-child {
		margin-bottom: 0;
	}
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-form {
		width: auto;
		border: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
.bootstrap-scope .navbar-nav > li > .dropdown-menu {
	margin-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.bootstrap-scope .navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
	margin-bottom: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.bootstrap-scope .navbar-btn {
	margin-top: 8px;
	margin-bottom: 8px;
}
.bootstrap-scope .navbar-btn.btn-sm {
	margin-top: 10px;
	margin-bottom: 10px;
}
.bootstrap-scope .navbar-btn.btn-xs {
	margin-top: 14px;
	margin-bottom: 14px;
}
.bootstrap-scope .navbar-text {
	margin-top: 15px;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-text {
		float: left;
		margin-left: 15px;
		margin-right: 15px;
	}
}
@media (min-width: 768px) {
	.bootstrap-scope .navbar-left {
		float: left !important;
	}
	.bootstrap-scope .navbar-right {
		float: right !important;
		margin-right: -15px;
	}
	.bootstrap-scope .navbar-right ~ .navbar-right {
		margin-right: 0;
	}
}
.bootstrap-scope .navbar-default {
	background-color: #f8f8f8;
	border-color: #e7e7e7;
}
.bootstrap-scope .navbar-default .navbar-brand {
	color: #777;
}
.bootstrap-scope .navbar-default .navbar-brand:hover,
.bootstrap-scope .navbar-default .navbar-brand:focus {
	color: #5e5e5e;
	background-color: transparent;
}
.bootstrap-scope .navbar-default .navbar-text {
	color: #777;
}
.bootstrap-scope .navbar-default .navbar-nav > li > a {
	color: #777;
}
.bootstrap-scope .navbar-default .navbar-nav > li > a:hover,
.bootstrap-scope .navbar-default .navbar-nav > li > a:focus {
	color: #333;
	background-color: transparent;
}
.bootstrap-scope .navbar-default .navbar-nav > .active > a,
.bootstrap-scope .navbar-default .navbar-nav > .active > a:hover,
.bootstrap-scope .navbar-default .navbar-nav > .active > a:focus {
	color: #555;
	background-color: #e7e7e7;
}
.bootstrap-scope .navbar-default .navbar-nav > .disabled > a,
.bootstrap-scope .navbar-default .navbar-nav > .disabled > a:hover,
.bootstrap-scope .navbar-default .navbar-nav > .disabled > a:focus {
	color: #ccc;
	background-color: transparent;
}
.bootstrap-scope .navbar-default .navbar-toggle {
	border-color: #ddd;
}
.bootstrap-scope .navbar-default .navbar-toggle:hover,
.bootstrap-scope .navbar-default .navbar-toggle:focus {
	background-color: #ddd;
}
.bootstrap-scope .navbar-default .navbar-toggle .icon-bar {
	background-color: #888;
}
.bootstrap-scope .navbar-default .navbar-collapse,
.bootstrap-scope .navbar-default .navbar-form {
	border-color: #e7e7e7;
}
.bootstrap-scope .navbar-default .navbar-nav > .open > a,
.bootstrap-scope .navbar-default .navbar-nav > .open > a:hover,
.bootstrap-scope .navbar-default .navbar-nav > .open > a:focus {
	background-color: #e7e7e7;
	color: #555;
}
@media (max-width: 767px) {
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #777;
	}
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #333;
		background-color: transparent;
	}
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #555;
		background-color: #e7e7e7;
	}
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.bootstrap-scope .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #ccc;
		background-color: transparent;
	}
}
.bootstrap-scope .navbar-default .navbar-link {
	color: #777;
}
.bootstrap-scope .navbar-default .navbar-link:hover {
	color: #333;
}
.bootstrap-scope .navbar-default .btn-link {
	color: #777;
}
.bootstrap-scope .navbar-default .btn-link:hover,
.bootstrap-scope .navbar-default .btn-link:focus {
	color: #333;
}
.bootstrap-scope .navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .bootstrap-scope .navbar-default .btn-link:hover,
.bootstrap-scope .navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .bootstrap-scope .navbar-default .btn-link:focus {
	color: #ccc;
}
.bootstrap-scope .navbar-inverse {
	background-color: #222;
	border-color: #080808;
}
.bootstrap-scope .navbar-inverse .navbar-brand {
	color: #9d9d9d;
}
.bootstrap-scope .navbar-inverse .navbar-brand:hover,
.bootstrap-scope .navbar-inverse .navbar-brand:focus {
	color: #fff;
	background-color: transparent;
}
.bootstrap-scope .navbar-inverse .navbar-text {
	color: #9d9d9d;
}
.bootstrap-scope .navbar-inverse .navbar-nav > li > a {
	color: #9d9d9d;
}
.bootstrap-scope .navbar-inverse .navbar-nav > li > a:hover,
.bootstrap-scope .navbar-inverse .navbar-nav > li > a:focus {
	color: #fff;
	background-color: transparent;
}
.bootstrap-scope .navbar-inverse .navbar-nav > .active > a,
.bootstrap-scope .navbar-inverse .navbar-nav > .active > a:hover,
.bootstrap-scope .navbar-inverse .navbar-nav > .active > a:focus {
	color: #fff;
	background-color: #080808;
}
.bootstrap-scope .navbar-inverse .navbar-nav > .disabled > a,
.bootstrap-scope .navbar-inverse .navbar-nav > .disabled > a:hover,
.bootstrap-scope .navbar-inverse .navbar-nav > .disabled > a:focus {
	color: #444;
	background-color: transparent;
}
.bootstrap-scope .navbar-inverse .navbar-toggle {
	border-color: #333;
}
.bootstrap-scope .navbar-inverse .navbar-toggle:hover,
.bootstrap-scope .navbar-inverse .navbar-toggle:focus {
	background-color: #333;
}
.bootstrap-scope .navbar-inverse .navbar-toggle .icon-bar {
	background-color: #fff;
}
.bootstrap-scope .navbar-inverse .navbar-collapse,
.bootstrap-scope .navbar-inverse .navbar-form {
	border-color: #101010;
}
.bootstrap-scope .navbar-inverse .navbar-nav > .open > a,
.bootstrap-scope .navbar-inverse .navbar-nav > .open > a:hover,
.bootstrap-scope .navbar-inverse .navbar-nav > .open > a:focus {
	background-color: #080808;
	color: #fff;
}
@media (max-width: 767px) {
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
		border-color: #080808;
	}
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
		background-color: #080808;
	}
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
		color: #9d9d9d;
	}
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #fff;
		background-color: transparent;
	}
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #fff;
		background-color: #080808;
	}
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.bootstrap-scope .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #444;
		background-color: transparent;
	}
}
.bootstrap-scope .navbar-inverse .navbar-link {
	color: #9d9d9d;
}
.bootstrap-scope .navbar-inverse .navbar-link:hover {
	color: #fff;
}
.bootstrap-scope .navbar-inverse .btn-link {
	color: #9d9d9d;
}
.bootstrap-scope .navbar-inverse .btn-link:hover,
.bootstrap-scope .navbar-inverse .btn-link:focus {
	color: #fff;
}
.bootstrap-scope .navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .bootstrap-scope .navbar-inverse .btn-link:hover,
.bootstrap-scope .navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .bootstrap-scope .navbar-inverse .btn-link:focus {
	color: #444;
}
.bootstrap-scope .breadcrumb {
	padding: 8px 15px;
	margin-bottom: 20px;
	list-style: none;
	background-color: #f5f5f5;
	border-radius: 4px;
}
.bootstrap-scope .breadcrumb > li {
	display: inline-block;
}
.bootstrap-scope .breadcrumb > li + li:before {
	content: "/\00a0";
	padding: 0 5px;
	color: #ccc;
}
.bootstrap-scope .breadcrumb > .active {
	color: #777777;
}
.bootstrap-scope .pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
}
.bootstrap-scope .pagination > li {
	display: inline;
}
.bootstrap-scope .pagination > li > a,
.bootstrap-scope .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	line-height: 1.42857143;
	text-decoration: none;
	color: #337ab7;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-left: -1px;
}
.bootstrap-scope .pagination > li:first-child > a,
.bootstrap-scope .pagination > li:first-child > span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
.bootstrap-scope .pagination > li:last-child > a,
.bootstrap-scope .pagination > li:last-child > span {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}
.bootstrap-scope .pagination > li > a:hover,
.bootstrap-scope .pagination > li > span:hover,
.bootstrap-scope .pagination > li > a:focus,
.bootstrap-scope .pagination > li > span:focus {
	color: #23527c;
	background-color: #eeeeee;
	border-color: #ddd;
}
.bootstrap-scope .pagination > .active > a,
.bootstrap-scope .pagination > .active > span,
.bootstrap-scope .pagination > .active > a:hover,
.bootstrap-scope .pagination > .active > span:hover,
.bootstrap-scope .pagination > .active > a:focus,
.bootstrap-scope .pagination > .active > span:focus {
	z-index: 2;
	color: #fff;
	background-color: #337ab7;
	border-color: #337ab7;
	cursor: default;
}
.bootstrap-scope .pagination > .disabled > span,
.bootstrap-scope .pagination > .disabled > span:hover,
.bootstrap-scope .pagination > .disabled > span:focus,
.bootstrap-scope .pagination > .disabled > a,
.bootstrap-scope .pagination > .disabled > a:hover,
.bootstrap-scope .pagination > .disabled > a:focus {
	color: #777777;
	background-color: #fff;
	border-color: #ddd;
	cursor: not-allowed;
}
.bootstrap-scope .pagination-lg > li > a,
.bootstrap-scope .pagination-lg > li > span {
	padding: 10px 16px;
	font-size: 18px;
}
.bootstrap-scope .pagination-lg > li:first-child > a,
.bootstrap-scope .pagination-lg > li:first-child > span {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
}
.bootstrap-scope .pagination-lg > li:last-child > a,
.bootstrap-scope .pagination-lg > li:last-child > span {
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
}
.bootstrap-scope .pagination-sm > li > a,
.bootstrap-scope .pagination-sm > li > span {
	padding: 5px 10px;
	font-size: 12px;
}
.bootstrap-scope .pagination-sm > li:first-child > a,
.bootstrap-scope .pagination-sm > li:first-child > span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}
.bootstrap-scope .pagination-sm > li:last-child > a,
.bootstrap-scope .pagination-sm > li:last-child > span {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}
.bootstrap-scope .pager {
	padding-left: 0;
	margin: 20px 0;
	list-style: none;
	text-align: center;
}
.bootstrap-scope .pager li {
	display: inline;
}
.bootstrap-scope .pager li > a,
.bootstrap-scope .pager li > span {
	display: inline-block;
	padding: 5px 14px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
}
.bootstrap-scope .pager li > a:hover,
.bootstrap-scope .pager li > a:focus {
	text-decoration: none;
	background-color: #eeeeee;
}
.bootstrap-scope .pager .next > a,
.bootstrap-scope .pager .next > span {
	float: right;
}
.bootstrap-scope .pager .previous > a,
.bootstrap-scope .pager .previous > span {
	float: left;
}
.bootstrap-scope .pager .disabled > a,
.bootstrap-scope .pager .disabled > a:hover,
.bootstrap-scope .pager .disabled > a:focus,
.bootstrap-scope .pager .disabled > span {
	color: #777777;
	background-color: #fff;
	cursor: not-allowed;
}
.bootstrap-scope .label {
	display: inline;
	padding: 0.2em 0.6em 0.3em;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;
}
a.bootstrap-scope .label:hover,
a.bootstrap-scope .label:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}
.bootstrap-scope .label:empty {
	display: none;
}
.btn .bootstrap-scope .label {
	position: relative;
	top: -1px;
}
.bootstrap-scope .label-default {
	background-color: #777777;
}
.bootstrap-scope .label-default[href]:hover,
.bootstrap-scope .label-default[href]:focus {
	background-color: #5e5e5e;
}
.bootstrap-scope .label-primary {
	background-color: #337ab7;
}
.bootstrap-scope .label-primary[href]:hover,
.bootstrap-scope .label-primary[href]:focus {
	background-color: #286090;
}
.bootstrap-scope .label-success {
	background-color: #5cb85c;
}
.bootstrap-scope .label-success[href]:hover,
.bootstrap-scope .label-success[href]:focus {
	background-color: #449d44;
}
.bootstrap-scope .label-info {
	background-color: #5bc0de;
}
.bootstrap-scope .label-info[href]:hover,
.bootstrap-scope .label-info[href]:focus {
	background-color: #31b0d5;
}
.bootstrap-scope .label-warning {
	background-color: #f0ad4e;
}
.bootstrap-scope .label-warning[href]:hover,
.bootstrap-scope .label-warning[href]:focus {
	background-color: #ec971f;
}
.bootstrap-scope .label-danger {
	background-color: #d9534f;
}
.bootstrap-scope .label-danger[href]:hover,
.bootstrap-scope .label-danger[href]:focus {
	background-color: #c9302c;
}
.bootstrap-scope .badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	line-height: 1;
	vertical-align: baseline;
	white-space: nowrap;
	text-align: center;
	background-color: #777777;
	border-radius: 10px;
}
.bootstrap-scope .badge:empty {
	display: none;
}
.btn .bootstrap-scope .badge {
	position: relative;
	top: -1px;
}
.btn-xs .bootstrap-scope .badge,
.btn-group-xs > .btn .bootstrap-scope .badge {
	top: 0;
	padding: 1px 5px;
}
a.bootstrap-scope .badge:hover,
a.bootstrap-scope .badge:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}
.list-group-item.active > .bootstrap-scope .badge,
.nav-pills > .active > a > .bootstrap-scope .badge {
	color: #337ab7;
	background-color: #fff;
}
.list-group-item > .bootstrap-scope .badge {
	float: right;
}
.list-group-item > .bootstrap-scope .badge + .bootstrap-scope .badge {
	margin-right: 5px;
}
.nav-pills > li > a > .bootstrap-scope .badge {
	margin-left: 3px;
}
.bootstrap-scope .jumbotron {
	padding: 30px 15px;
	margin-bottom: 30px;
	color: inherit;
	background-color: #eeeeee;
}
.bootstrap-scope .jumbotron h1,
.bootstrap-scope .jumbotron .h1 {
	color: inherit;
}
.bootstrap-scope .jumbotron p {
	margin-bottom: 15px;
	font-size: 21px;
	font-weight: 200;
}
.bootstrap-scope .jumbotron > hr {
	border-top-color: #d5d5d5;
}
.container .bootstrap-scope .jumbotron,
.container-fluid .bootstrap-scope .jumbotron {
	border-radius: 6px;
}
.bootstrap-scope .jumbotron .container {
	max-width: 100%;
}
@media screen and (min-width: 768px) {
	.bootstrap-scope .jumbotron {
		padding: 48px 0;
	}
	.container .bootstrap-scope .jumbotron,
	.container-fluid .bootstrap-scope .jumbotron {
		padding-left: 60px;
		padding-right: 60px;
	}
	.bootstrap-scope .jumbotron h1,
	.bootstrap-scope .jumbotron .h1 {
		font-size: 63px;
	}
}
.bootstrap-scope .thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 20px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: border 0.2s ease-in-out;
	-o-transition: border 0.2s ease-in-out;
	transition: border 0.2s ease-in-out;
}
.bootstrap-scope .thumbnail > img,
.bootstrap-scope .thumbnail a > img {
	margin-left: auto;
	margin-right: auto;
}
a.bootstrap-scope .thumbnail:hover,
a.bootstrap-scope .thumbnail:focus,
a.bootstrap-scope .thumbnail.active {
	border-color: #337ab7;
}
.bootstrap-scope .thumbnail .caption {
	padding: 9px;
	color: #333333;
}
.bootstrap-scope .alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
}
.bootstrap-scope .alert h4 {
	margin-top: 0;
	color: inherit;
}
.bootstrap-scope .alert .alert-link {
	font-weight: bold;
}
.bootstrap-scope .alert > p,
.bootstrap-scope .alert > ul {
	margin-bottom: 0;
}
.bootstrap-scope .alert > p + p {
	margin-top: 5px;
}
.bootstrap-scope .alert-dismissable,
.bootstrap-scope .alert-dismissible {
	padding-right: 35px;
}
.bootstrap-scope .alert-dismissable .close,
.bootstrap-scope .alert-dismissible .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit;
}
.bootstrap-scope .alert-success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d;
}
.bootstrap-scope .alert-success hr {
	border-top-color: #c9e2b3;
}
.bootstrap-scope .alert-success .alert-link {
	color: #2b542c;
}
.bootstrap-scope .alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
}
.bootstrap-scope .alert-info hr {
	border-top-color: #a6e1ec;
}
.bootstrap-scope .alert-info .alert-link {
	color: #245269;
}
.bootstrap-scope .alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
}
.bootstrap-scope .alert-warning hr {
	border-top-color: #f7e1b5;
}
.bootstrap-scope .alert-warning .alert-link {
	color: #66512c;
}
.bootstrap-scope .alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
}
.bootstrap-scope .alert-danger hr {
	border-top-color: #e4b9c0;
}
.bootstrap-scope .alert-danger .alert-link {
	color: #843534;
}
@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}
	to {
		background-position: 0 0;
	}
}
@keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}
	to {
		background-position: 0 0;
	}
}
.bootstrap-scope .progress {
	overflow: hidden;
	height: 20px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.bootstrap-scope .progress-bar {
	float: left;
	width: 0%;
	height: 100%;
	font-size: 12px;
	line-height: 20px;
	color: #fff;
	text-align: center;
	background-color: #337ab7;
	-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: width 0.6s ease;
	-o-transition: width 0.6s ease;
	transition: width 0.6s ease;
}
.bootstrap-scope .progress-striped .progress-bar,
.bootstrap-scope .progress-bar-striped {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 40px 40px;
}
.bootstrap-scope .progress.active .progress-bar,
.bootstrap-scope .progress-bar.active {
	-webkit-animation: progress-bar-stripes 2s linear infinite;
	-o-animation: progress-bar-stripes 2s linear infinite;
	animation: progress-bar-stripes 2s linear infinite;
}
.bootstrap-scope .progress-bar-success {
	background-color: #5cb85c;
}
.progress-striped .bootstrap-scope .progress-bar-success {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap-scope .progress-bar-info {
	background-color: #5bc0de;
}
.progress-striped .bootstrap-scope .progress-bar-info {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap-scope .progress-bar-warning {
	background-color: #f0ad4e;
}
.progress-striped .bootstrap-scope .progress-bar-warning {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap-scope .progress-bar-danger {
	background-color: #d9534f;
}
.progress-striped .bootstrap-scope .progress-bar-danger {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.bootstrap-scope .media {
	margin-top: 15px;
}
.bootstrap-scope .media:first-child {
	margin-top: 0;
}
.bootstrap-scope .media,
.bootstrap-scope .media-body {
	zoom: 1;
	overflow: hidden;
}
.bootstrap-scope .media-body {
	width: 10000px;
}
.bootstrap-scope .media-object {
	display: block;
}
.bootstrap-scope .media-right,
.bootstrap-scope .media > .pull-right {
	padding-left: 10px;
}
.bootstrap-scope .media-left,
.bootstrap-scope .media > .pull-left {
	padding-right: 10px;
}
.bootstrap-scope .media-left,
.bootstrap-scope .media-right,
.bootstrap-scope .media-body {
	display: table-cell;
	vertical-align: top;
}
.bootstrap-scope .media-middle {
	vertical-align: middle;
}
.bootstrap-scope .media-bottom {
	vertical-align: bottom;
}
.bootstrap-scope .media-heading {
	margin-top: 0;
	margin-bottom: 5px;
}
.bootstrap-scope .media-list {
	padding-left: 0;
	list-style: none;
}
.bootstrap-scope .list-group {
	margin-bottom: 20px;
	padding-left: 0;
}
.bootstrap-scope .list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid #ddd;
}
.bootstrap-scope .list-group-item:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.bootstrap-scope .list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.bootstrap-scope a.list-group-item {
	color: #555;
}
.bootstrap-scope a.list-group-item .list-group-item-heading {
	color: #333;
}
.bootstrap-scope a.list-group-item:hover,
.bootstrap-scope a.list-group-item:focus {
	text-decoration: none;
	color: #555;
	background-color: #f5f5f5;
}
.bootstrap-scope .list-group-item.disabled,
.bootstrap-scope .list-group-item.disabled:hover,
.bootstrap-scope .list-group-item.disabled:focus {
	background-color: #eeeeee;
	color: #777777;
	cursor: not-allowed;
}
.bootstrap-scope .list-group-item.disabled .list-group-item-heading,
.bootstrap-scope .list-group-item.disabled:hover .list-group-item-heading,
.bootstrap-scope .list-group-item.disabled:focus .list-group-item-heading {
	color: inherit;
}
.bootstrap-scope .list-group-item.disabled .list-group-item-text,
.bootstrap-scope .list-group-item.disabled:hover .list-group-item-text,
.bootstrap-scope .list-group-item.disabled:focus .list-group-item-text {
	color: #777777;
}
.bootstrap-scope .list-group-item.active,
.bootstrap-scope .list-group-item.active:hover,
.bootstrap-scope .list-group-item.active:focus {
	z-index: 2;
	color: #fff;
	background-color: #337ab7;
	border-color: #337ab7;
}
.bootstrap-scope .list-group-item.active .list-group-item-heading,
.bootstrap-scope .list-group-item.active:hover .list-group-item-heading,
.bootstrap-scope .list-group-item.active:focus .list-group-item-heading,
.bootstrap-scope .list-group-item.active .list-group-item-heading > small,
.bootstrap-scope .list-group-item.active:hover .list-group-item-heading > small,
.bootstrap-scope .list-group-item.active:focus .list-group-item-heading > small,
.bootstrap-scope .list-group-item.active .list-group-item-heading > .small,
.bootstrap-scope .list-group-item.active:hover .list-group-item-heading > .small,
.bootstrap-scope .list-group-item.active:focus .list-group-item-heading > .small {
	color: inherit;
}
.bootstrap-scope .list-group-item.active .list-group-item-text,
.bootstrap-scope .list-group-item.active:hover .list-group-item-text,
.bootstrap-scope .list-group-item.active:focus .list-group-item-text {
	color: #c7ddef;
}
.bootstrap-scope .list-group-item-success {
	color: #3c763d;
	background-color: #dff0d8;
}
a.bootstrap-scope .list-group-item-success {
	color: #3c763d;
}
a.bootstrap-scope .list-group-item-success .list-group-item-heading {
	color: inherit;
}
a.bootstrap-scope .list-group-item-success:hover,
a.bootstrap-scope .list-group-item-success:focus {
	color: #3c763d;
	background-color: #d0e9c6;
}
a.bootstrap-scope .list-group-item-success.active,
a.bootstrap-scope .list-group-item-success.active:hover,
a.bootstrap-scope .list-group-item-success.active:focus {
	color: #fff;
	background-color: #3c763d;
	border-color: #3c763d;
}
.bootstrap-scope .list-group-item-info {
	color: #31708f;
	background-color: #d9edf7;
}
a.bootstrap-scope .list-group-item-info {
	color: #31708f;
}
a.bootstrap-scope .list-group-item-info .list-group-item-heading {
	color: inherit;
}
a.bootstrap-scope .list-group-item-info:hover,
a.bootstrap-scope .list-group-item-info:focus {
	color: #31708f;
	background-color: #c4e3f3;
}
a.bootstrap-scope .list-group-item-info.active,
a.bootstrap-scope .list-group-item-info.active:hover,
a.bootstrap-scope .list-group-item-info.active:focus {
	color: #fff;
	background-color: #31708f;
	border-color: #31708f;
}
.bootstrap-scope .list-group-item-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
}
a.bootstrap-scope .list-group-item-warning {
	color: #8a6d3b;
}
a.bootstrap-scope .list-group-item-warning .list-group-item-heading {
	color: inherit;
}
a.bootstrap-scope .list-group-item-warning:hover,
a.bootstrap-scope .list-group-item-warning:focus {
	color: #8a6d3b;
	background-color: #faf2cc;
}
a.bootstrap-scope .list-group-item-warning.active,
a.bootstrap-scope .list-group-item-warning.active:hover,
a.bootstrap-scope .list-group-item-warning.active:focus {
	color: #fff;
	background-color: #8a6d3b;
	border-color: #8a6d3b;
}
.bootstrap-scope .list-group-item-danger {
	color: #a94442;
	background-color: #f2dede;
}
a.bootstrap-scope .list-group-item-danger {
	color: #a94442;
}
a.bootstrap-scope .list-group-item-danger .list-group-item-heading {
	color: inherit;
}
a.bootstrap-scope .list-group-item-danger:hover,
a.bootstrap-scope .list-group-item-danger:focus {
	color: #a94442;
	background-color: #ebcccc;
}
a.bootstrap-scope .list-group-item-danger.active,
a.bootstrap-scope .list-group-item-danger.active:hover,
a.bootstrap-scope .list-group-item-danger.active:focus {
	color: #fff;
	background-color: #a94442;
	border-color: #a94442;
}
.bootstrap-scope .list-group-item-heading {
	margin-top: 0;
	margin-bottom: 5px;
}
.bootstrap-scope .list-group-item-text {
	margin-bottom: 0;
	line-height: 1.3;
}
.bootstrap-scope .panel {
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.bootstrap-scope .panel-body {
	padding: 15px;
}
.bootstrap-scope .panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.bootstrap-scope .panel-heading > .dropdown .dropdown-toggle {
	color: inherit;
}
.bootstrap-scope .panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
	color: inherit;
}
.bootstrap-scope .panel-title > a,
.bootstrap-scope .panel-title > small,
.bootstrap-scope .panel-title > .small,
.bootstrap-scope .panel-title > small > a,
.bootstrap-scope .panel-title > .small > a {
	color: inherit;
}
.bootstrap-scope .panel-footer {
	padding: 10px 15px;
	background-color: #f5f5f5;
	border-top: 1px solid #ddd;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.bootstrap-scope .panel > .list-group,
.bootstrap-scope .panel > .panel-collapse > .list-group {
	margin-bottom: 0;
}
.bootstrap-scope .panel > .list-group .list-group-item,
.bootstrap-scope .panel > .panel-collapse > .list-group .list-group-item {
	border-width: 1px 0;
	border-radius: 0;
}
.bootstrap-scope .panel > .list-group:first-child .list-group-item:first-child,
.bootstrap-scope .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
	border-top: 0;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.bootstrap-scope .panel > .list-group:last-child .list-group-item:last-child,
.bootstrap-scope .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
	border-bottom: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.bootstrap-scope .panel-heading + .list-group .list-group-item:first-child {
	border-top-width: 0;
}
.bootstrap-scope .list-group + .panel-footer {
	border-top-width: 0;
}
.bootstrap-scope .panel > .table,
.bootstrap-scope .panel > .table-responsive > .table,
.bootstrap-scope .panel > .panel-collapse > .table {
	margin-bottom: 0;
}
.bootstrap-scope .panel > .table caption,
.bootstrap-scope .panel > .table-responsive > .table caption,
.bootstrap-scope .panel > .panel-collapse > .table caption {
	padding-left: 15px;
	padding-right: 15px;
}
.bootstrap-scope .panel > .table:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.bootstrap-scope .panel > .table:first-child > thead:first-child > tr:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.bootstrap-scope .panel > .table:first-child > tbody:first-child > tr:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.bootstrap-scope .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.bootstrap-scope .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.bootstrap-scope .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.bootstrap-scope .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
	border-top-left-radius: 3px;
}
.bootstrap-scope .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.bootstrap-scope .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.bootstrap-scope .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.bootstrap-scope .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.bootstrap-scope .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
	border-top-right-radius: 3px;
}
.bootstrap-scope .panel > .table:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.bootstrap-scope .panel > .table:last-child > tbody:last-child > tr:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.bootstrap-scope .panel > .table:last-child > tfoot:last-child > tr:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
.bootstrap-scope .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.bootstrap-scope .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.bootstrap-scope .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.bootstrap-scope .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
	border-bottom-left-radius: 3px;
}
.bootstrap-scope .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.bootstrap-scope .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.bootstrap-scope .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.bootstrap-scope .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.bootstrap-scope .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
	border-bottom-right-radius: 3px;
}
.bootstrap-scope .panel > .panel-body + .table,
.bootstrap-scope .panel > .panel-body + .table-responsive,
.bootstrap-scope .panel > .table + .panel-body,
.bootstrap-scope .panel > .table-responsive + .panel-body {
	border-top: 1px solid #ddd;
}
.bootstrap-scope .panel > .table > tbody:first-child > tr:first-child th,
.bootstrap-scope .panel > .table > tbody:first-child > tr:first-child td {
	border-top: 0;
}
.bootstrap-scope .panel > .table-bordered,
.bootstrap-scope .panel > .table-responsive > .table-bordered {
	border: 0;
}
.bootstrap-scope .panel > .table-bordered > thead > tr > th:first-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.bootstrap-scope .panel > .table-bordered > tbody > tr > th:first-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.bootstrap-scope .panel > .table-bordered > tfoot > tr > th:first-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.bootstrap-scope .panel > .table-bordered > thead > tr > td:first-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.bootstrap-scope .panel > .table-bordered > tbody > tr > td:first-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.bootstrap-scope .panel > .table-bordered > tfoot > tr > td:first-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
	border-left: 0;
}
.bootstrap-scope .panel > .table-bordered > thead > tr > th:last-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.bootstrap-scope .panel > .table-bordered > tbody > tr > th:last-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.bootstrap-scope .panel > .table-bordered > tfoot > tr > th:last-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.bootstrap-scope .panel > .table-bordered > thead > tr > td:last-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.bootstrap-scope .panel > .table-bordered > tbody > tr > td:last-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.bootstrap-scope .panel > .table-bordered > tfoot > tr > td:last-child,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
	border-right: 0;
}
.bootstrap-scope .panel > .table-bordered > thead > tr:first-child > td,
.bootstrap-scope .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.bootstrap-scope .panel > .table-bordered > tbody > tr:first-child > td,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.bootstrap-scope .panel > .table-bordered > thead > tr:first-child > th,
.bootstrap-scope .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.bootstrap-scope .panel > .table-bordered > tbody > tr:first-child > th,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
	border-bottom: 0;
}
.bootstrap-scope .panel > .table-bordered > tbody > tr:last-child > td,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.bootstrap-scope .panel > .table-bordered > tfoot > tr:last-child > td,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.bootstrap-scope .panel > .table-bordered > tbody > tr:last-child > th,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.bootstrap-scope .panel > .table-bordered > tfoot > tr:last-child > th,
.bootstrap-scope .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
	border-bottom: 0;
}
.bootstrap-scope .panel > .table-responsive {
	border: 0;
	margin-bottom: 0;
}
.bootstrap-scope .panel-group {
	margin-bottom: 20px;
}
.bootstrap-scope .panel-group .panel {
	margin-bottom: 0;
	border-radius: 4px;
}
.bootstrap-scope .panel-group .panel + .panel {
	margin-top: 5px;
}
.bootstrap-scope .panel-group .panel-heading {
	border-bottom: 0;
}
.bootstrap-scope .panel-group .panel-heading + .panel-collapse > .panel-body,
.bootstrap-scope .panel-group .panel-heading + .panel-collapse > .list-group {
	border-top: 1px solid #ddd;
}
.bootstrap-scope .panel-group .panel-footer {
	border-top: 0;
}
.bootstrap-scope .panel-group .panel-footer + .panel-collapse .panel-body {
	border-bottom: 1px solid #ddd;
}
.bootstrap-scope .panel-default {
	border-color: #ddd;
}
.bootstrap-scope .panel-default > .panel-heading {
	color: #333333;
	background-color: #f5f5f5;
	border-color: #ddd;
}
.bootstrap-scope .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #ddd;
}
.bootstrap-scope .panel-default > .panel-heading .badge {
	color: #f5f5f5;
	background-color: #333333;
}
.bootstrap-scope .panel-default > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #ddd;
}
.bootstrap-scope .panel-primary {
	border-color: #337ab7;
}
.bootstrap-scope .panel-primary > .panel-heading {
	color: #fff;
	background-color: #337ab7;
	border-color: #337ab7;
}
.bootstrap-scope .panel-primary > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #337ab7;
}
.bootstrap-scope .panel-primary > .panel-heading .badge {
	color: #337ab7;
	background-color: #fff;
}
.bootstrap-scope .panel-primary > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #337ab7;
}
.bootstrap-scope .panel-success {
	border-color: #d6e9c6;
}
.bootstrap-scope .panel-success > .panel-heading {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}
.bootstrap-scope .panel-success > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #d6e9c6;
}
.bootstrap-scope .panel-success > .panel-heading .badge {
	color: #dff0d8;
	background-color: #3c763d;
}
.bootstrap-scope .panel-success > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #d6e9c6;
}
.bootstrap-scope .panel-info {
	border-color: #bce8f1;
}
.bootstrap-scope .panel-info > .panel-heading {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1;
}
.bootstrap-scope .panel-info > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #bce8f1;
}
.bootstrap-scope .panel-info > .panel-heading .badge {
	color: #d9edf7;
	background-color: #31708f;
}
.bootstrap-scope .panel-info > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #bce8f1;
}
.bootstrap-scope .panel-warning {
	border-color: #faebcc;
}
.bootstrap-scope .panel-warning > .panel-heading {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc;
}
.bootstrap-scope .panel-warning > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #faebcc;
}
.bootstrap-scope .panel-warning > .panel-heading .badge {
	color: #fcf8e3;
	background-color: #8a6d3b;
}
.bootstrap-scope .panel-warning > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #faebcc;
}
.bootstrap-scope .panel-danger {
	border-color: #ebccd1;
}
.bootstrap-scope .panel-danger > .panel-heading {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
}
.bootstrap-scope .panel-danger > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #ebccd1;
}
.bootstrap-scope .panel-danger > .panel-heading .badge {
	color: #f2dede;
	background-color: #a94442;
}
.bootstrap-scope .panel-danger > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #ebccd1;
}
.bootstrap-scope .embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
}
.bootstrap-scope .embed-responsive .embed-responsive-item,
.bootstrap-scope .embed-responsive iframe,
.bootstrap-scope .embed-responsive embed,
.bootstrap-scope .embed-responsive object,
.bootstrap-scope .embed-responsive video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	border: 0;
}
.bootstrap-scope .embed-responsive-16by9 {
	padding-bottom: 56.25%;
}
.bootstrap-scope .embed-responsive-4by3 {
	padding-bottom: 75%;
}
.bootstrap-scope .well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.bootstrap-scope .well blockquote {
	border-color: #ddd;
	border-color: rgba(0, 0, 0, 0.15);
}
.bootstrap-scope .well-lg {
	padding: 24px;
	border-radius: 6px;
}
.bootstrap-scope .well-sm {
	padding: 9px;
	border-radius: 3px;
}
.bootstrap-scope .close {
	float: right;
	font-size: 21px;
	font-weight: bold;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.2;
	filter: alpha(opacity=20);
}
.bootstrap-scope .close:hover,
.bootstrap-scope .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
button.bootstrap-scope .close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}
.bootstrap-scope .modal-open {
	overflow: hidden;
}
.bootstrap-scope .modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}
.bootstrap-scope .modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	-o-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	-moz-transition: -moz-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}
.bootstrap-scope .modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}
.bootstrap-scope .modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.bootstrap-scope .modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
}
.bootstrap-scope .modal-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	background-clip: padding-box;
	outline: 0;
}
.bootstrap-scope .modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}
.bootstrap-scope .modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0);
}
.bootstrap-scope .modal-backdrop.in {
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.bootstrap-scope .modal-header {
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
	min-height: 16.42857143px;
}
.bootstrap-scope .modal-header .close {
	margin-top: -2px;
}
.bootstrap-scope .modal-title {
	margin: 0;
	line-height: 1.42857143;
}
.bootstrap-scope .modal-body {
	position: relative;
	padding: 15px;
}
.bootstrap-scope .modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
}
.bootstrap-scope .modal-footer .btn + .btn {
	margin-left: 5px;
	margin-bottom: 0;
}
.bootstrap-scope .modal-footer .btn-group .btn + .btn {
	margin-left: -1px;
}
.bootstrap-scope .modal-footer .btn-block + .btn-block {
	margin-left: 0;
}
.bootstrap-scope .modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
@media (min-width: 768px) {
	.bootstrap-scope .modal-dialog {
		width: 600px;
		margin: 30px auto;
	}
	.bootstrap-scope .modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}
	.bootstrap-scope .modal-sm {
		width: 300px;
	}
}
@media (min-width: 992px) {
	.bootstrap-scope .modal-lg {
		width: 900px;
	}
}
.bootstrap-scope .tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 1.4;
	opacity: 0;
	filter: alpha(opacity=0);
}
.bootstrap-scope .tooltip.in {
	opacity: 0.9;
	filter: alpha(opacity=90);
}
.bootstrap-scope .tooltip.top {
	margin-top: -3px;
	padding: 5px 0;
}
.bootstrap-scope .tooltip.right {
	margin-left: 3px;
	padding: 0 5px;
}
.bootstrap-scope .tooltip.bottom {
	margin-top: 3px;
	padding: 5px 0;
}
.bootstrap-scope .tooltip.left {
	margin-left: -3px;
	padding: 0 5px;
}
.bootstrap-scope .tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	background-color: #000;
	border-radius: 4px;
}
.bootstrap-scope .tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.bootstrap-scope .tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000;
}
.bootstrap-scope .tooltip.top-left .tooltip-arrow {
	bottom: 0;
	right: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000;
}
.bootstrap-scope .tooltip.top-right .tooltip-arrow {
	bottom: 0;
	left: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000;
}
.bootstrap-scope .tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #000;
}
.bootstrap-scope .tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #000;
}
.bootstrap-scope .tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000;
}
.bootstrap-scope .tooltip.bottom-left .tooltip-arrow {
	top: 0;
	right: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000;
}
.bootstrap-scope .tooltip.bottom-right .tooltip-arrow {
	top: 0;
	left: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000;
}
.bootstrap-scope .popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	max-width: 276px;
	padding: 1px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.42857143;
	text-align: left;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	white-space: normal;
}
.bootstrap-scope .popover.top {
	margin-top: -10px;
}
.bootstrap-scope .popover.right {
	margin-left: 10px;
}
.bootstrap-scope .popover.bottom {
	margin-top: 10px;
}
.bootstrap-scope .popover.left {
	margin-left: -10px;
}
.bootstrap-scope .popover-title {
	margin: 0;
	padding: 8px 14px;
	font-size: 14px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-radius: 5px 5px 0 0;
}
.bootstrap-scope .popover-content {
	padding: 9px 14px;
}
.bootstrap-scope .popover > .arrow,
.bootstrap-scope .popover > .arrow:after {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.bootstrap-scope .popover > .arrow {
	border-width: 11px;
}
.bootstrap-scope .popover > .arrow:after {
	border-width: 10px;
	content: "";
}
.bootstrap-scope .popover.top > .arrow {
	left: 50%;
	margin-left: -11px;
	border-bottom-width: 0;
	border-top-color: #999999;
	border-top-color: rgba(0, 0, 0, 0.25);
	bottom: -11px;
}
.bootstrap-scope .popover.top > .arrow:after {
	content: " ";
	bottom: 1px;
	margin-left: -10px;
	border-bottom-width: 0;
	border-top-color: #fff;
}
.bootstrap-scope .popover.right > .arrow {
	top: 50%;
	left: -11px;
	margin-top: -11px;
	border-left-width: 0;
	border-right-color: #999999;
	border-right-color: rgba(0, 0, 0, 0.25);
}
.bootstrap-scope .popover.right > .arrow:after {
	content: " ";
	left: 1px;
	bottom: -10px;
	border-left-width: 0;
	border-right-color: #fff;
}
.bootstrap-scope .popover.bottom > .arrow {
	left: 50%;
	margin-left: -11px;
	border-top-width: 0;
	border-bottom-color: #999999;
	border-bottom-color: rgba(0, 0, 0, 0.25);
	top: -11px;
}
.bootstrap-scope .popover.bottom > .arrow:after {
	content: " ";
	top: 1px;
	margin-left: -10px;
	border-top-width: 0;
	border-bottom-color: #fff;
}
.bootstrap-scope .popover.left > .arrow {
	top: 50%;
	right: -11px;
	margin-top: -11px;
	border-right-width: 0;
	border-left-color: #999999;
	border-left-color: rgba(0, 0, 0, 0.25);
}
.bootstrap-scope .popover.left > .arrow:after {
	content: " ";
	right: 1px;
	border-right-width: 0;
	border-left-color: #fff;
	bottom: -10px;
}
.bootstrap-scope .carousel {
	position: relative;
}
.bootstrap-scope .carousel-inner {
	position: relative;
	overflow: hidden;
	width: 100%;
}
.bootstrap-scope .carousel-inner > .item {
	display: none;
	position: relative;
	-webkit-transition: 0.6s ease-in-out left;
	-o-transition: 0.6s ease-in-out left;
	transition: 0.6s ease-in-out left;
}
.bootstrap-scope .carousel-inner > .item > img,
.bootstrap-scope .carousel-inner > .item > a > img {
	line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
	.bootstrap-scope .carousel-inner > .item {
		-webkit-transition: -webkit-transform 0.6s ease-in-out;
		-moz-transition: -moz-transform 0.6s ease-in-out;
		-o-transition: -o-transform 0.6s ease-in-out;
		transition: transform 0.6s ease-in-out;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-perspective: 1000;
		-moz-perspective: 1000;
		perspective: 1000;
	}
	.bootstrap-scope .carousel-inner > .item.next,
	.bootstrap-scope .carousel-inner > .item.active.right {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		left: 0;
	}
	.bootstrap-scope .carousel-inner > .item.prev,
	.bootstrap-scope .carousel-inner > .item.active.left {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		left: 0;
	}
	.bootstrap-scope .carousel-inner > .item.next.left,
	.bootstrap-scope .carousel-inner > .item.prev.right,
	.bootstrap-scope .carousel-inner > .item.active {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		left: 0;
	}
}
.bootstrap-scope .carousel-inner > .active,
.bootstrap-scope .carousel-inner > .next,
.bootstrap-scope .carousel-inner > .prev {
	display: block;
}
.bootstrap-scope .carousel-inner > .active {
	left: 0;
}
.bootstrap-scope .carousel-inner > .next,
.bootstrap-scope .carousel-inner > .prev {
	position: absolute;
	top: 0;
	width: 100%;
}
.bootstrap-scope .carousel-inner > .next {
	left: 100%;
}
.bootstrap-scope .carousel-inner > .prev {
	left: -100%;
}
.bootstrap-scope .carousel-inner > .next.left,
.bootstrap-scope .carousel-inner > .prev.right {
	left: 0;
}
.bootstrap-scope .carousel-inner > .active.left {
	left: -100%;
}
.bootstrap-scope .carousel-inner > .active.right {
	left: 100%;
}
.bootstrap-scope .carousel-control {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 15%;
	opacity: 0.5;
	filter: alpha(opacity=50);
	font-size: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.bootstrap-scope .carousel-control.left {
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}
.bootstrap-scope .carousel-control.right {
	left: auto;
	right: 0;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}
.bootstrap-scope .carousel-control:hover,
.bootstrap-scope .carousel-control:focus {
	outline: 0;
	color: #fff;
	text-decoration: none;
	opacity: 0.9;
	filter: alpha(opacity=90);
}
.bootstrap-scope .carousel-control .icon-prev,
.bootstrap-scope .carousel-control .icon-next,
.bootstrap-scope .carousel-control .glyphicon-chevron-left,
.bootstrap-scope .carousel-control .glyphicon-chevron-right {
	position: absolute;
	top: 50%;
	z-index: 5;
	display: inline-block;
}
.bootstrap-scope .carousel-control .icon-prev,
.bootstrap-scope .carousel-control .glyphicon-chevron-left {
	left: 50%;
	margin-left: -10px;
}
.bootstrap-scope .carousel-control .icon-next,
.bootstrap-scope .carousel-control .glyphicon-chevron-right {
	right: 50%;
	margin-right: -10px;
}
.bootstrap-scope .carousel-control .icon-prev,
.bootstrap-scope .carousel-control .icon-next {
	width: 20px;
	height: 20px;
	margin-top: -10px;
	line-height: 1;
	font-family: serif;
}
.bootstrap-scope .carousel-control .icon-prev:before {
	content: "\2039";
}
.bootstrap-scope .carousel-control .icon-next:before {
	content: "\203a";
}
.bootstrap-scope .carousel-indicators {
	position: absolute;
	bottom: 10px;
	left: 50%;
	z-index: 15;
	width: 60%;
	margin-left: -30%;
	padding-left: 0;
	list-style: none;
	text-align: center;
}
.bootstrap-scope .carousel-indicators li {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 1px;
	text-indent: -999px;
	border: 1px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	background-color: #000 \9;
	background-color: rgba(0, 0, 0, 0);
}
.bootstrap-scope .carousel-indicators .active {
	margin: 0;
	width: 12px;
	height: 12px;
	background-color: #fff;
}
.bootstrap-scope .carousel-caption {
	position: absolute;
	left: 15%;
	right: 15%;
	bottom: 20px;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.bootstrap-scope .carousel-caption .btn {
	text-shadow: none;
}
@media screen and (min-width: 768px) {
	.bootstrap-scope .carousel-control .glyphicon-chevron-left,
	.bootstrap-scope .carousel-control .glyphicon-chevron-right,
	.bootstrap-scope .carousel-control .icon-prev,
	.bootstrap-scope .carousel-control .icon-next {
		width: 30px;
		height: 30px;
		margin-top: -15px;
		font-size: 30px;
	}
	.bootstrap-scope .carousel-control .glyphicon-chevron-left,
	.bootstrap-scope .carousel-control .icon-prev {
		margin-left: -15px;
	}
	.bootstrap-scope .carousel-control .glyphicon-chevron-right,
	.bootstrap-scope .carousel-control .icon-next {
		margin-right: -15px;
	}
	.bootstrap-scope .carousel-caption {
		left: 20%;
		right: 20%;
		padding-bottom: 30px;
	}
	.bootstrap-scope .carousel-indicators {
		bottom: 20px;
	}
}
.bootstrap-scope .clearfix:before,
.bootstrap-scope .clearfix:after,
.bootstrap-scope .bootstrap-scope .dl-horizontal dd:before,
.bootstrap-scope .bootstrap-scope .dl-horizontal dd:after,
.bootstrap-scope .bootstrap-scope .container:before,
.bootstrap-scope .bootstrap-scope .container:after,
.bootstrap-scope .bootstrap-scope .container-fluid:before,
.bootstrap-scope .bootstrap-scope .container-fluid:after,
.bootstrap-scope .bootstrap-scope .row:before,
.bootstrap-scope .bootstrap-scope .row:after,
.bootstrap-scope .bootstrap-scope .form-horizontal .form-group:before,
.bootstrap-scope .bootstrap-scope .form-horizontal .form-group:after,
.bootstrap-scope .bootstrap-scope .btn-toolbar:before,
.bootstrap-scope .bootstrap-scope .btn-toolbar:after,
.bootstrap-scope .bootstrap-scope .btn-group-vertical > .btn-group:before,
.bootstrap-scope .bootstrap-scope .btn-group-vertical > .btn-group:after,
.bootstrap-scope .bootstrap-scope .nav:before,
.bootstrap-scope .bootstrap-scope .nav:after,
.bootstrap-scope .bootstrap-scope .navbar:before,
.bootstrap-scope .bootstrap-scope .navbar:after,
.bootstrap-scope .bootstrap-scope .navbar-header:before,
.bootstrap-scope .bootstrap-scope .navbar-header:after,
.bootstrap-scope .bootstrap-scope .navbar-collapse:before,
.bootstrap-scope .bootstrap-scope .navbar-collapse:after,
.bootstrap-scope .bootstrap-scope .pager:before,
.bootstrap-scope .bootstrap-scope .pager:after,
.bootstrap-scope .bootstrap-scope .panel-body:before,
.bootstrap-scope .bootstrap-scope .panel-body:after,
.bootstrap-scope .bootstrap-scope .modal-footer:before,
.bootstrap-scope .bootstrap-scope .modal-footer:after {
	content: " ";
	display: table;
}
.bootstrap-scope .clearfix:after,
.bootstrap-scope .bootstrap-scope .dl-horizontal dd:after,
.bootstrap-scope .bootstrap-scope .container:after,
.bootstrap-scope .bootstrap-scope .container-fluid:after,
.bootstrap-scope .bootstrap-scope .row:after,
.bootstrap-scope .bootstrap-scope .form-horizontal .form-group:after,
.bootstrap-scope .bootstrap-scope .btn-toolbar:after,
.bootstrap-scope .bootstrap-scope .btn-group-vertical > .btn-group:after,
.bootstrap-scope .bootstrap-scope .nav:after,
.bootstrap-scope .bootstrap-scope .navbar:after,
.bootstrap-scope .bootstrap-scope .navbar-header:after,
.bootstrap-scope .bootstrap-scope .navbar-collapse:after,
.bootstrap-scope .bootstrap-scope .pager:after,
.bootstrap-scope .bootstrap-scope .panel-body:after,
.bootstrap-scope .bootstrap-scope .modal-footer:after {
	clear: both;
}
.bootstrap-scope .center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.bootstrap-scope .pull-right {
	float: right !important;
}
.bootstrap-scope .pull-left {
	float: left !important;
}
.bootstrap-scope .hide {
	display: none !important;
}
.bootstrap-scope .show {
	display: block !important;
}
.bootstrap-scope .invisible {
	visibility: hidden;
}
.bootstrap-scope .text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.bootstrap-scope .hidden {
	display: none !important;
}
.bootstrap-scope .affix {
	position: fixed;
}
@-ms-viewport {
	width: device-width;
}
.bootstrap-scope .visible-xs,
.bootstrap-scope .visible-sm,
.bootstrap-scope .visible-md,
.bootstrap-scope .visible-lg {
	display: none !important;
}
.bootstrap-scope .visible-xs-block,
.bootstrap-scope .visible-xs-inline,
.bootstrap-scope .visible-xs-inline-block,
.bootstrap-scope .visible-sm-block,
.bootstrap-scope .visible-sm-inline,
.bootstrap-scope .visible-sm-inline-block,
.bootstrap-scope .visible-md-block,
.bootstrap-scope .visible-md-inline,
.bootstrap-scope .visible-md-inline-block,
.bootstrap-scope .visible-lg-block,
.bootstrap-scope .visible-lg-inline,
.bootstrap-scope .visible-lg-inline-block {
	display: none !important;
}
@media (max-width: 767px) {
	.bootstrap-scope .visible-xs {
		display: block !important;
	}
	table.bootstrap-scope .visible-xs {
		display: table;
	}
	tr.bootstrap-scope .visible-xs {
		display: table-row !important;
	}
	th.bootstrap-scope .visible-xs,
	td.bootstrap-scope .visible-xs {
		display: table-cell !important;
	}
}
@media (max-width: 767px) {
	.bootstrap-scope .visible-xs-block {
		display: block !important;
	}
}
@media (max-width: 767px) {
	.bootstrap-scope .visible-xs-inline {
		display: inline !important;
	}
}
@media (max-width: 767px) {
	.bootstrap-scope .visible-xs-inline-block {
		display: inline-block !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.bootstrap-scope .visible-sm {
		display: block !important;
	}
	table.bootstrap-scope .visible-sm {
		display: table;
	}
	tr.bootstrap-scope .visible-sm {
		display: table-row !important;
	}
	th.bootstrap-scope .visible-sm,
	td.bootstrap-scope .visible-sm {
		display: table-cell !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.bootstrap-scope .visible-sm-block {
		display: block !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.bootstrap-scope .visible-sm-inline {
		display: inline !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.bootstrap-scope .visible-sm-inline-block {
		display: inline-block !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.bootstrap-scope .visible-md {
		display: block !important;
	}
	table.bootstrap-scope .visible-md {
		display: table;
	}
	tr.bootstrap-scope .visible-md {
		display: table-row !important;
	}
	th.bootstrap-scope .visible-md,
	td.bootstrap-scope .visible-md {
		display: table-cell !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.bootstrap-scope .visible-md-block {
		display: block !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.bootstrap-scope .visible-md-inline {
		display: inline !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.bootstrap-scope .visible-md-inline-block {
		display: inline-block !important;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .visible-lg {
		display: block !important;
	}
	table.bootstrap-scope .visible-lg {
		display: table;
	}
	tr.bootstrap-scope .visible-lg {
		display: table-row !important;
	}
	th.bootstrap-scope .visible-lg,
	td.bootstrap-scope .visible-lg {
		display: table-cell !important;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .visible-lg-block {
		display: block !important;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .visible-lg-inline {
		display: inline !important;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .visible-lg-inline-block {
		display: inline-block !important;
	}
}
@media (max-width: 767px) {
	.bootstrap-scope .hidden-xs {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.bootstrap-scope .hidden-sm {
		display: none !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.bootstrap-scope .hidden-md {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	.bootstrap-scope .hidden-lg {
		display: none !important;
	}
}
.bootstrap-scope .visible-print {
	display: none !important;
}
@media print {
	.bootstrap-scope .visible-print {
		display: block !important;
	}
	table.bootstrap-scope .visible-print {
		display: table;
	}
	tr.bootstrap-scope .visible-print {
		display: table-row !important;
	}
	th.bootstrap-scope .visible-print,
	td.bootstrap-scope .visible-print {
		display: table-cell !important;
	}
}
.bootstrap-scope .visible-print-block {
	display: none !important;
}
@media print {
	.bootstrap-scope .visible-print-block {
		display: block !important;
	}
}
.bootstrap-scope .visible-print-inline {
	display: none !important;
}
@media print {
	.bootstrap-scope .visible-print-inline {
		display: inline !important;
	}
}
.bootstrap-scope .visible-print-inline-block {
	display: none !important;
}
@media print {
	.bootstrap-scope .visible-print-inline-block {
		display: inline-block !important;
	}
}
@media print {
	.bootstrap-scope .hidden-print {
		display: none !important;
	}
}
