/**
 * HTML font-size is adjusted using media queries. 
 *
 * Breakpoints:
 * Extra small devices (less than 480px) = 11px
 * Small devices (480px and up) = 14px
 * Large devices (670px and up) = 16px	
 *
 */
html {
	font-size: 16px;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	overflow: initial !important;
}

@media screen and (max-width: 670px) {
	html {
		font-size: 14px;
	}
}

@media screen and (max-width: 480px) {
	html {
		font-size: 11px;
	}
}

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
	z-index: 999;
}
